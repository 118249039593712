/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Gender, GenderInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { GenderActionTypes } from '../actions';

export interface GendersState extends EntityState<Gender | GenderInterface> {};

export const GenderAdapter: EntityAdapter<Gender | GenderInterface> = createEntityAdapter<Gender | GenderInterface>();

const initialState: GendersState = GenderAdapter.getInitialState({});

const cases = BaseReducerFactory<GendersState, Gender | GenderInterface>(GenderActionTypes, GenderAdapter);

/**
 * @module GendersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Gender reducer.
 */
export function GendersReducer(state = initialState, action: LoopbackAction): GendersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getGendersState = (state: any) => state.Genders;
export const getGendersEntities = (state: any) => state.Genders.entities;
export const getGendersIds = (state: any) => state.Genders.ids;

export const getGenders =
  createSelector(getGendersEntities, getGendersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getGenderById(id: string) {
  return (state: any) => state.Genders.entities[id];
}

export function getGendersById(ids: string[]) {
  return createSelector(getGendersEntities, (entities) => ids.map((id) => entities[id]));
}
