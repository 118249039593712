import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UiService} from '../../services/ui.service';
import * as _ from 'lodash';
import {groupBy} from '@progress/kendo-data-query';
import {MeasurementService} from '../../services/measurement.service';
import {LoopBackAuth} from '../../lb-sdk/services/core';
import {AppUserApi} from '../../lb-sdk/services/custom';
import {ApiExService} from '../../services/api-ex.service';

@Component({
    selector: 'app-customer-card-noscan',
    templateUrl: './customer-card-noscan.component.html',
    styleUrls: ['./customer-card-noscan.component.scss']
})
export class CustomerCardNoscanComponent implements OnInit, OnChanges {

    @Input() user;
    briefAvailable = false;

    favouredLeg = '*';
    otherLeg = '*';
    percent = 0;

    isGenderEdit = false;

    constructor(
        public ui: UiService,
        private apiEx: ApiExService,
        public auth: LoopBackAuth,
        private userApi: AppUserApi,
        private measurementService: MeasurementService,
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user && changes.user.currentValue) {

            if (this.user.workingSessionId) {
                this.measurementService.getMeasures(this.user.workingSessionId, (err: Error, m: any) => {

                    if (m && m.distribution && m.distribution.percentLeft && m.distribution.percentRight) {

                        if (m.distribution.percentLeft > m.distribution.percentRight) {
                            this.favouredLeg = 'left';
                            this.otherLeg = 'right';
                            this.percent = m.distribution.percentLeft - m.distribution.percentRight;
                        } else {
                            this.favouredLeg = 'right';
                            this.otherLeg = 'left';
                            this.percent = m.distribution.percentRight - m.distribution.percentLeft;
                        }


                        this.briefAvailable = true;
                    }

                });
            }
        }
    }

    onSaveGender(newGenderId) {
        this.apiEx.updateAttributesEx(this.userApi.getModelName(), this.user.id, {genderId: newGenderId}).subscribe((_user: any) => {
            //this.userApi.updateAttributes(this.user.id, {genderId: newGenderId}).subscribe((_user: any) => {
            //console.log(_user);

            this.user.genderId = newGenderId;
            this.isGenderEdit = false;
        });
    }
}
