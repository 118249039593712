/* tslint:disable */

declare var Object: any;
export interface MeasurementParameterInterface {
  "title"?: string;
  "name"?: string;
  "unit"?: string;
  "id"?: number;
}

export class MeasurementParameter implements MeasurementParameterInterface {
  "title": string;
  "name": string;
  "unit": string;
  "id": number;
  constructor(data?: MeasurementParameterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementParameter`.
   */
  public static getModelName() {
    return "MeasurementParameter";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementParameter for dynamic purposes.
  **/
  public static factory(data: MeasurementParameterInterface): MeasurementParameter{
    return new MeasurementParameter(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementParameter',
      plural: 'MeasurementParameters',
      path: 'MeasurementParameters',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "unit": {
          name: 'unit',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
