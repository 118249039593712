/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementSessionIntegrity } from '../models';

export const MeasurementSessionIntegrityActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementSessionIntegrity'), {
});
export const MeasurementSessionIntegrityActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementSessionIntegrity>(MeasurementSessionIntegrityActionTypes), {
});