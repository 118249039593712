/* tslint:disable */

declare var Object: any;
export interface MeasurementSessionIntegrityInterface {
  "code"?: string;
  "title"?: string;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class MeasurementSessionIntegrity implements MeasurementSessionIntegrityInterface {
  "code": string;
  "title": string;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: MeasurementSessionIntegrityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementSessionIntegrity`.
   */
  public static getModelName() {
    return "MeasurementSessionIntegrity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementSessionIntegrity for dynamic purposes.
  **/
  public static factory(data: MeasurementSessionIntegrityInterface): MeasurementSessionIntegrity{
    return new MeasurementSessionIntegrity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementSessionIntegrity',
      plural: 'MeasurementSessionIntegrities',
      path: 'MeasurementSessionIntegrities',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
