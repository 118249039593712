/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementSessionQuality } from '../models';

export const MeasurementSessionQualityActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementSessionQuality'), {
});
export const MeasurementSessionQualityActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementSessionQuality>(MeasurementSessionQualityActionTypes), {
});