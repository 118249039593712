/* tslint:disable */
import {
  FeatureData
} from '../index';

declare var Object: any;
export interface FeatureInterface {
  "title": string;
  "name"?: string;
  "id"?: number;
  featureData?: FeatureData[];
}

export class Feature implements FeatureInterface {
  "title": string;
  "name": string;
  "id": number;
  featureData: FeatureData[];
  constructor(data?: FeatureInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Feature`.
   */
  public static getModelName() {
    return "Feature";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Feature for dynamic purposes.
  **/
  public static factory(data: FeatureInterface): Feature{
    return new Feature(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Feature',
      plural: 'Features',
      path: 'Features',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        featureData: {
          name: 'featureData',
          type: 'FeatureData[]',
          model: 'FeatureData',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'featureId'
        },
      }
    }
  }
}
