/* tslint:disable */

declare var Object: any;
export interface BookingStatusInterface {
  "code"?: string;
  "title"?: string;
  "id"?: number;
}

export class BookingStatus implements BookingStatusInterface {
  "code": string;
  "title": string;
  "id": number;
  constructor(data?: BookingStatusInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BookingStatus`.
   */
  public static getModelName() {
    return "BookingStatus";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BookingStatus for dynamic purposes.
  **/
  public static factory(data: BookingStatusInterface): BookingStatus{
    return new BookingStatus(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BookingStatus',
      plural: 'BookingStatuses',
      path: 'BookingStatuses',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
