/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { BookingSlot, BookingSlotInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { BookingSlotActionTypes } from '../actions';

export interface BookingSlotsState extends EntityState<BookingSlot | BookingSlotInterface> {};

export const BookingSlotAdapter: EntityAdapter<BookingSlot | BookingSlotInterface> = createEntityAdapter<BookingSlot | BookingSlotInterface>();

const initialState: BookingSlotsState = BookingSlotAdapter.getInitialState({});

const cases = BaseReducerFactory<BookingSlotsState, BookingSlot | BookingSlotInterface>(BookingSlotActionTypes, BookingSlotAdapter);

/**
 * @module BookingSlotsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible BookingSlot reducer.
 */
export function BookingSlotsReducer(state = initialState, action: LoopbackAction): BookingSlotsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getBookingSlotsState = (state: any) => state.BookingSlots;
export const getBookingSlotsEntities = (state: any) => state.BookingSlots.entities;
export const getBookingSlotsIds = (state: any) => state.BookingSlots.ids;

export const getBookingSlots =
  createSelector(getBookingSlotsEntities, getBookingSlotsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getBookingSlotById(id: string) {
  return (state: any) => state.BookingSlots.entities[id];
}

export function getBookingSlotsById(ids: string[]) {
  return createSelector(getBookingSlotsEntities, (entities) => ids.map((id) => entities[id]));
}
