import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools'

import {environment} from 'environments/environment';
import {LoopbackReducer} from './shared/lb-sdk';
import {AppEffects} from './app.state';

@NgModule({
    imports: [
        StoreModule.forRoot(LoopbackReducer),
        !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : [],
        EffectsModule.forRoot(AppEffects)
    ]
})
export class AppStoreModule {
}
