/* tslint:disable */

declare var Object: any;
export interface MeasurementDataCompactInterface {
  "side"?: string;
  "time"?: number;
  "x_accel"?: number;
  "y_accel"?: number;
  "z_accel"?: number;
  "pitch"?: number;
  "roll"?: number;
  "yaw"?: number;
  "pressure"?: number;
  "humidity"?: number;
  "temperature"?: number;
  "battery"?: number;
  "id"?: number;
  "measurementSessionId"?: number;
  measurementSession?: any;
}

export class MeasurementDataCompact implements MeasurementDataCompactInterface {
  "side": string;
  "time": number;
  "x_accel": number;
  "y_accel": number;
  "z_accel": number;
  "pitch": number;
  "roll": number;
  "yaw": number;
  "pressure": number;
  "humidity": number;
  "temperature": number;
  "battery": number;
  "id": number;
  "measurementSessionId": number;
  measurementSession: any;
  constructor(data?: MeasurementDataCompactInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementDataCompact`.
   */
  public static getModelName() {
    return "MeasurementDataCompact";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementDataCompact for dynamic purposes.
  **/
  public static factory(data: MeasurementDataCompactInterface): MeasurementDataCompact{
    return new MeasurementDataCompact(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementDataCompact',
      plural: 'MeasurementDataCompacts',
      path: 'MeasurementDataCompacts',
      idName: 'id',
      properties: {
        "side": {
          name: 'side',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "time": {
          name: 'time',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "x_accel": {
          name: 'x_accel',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "y_accel": {
          name: 'y_accel',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "z_accel": {
          name: 'z_accel',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "pitch": {
          name: 'pitch',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "roll": {
          name: 'roll',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "yaw": {
          name: 'yaw',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "pressure": {
          name: 'pressure',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "humidity": {
          name: 'humidity',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "temperature": {
          name: 'temperature',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "battery": {
          name: 'battery',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementSessionId": {
          name: 'measurementSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        measurementSession: {
          name: 'measurementSession',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'measurementSessionId',
          keyTo: 'id'
        },
      }
    }
  }
}
