import {
    Component,
    ViewChild,
    AfterViewInit,
    ElementRef,
    HostListener, Input, OnChanges, SimpleChanges,
} from '@angular/core';

import * as THREE from 'three';
import {Vector3, LightShadow} from 'three';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {SSAARenderPass} from 'three/examples/jsm/postprocessing/SSAARenderPass';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {CopyShader} from 'three/examples/jsm/shaders/CopyShader';
import {SSAOBlurShader, SSAODepthShader, SSAOShader} from 'three/examples/jsm/shaders/SSAOShader';
import {FXAAShader} from 'three/examples/jsm/shaders/FXAAShader';
import * as TWEEN from '@tweenjs/tween.js';


@Component({
    selector: 'app-feet-3d-ecco',
    templateUrl: './feet-3d-ecco.component.html',
    styleUrls: ['./feet-3d-ecco.component.scss'],
})

export class Feet3dEccoComponent implements AfterViewInit, OnChanges {

    @Input() userId: number;
    @Input() scanUuid: string;
    @Input() scanId: string;

    @Input() arch: number;
    @Input() width: number;

    @Input() sizeText: string;

    @ViewChild('feet3d', {static: false})
    private canvasRef: ElementRef;

    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer;
    private manager: THREE.LoadingManager;
    private objLoader = new OBJLoader();
    private SVGLoader = new SVGLoader();
    private fbxLoader = new FBXLoader();
    private controls: OrbitControls;

    private feetGroup = new THREE.Object3D();

    currentCameraSetup: string;
    controlsEnabled: boolean = true;
    isDebug: boolean = true;

    private fbxModel = './assets/obj3d/medium_arch.obj';

    private whiteColorHex = 0xffffff;
    private basicMaterial = this.setUpNoShinyMaterial(this.whiteColorHex);

    private objects: any = {
        leftFoot: {
            //path: './assets/obj3d/high_poly_mesh_left_test.obj',
            path: './assets/obj3d/normal_arch_left.obj',
            // pos: new Vector3(8, 0, 0),
            rotation: new Vector3(0, 0, (-Math.PI / 180) * 0),
            pos: new Vector3(9, 0, 0),
            material: this.basicMaterial,
            castShadow: true,
        },
        rightFoot: {
            //path: './assets/obj3d/high_poly_mesh_right_test.obj',
            path: './assets/obj3d/normal_arch_right.obj',
            // pos: new Vector3(-12, 0, 0),
            rotation: new Vector3(0, 0, 0),
            pos: new Vector3(-9, 0, 0),
            material: this.basicMaterial,
            castShadow: true,
        },
        //rightFoot: {path: './assets/obj3d/volumental/right.obj', scale: 150, rotation: {z: Math.PI}, pos: new Vector3(-10, 20, 0), material: this.basicMaterial}, // volumental
        plane: {
            path: './assets/obj3d/background_plane.obj',
            pos: new Vector3(0, 0, 95),
            rotation: {x: 0.5 * Math.PI, z: 1 * Math.PI, y: Math.PI},
            material: this.basicMaterial,
        },
        midsole: {
            path: './assets/obj3d/midsole.fbx',
            pos: new Vector3(0, 0, 0),
            scale: 2,
            rotation: {x: Math.PI / 2, y: 0, z: 0},
            material: this.basicMaterial,
        },
    };

    cameraLoc: any = {
        main: {
            'pos': {'x': 13.56468930382399, 'y': 27.34196106101654, 'z': 27.388569264096425},
            'rotation': {'x': -0.8412309289087972, 'y': 0.3308440885782677, 'z': 0.3484760151299772},
            'target': {'x': 1.4337734826789421, 'y': 1.0129986637023425, 'z': 3.846918038110428}
        },
        temp: {
            'pos': {'x': -33.332635268187246, 'y': 18.227446605650123, 'z': -2.4937142604554525},
            'rotation': {'x': -1.9237083158597277, 'y': -1.0852617188742304, 'z': -1.9654153244989987},
            'target': {'x': 1.4337734826789421, 'y': 1.0129986637023425, 'z': 3.846918038110428}
        },
        temp2: {
            'pos': {'x': -15.012687899232, 'y': 13.099830093939644, 'z': 37.44254911291379},
            'rotation': {'x': -0.3453554124911926, 'y': -0.4316641815952846, 'z': -0.14940164753580754},
            'target': {'x': 1.4337734826789421, 'y': 1.0129986637023425, 'z': 3.846918038110428}
        },
        loading: {
            'pos': {'x': -30.533167641633167, 'y': 23.535855495587015, 'z': -40.80431779414324},
            'rotation': {'x': -2.6830111199374334, 'y': -0.5381238713168062, 'z': -2.893768482940004},
            'target': {'x': -0.16451508189480737, 'y': 1.0129986637023425, 'z': 4.817752986418184}
        }
    };

    // Render setup
    private usePostProcess = false;
    private useUseShadows = false;

    renderPass: any;
    ssaaRenderPass: any;
    ssaoPass: any;
    copyPass: any;

    effectComposer: any;

    constructor() {
        this.render = this.render.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.arch || changes.width) {
            if (this.arch && this.width) {
                console.log(this.arch, this.width);

                // if (this.objects.leftFoot.ref) {
                //     this.feetGroup.remove(this.objects.leftFoot.ref);
                //     this.objects.leftFoot.ref = null;
                // }
                //
                // if (this.objects.rightFoot.ref) {
                //     this.feetGroup.remove(this.objects.rightFoot.ref);
                //     this.objects.rightFoot.ref = null;
                // }

                this.feetGroup.remove(...this.feetGroup.children);

                //if (!this.objects.leftFoot.ref) {
                this.loadByParams(this.arch, this.width);
                //}
            }
        }
    }

    private get canvas(): HTMLCanvasElement {
        return this.canvasRef.nativeElement;
    }

    private createScene() {
        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color( 0xff0000 );
        this.scene.background = new THREE.Color(0xf6f6f6);
        //this.scene.background = new THREE.Color(0x000000);
        //this.scene.background = new THREE.Color(this.whiteColorHex);
        //this.scene.fog = new THREE.FogExp2(this.whiteColorHex, 0.004);
        // this.scene.add(new THREE.AxesHelper(200));
    }

    private setUpLoadManger() {
        this.manager = new THREE.LoadingManager();
        this.objLoader = new OBJLoader(this.manager);

        this.manager.onLoad = () => {

            console.log('Loading complete!');
            this.feetGroup.rotateX(1.5 * Math.PI);
            this.feetGroup.position.set(0, 0, 0);
            this.scene.add(this.feetGroup);

            // initialize things that need the group
            this.createCamera();
            this.createLights();

            this.setupControls();

            // this.moveCameraTo('loading');
            // this.tweenCamera('loading', 'temp', 600, 300, TWEEN.Easing.Linear.None, () => {
            //   this.tweenCamera('temp', 'temp2', 600, 0, TWEEN.Easing.Linear.None, () => {
            //     this.tweenCamera('temp2', 'main', 600, 0, TWEEN.Easing.Quadratic.Out);
            //   });
            // });

            this.moveCameraTo('temp');
            this.tweenCamera('temp', 'main', 2000, 300, TWEEN.Easing.Quadratic.Out);

            this.animate();
        };
    }

    private loadSvg(
        name: string,
        url: string,
        pos: any,
        scale: number,
        color: any
    ) {
        this.SVGLoader.load(url, (data) => {
            let group = new THREE.Group();
            group.scale.multiplyScalar(scale);
            for (let i = 0; i < data.paths.length; i++) {
                let path = data.paths[i];

                let material = new THREE.MeshBasicMaterial({
                    color: color,
                    side: THREE.DoubleSide,
                    depthWrite: true, // was false
                });

                let shapes = path.toShapes(true);

                for (let j = 0; j < shapes.length; j++) {
                    let shape = shapes[j];
                    let geometry = new THREE.ShapeBufferGeometry(shape);
                    let mesh = new THREE.Mesh(geometry, material);
                    group.add(mesh);
                }
            }

            group.rotation.x = Math.PI / 2;
            group.position.set(pos.x, pos.y, pos.z);
            group.visible = true;
            this.scene.add(group);
        });
    }

    private loadObjResource(objInfo: any, group: THREE.Group | any = null) {
        this.objLoader.load(
            objInfo.path,
            (model) => {
                model.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material = objInfo.material;

                        if (this.useUseShadows) {
                            child.castShadow = objInfo.castShadow == true;
                        }
                    }
                });

                if (objInfo.pos) {
                    model.position.x = objInfo.pos.x;
                    model.position.y = objInfo.pos.y;
                    model.position.z = objInfo.pos.z;
                }

                if (objInfo.rotation) {
                    model.rotation.set(
                        objInfo.rotation.x ? objInfo.rotation.x : 0,
                        objInfo.rotation.y ? objInfo.rotation.y : 0,
                        objInfo.rotation.z ? objInfo.rotation.z : 0
                    );
                }

                if (objInfo.scale) {
                    model.scale.set(objInfo.scale, objInfo.scale, objInfo.scale);
                }

                objInfo.ref = model;
                group ? group.add(model) : this.scene.add(model);
            },
            undefined,
            (error) => {
                console.error('Error', error);
            }
        );
    }

    private loadFbxResource(objInfo: any, group: THREE.Group | any = null) {
        this.fbxLoader.load(
            objInfo.path,
            (model) => {
                model.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material = objInfo.material;

                        if (this.useUseShadows) {
                            child.castShadow = objInfo.castShadow == true;
                        }
                    }
                });

                if (objInfo.pos) {
                    model.position.x = objInfo.pos.x;
                    model.position.y = objInfo.pos.y;
                    model.position.z = objInfo.pos.z;
                }

                if (objInfo.rotation) {
                    model.rotation.set(
                        objInfo.rotation.x ? objInfo.rotation.x : 0,
                        objInfo.rotation.y ? objInfo.rotation.y : 0,
                        objInfo.rotation.z ? objInfo.rotation.z : 0
                    );
                }

                if (objInfo.scale) {
                    model.scale.set(objInfo.scale, objInfo.scale, objInfo.scale);
                }

                group ? group.add(model) : this.scene.add(model);
            },
            undefined,
            (error) => {
                console.error('Error', error);
            }
        );
    }

    private setUpNoShinyMaterial(color): any {
        return new THREE.MeshLambertMaterial({
            color: 0xe0e0e0,
            reflectivity: 1,
        });
    }

    private createShadowPlane() {
        const shadowPlane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(60, 60),
            new THREE.ShadowMaterial({
                color: 0x000000,
                transparent: true,
                opacity: 0.5,
            })
        );
        // console.log(shadowPlane)
        shadowPlane.rotation.x = Math.PI;
        shadowPlane.receiveShadow = true;
        shadowPlane.position.set(0, 0.01, 0);

        // console.log(shadowPlane);
        if (this.useUseShadows) {
            this.scene.add(shadowPlane);
        }
    }

    private loadModels() {
        this.loadObjResource(this.objects.rightFoot, this.feetGroup);
        this.loadObjResource(this.objects.leftFoot, this.feetGroup);
        //this.loadObjResource(this.objects.plane);
        //this.loadFbxResource(this.objects.midsole);
    }

    private addDirectLight(
        color: string | number | THREE.Color,
        intesity: number,
        pos: any,
        castShadows: boolean,
        helper: boolean
    ) {
        let light = new THREE.DirectionalLight(color, intesity);

        light.position.set(pos.x, pos.y, pos.z);

        //light.target = this.feetGroup;

        //Light Shadows
        light.castShadow = castShadows;

        if (castShadows) {
            let cameraShadowsSize = 25;
            light.shadow.camera.right = cameraShadowsSize;
            light.shadow.camera.left = -cameraShadowsSize;
            light.shadow.camera.top = 3 * cameraShadowsSize;
            light.shadow.camera.bottom = -cameraShadowsSize;

            light.shadow.camera.near = 1;
            light.shadow.camera.far = 1000;
            light.shadow.bias = 0.0001;
            light.shadow.mapSize.width = 1042;
            light.shadow.mapSize.height = 1042;
            // light.shadowBias = 0.00001;
            //console.log(light.shadow.toJSON());
            // light.shadow.mapSize.width = 400;
            // light.shadow.mapSize.height = 400;

            // light.shadowBias = -0.001;
            // light.shadow.radius = 10;

            // shadow helper
            // let shadowCameraHelper = new THREE.CameraHelper(light.shadow.camera);
            // shadowCameraHelper.visible = true;
            // this.scene.add(shadowCameraHelper);
        }

        this.scene.add(light);

        if (helper) {
            let helper = new THREE.DirectionalLightHelper(light, 20);
            this.scene.add(helper);
        }
    }

    private createLights() {
        let lightsHelper: boolean = false;

        let intensity = 0.5;

        // Top light
        this.addDirectLight(
            this.whiteColorHex,
            intensity - 0.05,
            new THREE.Vector3(0, 100, 0),
            true,
            lightsHelper
        );

        // Right Light
        this.addDirectLight(
            this.whiteColorHex,
            intensity,
            new THREE.Vector3(100, 40, 0),
            false,
            lightsHelper
        );

        //Left Light
        this.addDirectLight(
            this.whiteColorHex,
            intensity,
            new THREE.Vector3(-100, 40, 0),
            false,
            lightsHelper
        );

        //test compbine with fog
        var hemiLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.3);
        // console.log(hemiLight)
        hemiLight.position.set(10, 10, 10);
        // var helper = new HemisphereLightHelper(hemiLight, 20);
        //this.scene.add(hemiLight);

        let ambient = new THREE.AmbientLight(0xffffff, 0.3);
        // ambient.color.setHSL( 0, 0, 0.1 );
        this.scene.add(ambient);
    }

    // Camera & Tweening

    //private fieldOfView: number = 55; // was, but too close
    private fieldOfView: number = 70;
    private nearClippingPane: number = 1;
    private farClippingPane: number = 150;

    private createCamera() {
        //here only camera initialization the rest on moveCameraTo()
        const aspectRatio = this.getAspectRatio();
        this.camera = new THREE.PerspectiveCamera(
            this.fieldOfView,
            aspectRatio,
            this.nearClippingPane,
            this.farClippingPane
        );
        // Set position and look at
        // this.camera.position.set(
        //   this.cameraPos.x,
        //   this.cameraPos.y,
        //   this.cameraPos.z
        // );
        //this.camera.lookAt(this.feetGroup.position);
    }

    moveCameraTo(setup: string) {
        this.currentCameraSetup = setup;
        this.moveCameraToEx(this.cameraLoc[setup]);
    }

    moveCameraToEx(loc: any) {
        this.camera.position.set(loc.pos.x, loc.pos.y, loc.pos.z);
        this.camera.rotation.set(loc.rotation.x, loc.rotation.y, loc.rotation.z);

        if (this.controls) {
            this.controls.update();
            this.controls.target.set(loc.target.x, loc.target.y, loc.target.z);
            this.controls.update();
        }
    }

    tweenCamera(startCameraSetup: string, endCameraSetup: string, tweenDuration: number, delay: number, easing: any, callback: Function = null) {
        //if(startCameraSetup === endCameraSetup) return;

        TWEEN.removeAll();

        let tween = new TWEEN.Tween({
            px: this.cameraLoc[startCameraSetup].pos.x,
            py: this.cameraLoc[startCameraSetup].pos.y,
            pz: this.cameraLoc[startCameraSetup].pos.z,
            rx: this.cameraLoc[startCameraSetup].rotation.x,
            ry: this.cameraLoc[startCameraSetup].rotation.y,
            rz: this.cameraLoc[startCameraSetup].rotation.z,
            tx: this.cameraLoc[startCameraSetup].target.x,
            ty: this.cameraLoc[startCameraSetup].target.y,
            tz: this.cameraLoc[startCameraSetup].target.z
        })
            .to({
                px: this.cameraLoc[endCameraSetup].pos.x,
                py: this.cameraLoc[endCameraSetup].pos.y,
                pz: this.cameraLoc[endCameraSetup].pos.z,
                rx: this.cameraLoc[endCameraSetup].rotation.x,
                ry: this.cameraLoc[endCameraSetup].rotation.y,
                rz: this.cameraLoc[endCameraSetup].rotation.z,
                tx: this.cameraLoc[endCameraSetup].target.x,
                ty: this.cameraLoc[endCameraSetup].target.y,
                tz: this.cameraLoc[endCameraSetup].target.z
            }, tweenDuration)
            .onUpdate(res => {
                this.moveCameraToEx({
                    pos: {x: res.px, y: res.py, z: res.pz},
                    rotation: {x: res.rx, y: res.ry, z: res.rz},
                    target: {x: res.tx, y: res.ty, z: res.tz}
                });
            })
            .delay(delay)
            .easing(easing)
            .start()
            .onComplete((x) => {
                this.currentCameraSetup = endCameraSetup;

                if (callback) {
                    callback();
                }
            });
    }


    private getAspectRatio(): number {
        let height = this.canvas.clientHeight;
        if (height === 0) {
            return 0;
        }
        return this.canvas.clientWidth / this.canvas.clientHeight;
    }

    private animate() {
        window.requestAnimationFrame(() => this.animate());

        TWEEN.update();

        if (this.usePostProcess) {
            this.setupPost();
        } else {
            this.render();
        }
    }

    public render() {
        if (!this.usePostProcess) {
            this.renderer.render(this.scene, this.camera);
        } else {
            this.effectComposer.render();
        }
    }

    private setupRendering() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            alpha: true
        });

        // this.renderer.toneMapping = THREE.ReinhardToneMapping;
        // this.renderer.toneMappingExposure = 2;

        if (this.useUseShadows) {
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        }

        this.renderer.localClippingEnabled = true;
        this.renderer.setPixelRatio(devicePixelRatio);
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
        // this.renderer.setClearColor(this.whiteColorHex, 1);
        this.renderer.setClearColor(0xf6f6f6, 1);
        this.renderer.autoClear = false;
    }

    private addControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);

        this.controls.minAzimuthAngle = 0; // limit for horizontaly rotation
        this.controls.maxAzimuthAngle = 0; // limit for horizontaly rotation
        this.controls.maxPolarAngle = Math.PI / 2.3; // down limit for vertical

        /*  Exaplanation about this ↓: if not set to false, dolly zoom (means moving camera forward backwords, instead of changinf FOV)
            conflicts with manual change of FOV throught wheel and nothing works properly.
            Setting this to false solve this conflict.
        */
        this.controls.enableZoom = false;
    }

    setupControls() {
        if (this.controlsEnabled) {
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);

            this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
            this.controls.dampingFactor = 0.25;
            this.controls.screenSpacePanning = false;
            this.controls.minDistance = 10;

            if (this.isDebug && false) {
                this.controls.maxPolarAngle = 2 * Math.PI;
                this.controls.maxDistance = 2000;
            } else {
                this.controls.maxDistance = 60;
                this.controls.maxPolarAngle = Math.PI / 2 + Math.PI / 45;
            }
        }
    }

    effectFXAA: any;

    private setupPost() {
        // Setup render pass
        this.renderPass = new RenderPass(this.scene, this.camera);

        this.effectComposer = new EffectComposer(this.renderer);
        this.effectComposer.setPixelRatio(1); // ensure pixel ratio is always 1 for performance reasons

        this.ssaaRenderPass = new SSAARenderPass(
            this.scene,
            this.camera,
            'black',
            1
        );
        this.ssaaRenderPass.unbiased = false;
        this.ssaaRenderPass.sampleLevel = 2;

        this.effectComposer.addPass(this.ssaaRenderPass);

        let pixelratio = this.renderer.getPixelRatio();

        this.effectFXAA = new ShaderPass(FXAAShader);
        this.effectFXAA.uniforms['resolution'].value.x =
            1 / (window.innerWidth * pixelratio);
        this.effectFXAA.uniforms['resolution'].value.y =
            1 / (window.innerHeight * pixelratio);
        this.effectFXAA.renderToScreen = false;
        this.effectComposer.addPass(this.effectFXAA);

        this.ssaoPass = new ShaderPass(SSAOShader);

        // this.ssaoPass.renderToScreen = true
        // this.effectComposer.addPass(this.ssaoPass);
        // this.copyPass= new ShaderPass( CopyShader );
        // this.copyPass.renderToScreen = true;
        // this.effectComposer.addPass( this.copyPass );

        this.render();
    }

    //Events

    //private mouseDown: boolean = false;

    // public onMouseDown(event: MouseEvent) {
    //   // console.log(event);
    //   event.preventDefault();
    //   if (this.tweenComplete) {
    //     this.mouseDown = true;
    //   }
    // }
    //
    // public onMouseUp(event: MouseEvent) {
    //   this.mouseDown = false;
    // }

    // @HostListener('document:mousemove', ['$event'])
    // public onMouseMove(event: any) {
    //   // console.log(event);
    //   if (this.mouseDown) {
    //     //TODO: change pivot at the group so rotate arround it
    //     this.feetGroup.rotation.z += event.movementX * 0.005;
    //     this.render();
    //   }
    // }

    @HostListener('window:resize', ['$event'])
    public onResize(event: Event) {
        this.canvas.style.width = '100%';
        this.canvas.style.height = 'auto';
        // console.log(
        //   "onResize: " + this.canvas.clientWidth + ", " + this.canvas.clientHeight
        // );

        if (this.camera) {
            this.camera.aspect = this.getAspectRatio();
            this.camera.updateProjectionMatrix();
        }

        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

        let pixelRatio = this.renderer.getPixelRatio();

        if (this.usePostProcess) {
            this.effectFXAA.material.uniforms['resolution'].value.x =
                1 / (window.innerWidth * pixelRatio);
            this.effectFXAA.material.uniforms['resolution'].value.y =
                1 / (window.innerHeight * pixelRatio);
        }

        this.render();
    }

    // @HostListener('document:wheel', ['$event'])
    // onDocumentMouseWheel(event: any) {
    //   console.log(event)
    //   if (this.tweenComplete) {
    //     let fovMAX = 100;
    //     let fovMIN = 50;
    //
    //     this.camera.fov += event.deltaY * 0.05;
    //     // console.log(this.camera.fov)
    //     this.camera.fov = Math.max(Math.min(this.camera.fov, fovMAX), fovMIN);
    //     this.camera.projectionMatrix = new THREE.Matrix4().makePerspective(
    //       this.camera.fov,
    //       window.innerWidth / window.innerHeight,
    //       this.camera.near,
    //       this.camera.far
    //     );
    //     this.camera.updateProjectionMatrix();
    //     this.render();
    //   }
    // }

    //lifecycle

    textureLoaded = false;

    loadByParams(arch, width) {
        // this.objects.leftFoot.path = './assets/obj3d/feet/a' + arch + '/w' + width + '/left-7.obj';
        // this.objects.rightFoot.path = './assets/obj3d/feet/a' + arch + '/w' + width + '/right-7.obj';

        this.objects.leftFoot.path = './assets/obj3d/feet/a' + arch + '/w' + width + '/left.obj';
        this.objects.rightFoot.path = './assets/obj3d/feet/a' + arch + '/w' + width + '/right.obj';

        this.loadModels();

        this.loadTexture(
            'pngShadows',
            './assets/img/viz/ground-' + width + '.png',
            //'./assets/img/viz/ground-logo-inv.png',
            //{x: 0, y: -2, z: 0},
            {x: 0, y: -1, z: 0},
            {z: Math.PI},
            45,
            this.feetGroup
        );
        this.textureLoaded = true;
    }

    ngAfterViewInit() {

        console.log('after view init');

        this.createScene();
        //this.setUpLoadManger();
        //this.createShadowPlane(); //only for shadows, is transparent
        this.setupRendering();


        this.feetGroup.rotateX(1.5 * Math.PI);
        this.feetGroup.position.set(0, 0, 0);
        this.scene.add(this.feetGroup);

        // initialize things that need the group
        this.createCamera();
        this.createLights();

        this.setupControls();

        this.moveCameraTo('main');
        //this.tweenCamera('temp', 'main', 2000, 300, TWEEN.Easing.Quadratic.Out);

        this.animate();
        /*
        this.loadSvg(
          "logo",
          "assets/img/hex-logo.svg",
          {
            x: -3.45,
            y: 0.5,
            z: -4,
          },
          0.03,
          0xa0a0a0
        );
    */
    }

    textureMesh: any;

    //png image
    private loadTexture(
        name: string,
        url: string,
        pos: any,
        rotation: any,
        scale?: number,
        group?: any
    ) {
        let loader = new THREE.TextureLoader();

        loader.load(url, (texture) => {
            let material = new THREE.MeshBasicMaterial({
                map: texture,
                opacity: 0.5,
                transparent: true,
            });

            let geo = new THREE.PlaneBufferGeometry();
            let mesh = new THREE.Mesh(geo, material);

            if (scale) {
                mesh.scale.set(scale, scale, scale);
            }

            mesh.position.set(pos.x, pos.y, pos.z);

            mesh.name = name;

            mesh.rotation.set(rotation.x ? rotation.x : 0, rotation.y ? rotation.y : 0, rotation.z ? rotation.z : 0);
            group ? group.add(mesh) : this.scene.add(mesh);
            // this.feetGroup.add(pngMesh);
            // this.scene.add(pngMesh);
        });
    }

    onSaveView() {
        console.log(this.camera);

        let saved = {
            pos: {
                x: this.camera.position.x,
                y: this.camera.position.y,
                z: this.camera.position.z,
            },
            rotation: {
                x: this.camera.rotation.x,
                y: this.camera.rotation.y,
                z: this.camera.rotation.z,
            },
            target: {
                x: this.controls.target.x,
                y: this.controls.target.y,
                z: this.controls.target.z,
            }
        };

        console.log(JSON.stringify(saved));
    }
}


