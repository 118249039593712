import {Component, Input, OnInit} from '@angular/core';
import {UiService} from "../../../services/ui.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-content-card',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

    @Input() icon;
    @Input() title;
    @Input() isCompact;
    @Input() hasRaw: boolean = false;
    @Input() noPadding: boolean = false;

    constructor(public ui: UiService,
                private translate: TranslateService) {
    }

    ngOnInit() {
    }

}
