/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { FeatureDataActionTypes, FeatureDataActions } from '../actions/FeatureData';
import { LoopbackErrorActions } from '../actions/error';
import { FeatureDataApi } from '../services/index';

@Injectable()
export class FeatureDataEffects extends BaseLoopbackEffects {
  @Effect()
  public getFeature$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_FEATURE),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getFeature(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Feature', 'findSuccess'),
            of(new FeatureDataActions.getFeatureSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getFeatureFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementParameter$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_MEASUREMENTPARAMETER),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getMeasurementParameter(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementParameter', 'findSuccess'),
            of(new FeatureDataActions.getMeasurementParameterSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getMeasurementParameterFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementSession$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getMeasurementSession(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'findSuccess'),
            of(new FeatureDataActions.getMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.findByIdMeasurementDataCompact(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataCompact', 'FeatureDataMeasurementDataCompact', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementDataCompact', 'findByIdSuccess'),
            of(new FeatureDataActions.findByIdMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.findByIdMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.destroyByIdMeasurementDataCompact(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementDataCompact', 'deleteByIdSuccess'),
            of(new FeatureDataActions.destroyByIdMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.destroyByIdMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.updateByIdMeasurementDataCompact(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataCompact', 'FeatureDataMeasurementDataCompact', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementDataCompact', 'findByIdSuccess'),
            of(new FeatureDataActions.updateByIdMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.updateByIdMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.findByIdMeasurementData(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementData', 'FeatureDataMeasurementData', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementData', 'findByIdSuccess'),
            of(new FeatureDataActions.findByIdMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.findByIdMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.destroyByIdMeasurementData(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementData', 'deleteByIdSuccess'),
            of(new FeatureDataActions.destroyByIdMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.destroyByIdMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.updateByIdMeasurementData(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementData', 'FeatureDataMeasurementData', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementData', 'findByIdSuccess'),
            of(new FeatureDataActions.updateByIdMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.updateByIdMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getSensor$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_SENSOR),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getSensor(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Sensor', 'findSuccess'),
            of(new FeatureDataActions.getSensorSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getSensorFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.findByIdMeasurementDataNorm(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataNorm', 'FeatureDataMeasurementDataNorm', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementDataNorm', 'findByIdSuccess'),
            of(new FeatureDataActions.findByIdMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.findByIdMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.destroyByIdMeasurementDataNorm(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementDataNorm', 'deleteByIdSuccess'),
            of(new FeatureDataActions.destroyByIdMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.destroyByIdMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.updateByIdMeasurementDataNorm(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataNorm', 'FeatureDataMeasurementDataNorm', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementDataNorm', 'findByIdSuccess'),
            of(new FeatureDataActions.updateByIdMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.updateByIdMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getMeasurementDataCompact(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataCompact', 'FeatureDataMeasurementDataCompact', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataCompact', 'findSuccess'),
            of(new FeatureDataActions.getMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createMeasurementDataCompact(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataCompact', 'FeatureDataMeasurementDataCompact', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataCompact', 'findSuccess'),
            of(new FeatureDataActions.createMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DELETE_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.deleteMeasurementDataCompact(action.payload.id).pipe(
          map((response: any) => new FeatureDataActions.deleteMeasurementDataCompactSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new FeatureDataActions.deleteMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getMeasurementData(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementData', 'FeatureDataMeasurementData', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementData', 'findSuccess'),
            of(new FeatureDataActions.getMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createMeasurementData(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementData', 'FeatureDataMeasurementData', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementData', 'findSuccess'),
            of(new FeatureDataActions.createMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DELETE_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.deleteMeasurementData(action.payload.id).pipe(
          map((response: any) => new FeatureDataActions.deleteMeasurementDataSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new FeatureDataActions.deleteMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.GET_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.getMeasurementDataNorm(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataNorm', 'FeatureDataMeasurementDataNorm', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataNorm', 'findSuccess'),
            of(new FeatureDataActions.getMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.getMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createMeasurementDataNorm(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataNorm', 'FeatureDataMeasurementDataNorm', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataNorm', 'findSuccess'),
            of(new FeatureDataActions.createMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.DELETE_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.deleteMeasurementDataNorm(action.payload.id).pipe(
          map((response: any) => new FeatureDataActions.deleteMeasurementDataNormSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new FeatureDataActions.deleteMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyMeasurementDataCompact$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATACOMPACT),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createManyMeasurementDataCompact(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataCompact', 'FeatureDataMeasurementDataCompact', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataCompact', 'findSuccess'),
            of(new FeatureDataActions.createManyMeasurementDataCompactSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createManyMeasurementDataCompactFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyMeasurementData$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATA),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createManyMeasurementData(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementData', 'FeatureDataMeasurementData', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementData', 'findSuccess'),
            of(new FeatureDataActions.createManyMeasurementDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createManyMeasurementDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyMeasurementDataNorm$ = this.actions$
    .pipe(
      ofType(FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATANORM),
      mergeMap((action: LoopbackAction) =>
        this.featuredata.createManyMeasurementDataNorm(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementDataNorm', 'FeatureDataMeasurementDataNorm', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementDataNorm', 'findSuccess'),
            of(new FeatureDataActions.createManyMeasurementDataNormSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataActions.createManyMeasurementDataNormFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(FeatureDataApi) public featuredata: FeatureDataApi
  ) {
    super(actions$, featuredata, 'FeatureData', FeatureDataActionTypes, FeatureDataActions);
  }
}
