/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { CustomMidsole, CustomMidsoleInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { CustomMidsoleActionTypes } from '../actions';

export interface CustomMidsolesState extends EntityState<CustomMidsole | CustomMidsoleInterface> {};

export const CustomMidsoleAdapter: EntityAdapter<CustomMidsole | CustomMidsoleInterface> = createEntityAdapter<CustomMidsole | CustomMidsoleInterface>();

const initialState: CustomMidsolesState = CustomMidsoleAdapter.getInitialState({});

const cases = BaseReducerFactory<CustomMidsolesState, CustomMidsole | CustomMidsoleInterface>(CustomMidsoleActionTypes, CustomMidsoleAdapter);

/**
 * @module CustomMidsolesReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible CustomMidsole reducer.
 */
export function CustomMidsolesReducer(state = initialState, action: LoopbackAction): CustomMidsolesState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getCustomMidsolesState = (state: any) => state.CustomMidsoles;
export const getCustomMidsolesEntities = (state: any) => state.CustomMidsoles.entities;
export const getCustomMidsolesIds = (state: any) => state.CustomMidsoles.ids;

export const getCustomMidsoles =
  createSelector(getCustomMidsolesEntities, getCustomMidsolesIds, (entities, ids) => ids.map((id) => entities[id]));

export function getCustomMidsoleById(id: string) {
  return (state: any) => state.CustomMidsoles.entities[id];
}

export function getCustomMidsolesById(ids: string[]) {
  return createSelector(getCustomMidsolesEntities, (entities) => ids.map((id) => entities[id]));
}
