/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Order } from '../models';

export const OrderActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Order'), {
  GET_STATUS: type('[Order] getStatus'),
  GET_STATUS_SUCCESS: type('[Order] getStatus success'),
  GET_STATUS_FAIL: type('[Order] getStatus fail'),

});
export const OrderActions =
Object.assign(BaseLoopbackActionsFactory<Order>(OrderActionTypes), {

  /**
   * getStatus Action.
   * Fetches belongsTo relation status.
   *
   * @param {any} id Order id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getStatus: class implements Action {
    public readonly type = OrderActionTypes.GET_STATUS;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getStatusSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getStatusSuccess: class implements Action {
    public readonly type = OrderActionTypes.GET_STATUS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getStatusFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getStatusFail: class implements Action {
    public readonly type = OrderActionTypes.GET_STATUS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});