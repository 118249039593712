/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, PrintTaskStatus } from '../models';

export const PrintTaskStatusActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('PrintTaskStatus'), {
});
export const PrintTaskStatusActions =
Object.assign(BaseLoopbackActionsFactory<PrintTaskStatus>(PrintTaskStatusActionTypes), {
});