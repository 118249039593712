import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UiService} from '../services/ui.service';

@Injectable()
export class WorkingEventGuard implements CanActivate {

    constructor(private router: Router, private ui: UiService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (this.ui.workingEventId) {
            return true;
        }

        localStorage.setItem('_back_from_event', route.routeConfig.path);

        return this.router.parseUrl('/architect/event');
    }
}
