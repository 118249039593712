/* tslint:disable */
import {
  Feature,
  MeasurementParameter,
  MeasurementSession,
  MeasurementDataCompact,
  MeasurementData,
  Sensor,
  MeasurementDataNorm
} from '../index';

declare var Object: any;
export interface FeatureDataInterface {
  "timeStart"?: number;
  "timeEnd"?: number;
  "value"?: number;
  "valueMin"?: number;
  "valueMax"?: number;
  "valueAvg"?: number;
  "id"?: number;
  "featureId"?: number;
  "measurementParameterId"?: number;
  "measurementSessionId"?: number;
  "sensorId"?: number;
  feature?: Feature;
  measurementParameter?: MeasurementParameter;
  measurementSession?: MeasurementSession;
  measurementDataCompact?: MeasurementDataCompact[];
  measurementData?: MeasurementData[];
  sensor?: Sensor;
  measurementDataNorm?: MeasurementDataNorm[];
}

export class FeatureData implements FeatureDataInterface {
  "timeStart": number;
  "timeEnd": number;
  "value": number;
  "valueMin": number;
  "valueMax": number;
  "valueAvg": number;
  "id": number;
  "featureId": number;
  "measurementParameterId": number;
  "measurementSessionId": number;
  "sensorId": number;
  feature: Feature;
  measurementParameter: MeasurementParameter;
  measurementSession: MeasurementSession;
  measurementDataCompact: MeasurementDataCompact[];
  measurementData: MeasurementData[];
  sensor: Sensor;
  measurementDataNorm: MeasurementDataNorm[];
  constructor(data?: FeatureDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeatureData`.
   */
  public static getModelName() {
    return "FeatureData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeatureData for dynamic purposes.
  **/
  public static factory(data: FeatureDataInterface): FeatureData{
    return new FeatureData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeatureData',
      plural: 'FeatureData',
      path: 'FeatureData',
      idName: 'id',
      properties: {
        "timeStart": {
          name: 'timeStart',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "timeEnd": {
          name: 'timeEnd',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "value": {
          name: 'value',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "valueMin": {
          name: 'valueMin',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "valueMax": {
          name: 'valueMax',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "valueAvg": {
          name: 'valueAvg',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "featureId": {
          name: 'featureId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementParameterId": {
          name: 'measurementParameterId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementSessionId": {
          name: 'measurementSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sensorId": {
          name: 'sensorId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        feature: {
          name: 'feature',
          type: 'Feature',
          model: 'Feature',
          relationType: 'belongsTo',
                      keyFrom: 'featureId',
          keyTo: 'id'
        },
        measurementParameter: {
          name: 'measurementParameter',
          type: 'MeasurementParameter',
          model: 'MeasurementParameter',
          relationType: 'belongsTo',
                      keyFrom: 'measurementParameterId',
          keyTo: 'id'
        },
        measurementSession: {
          name: 'measurementSession',
          type: 'MeasurementSession',
          model: 'MeasurementSession',
          relationType: 'belongsTo',
                      keyFrom: 'measurementSessionId',
          keyTo: 'id'
        },
        measurementDataCompact: {
          name: 'measurementDataCompact',
          type: 'MeasurementDataCompact[]',
          model: 'MeasurementDataCompact',
          relationType: 'hasMany',
              modelThrough: 'FeatureDataMeasurementDataCompact',
              keyThrough: 'measurementDataCompactId',
                      keyFrom: 'id',
          keyTo: 'featureDataId'
        },
        measurementData: {
          name: 'measurementData',
          type: 'MeasurementData[]',
          model: 'MeasurementData',
          relationType: 'hasMany',
              modelThrough: 'FeatureDataMeasurementData',
              keyThrough: 'measurementDataId',
                      keyFrom: 'id',
          keyTo: 'featureDataId'
        },
        sensor: {
          name: 'sensor',
          type: 'Sensor',
          model: 'Sensor',
          relationType: 'belongsTo',
                      keyFrom: 'sensorId',
          keyTo: 'id'
        },
        measurementDataNorm: {
          name: 'measurementDataNorm',
          type: 'MeasurementDataNorm[]',
          model: 'MeasurementDataNorm',
          relationType: 'hasMany',
              modelThrough: 'FeatureDataMeasurementDataNorm',
              keyThrough: 'measurementDataNormId',
                      keyFrom: 'id',
          keyTo: 'featureDataId'
        },
      }
    }
  }
}
