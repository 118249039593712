/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { AppUserActionTypes, AppUserActions } from '../actions/AppUser';
import { LoopbackErrorActions } from '../actions/error';
import { AppUserApi } from '../services/index';

@Injectable()
export class AppUserEffects extends BaseLoopbackEffects {
  @Effect()
  public login$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.LOGIN),
      mergeMap((action: LoopbackAction) =>
        this.appuser.login(action.payload.credentials, action.payload.include).pipe(
          map((response: any) => new AppUserActions.loginSuccess(response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.loginFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public logout$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.LOGOUT),
      mergeMap((action: LoopbackAction) =>
        this.appuser.logout().pipe(
          map((response: any) => new AppUserActions.logoutSuccess(action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.logoutFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public dashboard$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.DASHBOARD),
      mergeMap((action: LoopbackAction) =>
        this.appuser.dashboard(action.payload.type).pipe(
          map((response: any) => new AppUserActions.dashboardSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.dashboardFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByRole$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.FIND_BY_ROLE),
      mergeMap((action: LoopbackAction) =>
        this.appuser.findByRole(action.payload.role).pipe(
          map((response: any) => new AppUserActions.findByRoleSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.findByRoleFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public sync$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.SYNC),
      mergeMap((action: LoopbackAction) =>
        this.appuser.sync(action.payload.appUser).pipe(
          map((response: any) => new AppUserActions.syncSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.syncFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public syncList$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.SYNC_LIST),
      mergeMap((action: LoopbackAction) =>
        this.appuser.syncList(action.payload.ids).pipe(
          map((response: any) => new AppUserActions.syncListSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.syncListFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public consent$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.CONSENT),
      mergeMap((action: LoopbackAction) =>
        this.appuser.consent(action.payload.email, action.payload.name).pipe(
          map((response: any) => new AppUserActions.consentSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.consentFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public passwordReset$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.PASSWORD_RESET),
      mergeMap((action: LoopbackAction) =>
        this.appuser.passwordReset(action.payload.email).pipe(
          map((response: any) => new AppUserActions.passwordResetSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.passwordResetFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateProfile$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.UPDATE_PROFILE),
      mergeMap((action: LoopbackAction) =>
        this.appuser.updateProfile(action.payload.name, action.payload.password).pipe(
          map((response: any) => new AppUserActions.updateProfileSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.updateProfileFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public participate$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.PARTICIPATE),
      mergeMap((action: LoopbackAction) =>
        this.appuser.participate(action.payload.email, action.payload.event).pipe(
          map((response: any) => new AppUserActions.participateSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.participateFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * AppUser specific actions
   */
  @Effect()
  public signup$ = this.actions$
    .pipe(
      ofType(AppUserActionTypes.SIGNUP),
      mergeMap((action: LoopbackAction) =>
        this.appuser.create(action.payload).pipe(
          map((response: any) => new AppUserActions.signupSuccess(action.payload, response, action.meta)),
          catchError((error: any) => concat(
            of(new AppUserActions.signupFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(AppUserApi) public appuser: AppUserApi
  ) {
    super(actions$, appuser, 'AppUser', AppUserActionTypes, AppUserActions);
  }
}
