/* tslint:disable */

declare var Object: any;
export interface MeasurementDataInterface {
  "timeOffset"?: number;
  "value"?: number;
  "variation"?: number;
  "id"?: number;
  "measurementSessionId"?: number;
  "measurementParameterId"?: number;
  "sensorId"?: number;
  measurementSession?: any;
  measurementParameter?: any;
  sensor?: any;
}

export class MeasurementData implements MeasurementDataInterface {
  "timeOffset": number;
  "value": number;
  "variation": number;
  "id": number;
  "measurementSessionId": number;
  "measurementParameterId": number;
  "sensorId": number;
  measurementSession: any;
  measurementParameter: any;
  sensor: any;
  constructor(data?: MeasurementDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementData`.
   */
  public static getModelName() {
    return "MeasurementData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementData for dynamic purposes.
  **/
  public static factory(data: MeasurementDataInterface): MeasurementData{
    return new MeasurementData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementData',
      plural: 'MeasurementData',
      path: 'MeasurementData',
      idName: 'id',
      properties: {
        "timeOffset": {
          name: 'timeOffset',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "value": {
          name: 'value',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "variation": {
          name: 'variation',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementSessionId": {
          name: 'measurementSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementParameterId": {
          name: 'measurementParameterId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sensorId": {
          name: 'sensorId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        measurementSession: {
          name: 'measurementSession',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'measurementSessionId',
          keyTo: 'id'
        },
        measurementParameter: {
          name: 'measurementParameter',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'measurementParameterId',
          keyTo: 'id'
        },
        sensor: {
          name: 'sensor',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'sensorId',
          keyTo: 'id'
        },
      }
    }
  }
}
