import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MarkersVisualArgs} from "@progress/kendo-angular-charts";
import {ConfigService} from './config.service';

@Injectable()
export class ChartService {

    public seriesColors: any[] = ['#ffff00', '#ff0000'];

    public markersSetup: any = {
        visible: false,
        visual: function (e: MarkersVisualArgs) {

        }
    };

    public labelsSetup: any = {
        color: 'white',
        font: '12px'
    };

    public labelsInactiveSetup: any = {
        color: 'gray'
    };

    public avgStepTime: number = 0;

    public landingVectorColor = 'red';

    public gaugeBg = '#374061';
    public gaugeRange = '#60C2A6';
    public gaugePointer = '#374061';

    public bgColor: string = '#141618';

    constructor(private configService: ConfigService) {
        //console.log(configService.templateConf.layout.variant);

        if(configService.templateConf.layout.variant === 'light') {
            this.labelsSetup.color = 'black';
            this.landingVectorColor = '#60C2A6';
            // this.seriesColors = ['#0000ff', '#ff0000'];
            this.seriesColors = ['#004c78', '#60C2A6'];
            this.bgColor = '#f6f6f6';
        }
    }
}
