/* tslint:disable */
import {
  MeasurementParameter,
  Sensor,
  MeasurementSession
} from '../index';

declare var Object: any;
export interface MeasurementDataNormInterface {
  "timeOffset"?: number;
  "value"?: number;
  "variation"?: number;
  "id"?: number;
  "measurementParameterId"?: number;
  "sensorId"?: number;
  "measurementSessionId"?: number;
  measurementParameter?: MeasurementParameter;
  sensor?: Sensor;
  measurementSession?: MeasurementSession;
}

export class MeasurementDataNorm implements MeasurementDataNormInterface {
  "timeOffset": number;
  "value": number;
  "variation": number;
  "id": number;
  "measurementParameterId": number;
  "sensorId": number;
  "measurementSessionId": number;
  measurementParameter: MeasurementParameter;
  sensor: Sensor;
  measurementSession: MeasurementSession;
  constructor(data?: MeasurementDataNormInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementDataNorm`.
   */
  public static getModelName() {
    return "MeasurementDataNorm";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementDataNorm for dynamic purposes.
  **/
  public static factory(data: MeasurementDataNormInterface): MeasurementDataNorm{
    return new MeasurementDataNorm(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementDataNorm',
      plural: 'MeasurementDataNorms',
      path: 'MeasurementDataNorms',
      idName: 'id',
      properties: {
        "timeOffset": {
          name: 'timeOffset',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "value": {
          name: 'value',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "variation": {
          name: 'variation',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementParameterId": {
          name: 'measurementParameterId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sensorId": {
          name: 'sensorId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementSessionId": {
          name: 'measurementSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        measurementParameter: {
          name: 'measurementParameter',
          type: 'MeasurementParameter',
          model: 'MeasurementParameter',
          relationType: 'belongsTo',
                      keyFrom: 'measurementParameterId',
          keyTo: 'id'
        },
        sensor: {
          name: 'sensor',
          type: 'Sensor',
          model: 'Sensor',
          relationType: 'belongsTo',
                      keyFrom: 'sensorId',
          keyTo: 'id'
        },
        measurementSession: {
          name: 'measurementSession',
          type: 'MeasurementSession',
          model: 'MeasurementSession',
          relationType: 'belongsTo',
                      keyFrom: 'measurementSessionId',
          keyTo: 'id'
        },
      }
    }
  }
}
