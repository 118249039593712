/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementDataCompact } from '../models';

export const MeasurementDataCompactActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementDataCompact'), {
});
export const MeasurementDataCompactActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementDataCompact>(MeasurementDataCompactActionTypes), {
});