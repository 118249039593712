/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementParameter, MeasurementParameterInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementParameterActionTypes } from '../actions';

export interface MeasurementParametersState extends EntityState<MeasurementParameter | MeasurementParameterInterface> {};

export const MeasurementParameterAdapter: EntityAdapter<MeasurementParameter | MeasurementParameterInterface> = createEntityAdapter<MeasurementParameter | MeasurementParameterInterface>();

const initialState: MeasurementParametersState = MeasurementParameterAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementParametersState, MeasurementParameter | MeasurementParameterInterface>(MeasurementParameterActionTypes, MeasurementParameterAdapter);

/**
 * @module MeasurementParametersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementParameter reducer.
 */
export function MeasurementParametersReducer(state = initialState, action: LoopbackAction): MeasurementParametersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementParametersState = (state: any) => state.MeasurementParameters;
export const getMeasurementParametersEntities = (state: any) => state.MeasurementParameters.entities;
export const getMeasurementParametersIds = (state: any) => state.MeasurementParameters.ids;

export const getMeasurementParameters =
  createSelector(getMeasurementParametersEntities, getMeasurementParametersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementParameterById(id: string) {
  return (state: any) => state.MeasurementParameters.entities[id];
}

export function getMeasurementParametersById(ids: string[]) {
  return createSelector(getMeasurementParametersEntities, (entities) => ids.map((id) => entities[id]));
}
