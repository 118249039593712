import {Routes, RouterModule} from '@angular/router';
import {RoleGuard} from '../guards/role.guard';
import {AuthGuard} from '../lb-sdk/guards';
import {ProfilePageComponent} from '../profile/profile-page.component';
import {SalespersonGuard} from '../guards/salesperson.guard';

// Route for content layout with sidebar, navbar and footer

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthGuard, SalespersonGuard],
        loadChildren: './dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: './customer/customer.module#CustomerModule',
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        component: ProfilePageComponent,
        data: {
            title: 'Profile'
        }
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        loadChildren: './users/users.module#UsersModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'sessions',
        canActivate: [RoleGuard],
        loadChildren: './sessions/sessions.module#SessionsModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'orders',
        canActivate: [RoleGuard],
        loadChildren: './orders/orders.module#OrdersModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'printers',
        canActivate: [RoleGuard],
        loadChildren: './printers/printers.module#PrintersModule',
        data: {
            roles: ['admin']
        }
    },
    {
        path: 'scanners',
        canActivate: [RoleGuard],
        loadChildren: './scanners/scanners.module#ScannersModule',
        data: {
            roles: ['admin']
        }
    },
    {
        path: 'scans',
        canActivate: [RoleGuard],
        loadChildren: './scans/scans.module#ScansModule',
        data: {
            roles: ['admin']
        }
    },
    {
        path: 'events',
        canActivate: [RoleGuard],
        loadChildren: './events/events.module#EventsModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'bookings',
        canActivate: [RoleGuard],
        loadChildren: './bookings/bookings.module#BookingsModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'bookings',
        canActivate: [RoleGuard],
        loadChildren: './bookings/bookings.module#BookingsModule',
        data: {
            roles: ['admin', 'manager']
        }
    },
    {
        path: 'tools',
        loadChildren: () => import('../../tools/tools.module').then(m => m.ToolsModule)
    },
];
