/* tslint:disable */
import {
  MeasurementSession
} from '../index';

declare var Object: any;
export interface WorkerInterface {
  "name"?: string;
  "path"?: string;
  "id"?: number;
  measurementSession?: MeasurementSession[];
}

export class Worker implements WorkerInterface {
  "name": string;
  "path": string;
  "id": number;
  measurementSession: MeasurementSession[];
  constructor(data?: WorkerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Worker`.
   */
  public static getModelName() {
    return "Worker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Worker for dynamic purposes.
  **/
  public static factory(data: WorkerInterface): Worker{
    return new Worker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Worker',
      plural: 'Workers',
      path: 'Workers',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "path": {
          name: 'path',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        measurementSession: {
          name: 'measurementSession',
          type: 'MeasurementSession[]',
          model: 'MeasurementSession',
          relationType: 'hasMany',
              modelThrough: 'WorkerMeasurementSession',
              keyThrough: 'measurementSessionId',
                      keyFrom: 'id',
          keyTo: 'workerId'
        },
      }
    }
  }
}
