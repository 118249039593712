import {Component, OnInit} from '@angular/core';
import {CardBaseComponent} from '../card-base/card-base.component';

@Component({
    selector: 'app-note-card',
    templateUrl: './note-card.component.html',
    styleUrls: ['./note-card.component.scss']
})
export class NoteCardComponent
    extends CardBaseComponent
    implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
