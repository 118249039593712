/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { PrinterActionTypes, PrinterActions } from '../actions/Printer';
import { LoopbackErrorActions } from '../actions/error';
import { PrinterApi } from '../services/index';

@Injectable()
export class PrinterEffects extends BaseLoopbackEffects {
  @Effect()
  public findByIdTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.FIND_BY_ID_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.findByIdTasks(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'PrinterTask', 'findByIdSuccess'),
            of(new PrinterActions.findByIdTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.findByIdTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.DESTROY_BY_ID_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.destroyByIdTasks(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterTask', 'deleteByIdSuccess'),
            of(new PrinterActions.destroyByIdTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.destroyByIdTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.UPDATE_BY_ID_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.updateByIdTasks(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'PrinterTask', 'findByIdSuccess'),
            of(new PrinterActions.updateByIdTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.updateByIdTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getActiveTask$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.GET_ACTIVETASK),
      mergeMap((action: LoopbackAction) =>
        this.printer.getActiveTask(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterTask', 'findSuccess'),
            of(new PrinterActions.getActiveTaskSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.getActiveTaskFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getStatus$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.GET_STATUS),
      mergeMap((action: LoopbackAction) =>
        this.printer.getStatus(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterStatus', 'findSuccess'),
            of(new PrinterActions.getStatusSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.getStatusFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getEnvironment$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.GET_ENVIRONMENT),
      mergeMap((action: LoopbackAction) =>
        this.printer.getEnvironment(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Environment', 'findSuccess'),
            of(new PrinterActions.getEnvironmentSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.getEnvironmentFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.GET_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.getTasks(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterTask', 'findSuccess'),
            of(new PrinterActions.getTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.getTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.CREATE_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.createTasks(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterTask', 'findSuccess'),
            of(new PrinterActions.createTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.createTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.DELETE_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.deleteTasks(action.payload.id).pipe(
          map((response: any) => new PrinterActions.deleteTasksSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new PrinterActions.deleteTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyTasks$ = this.actions$
    .pipe(
      ofType(PrinterActionTypes.CREATE_MANY_TASKS),
      mergeMap((action: LoopbackAction) =>
        this.printer.createManyTasks(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'PrinterTask', 'findSuccess'),
            of(new PrinterActions.createManyTasksSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new PrinterActions.createManyTasksFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(PrinterApi) public printer: PrinterApi
  ) {
    super(actions$, printer, 'Printer', PrinterActionTypes, PrinterActions);
  }
}
