/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, AppUser } from '../models';

export const AppUserActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('AppUser'), {
  LOGIN: type('[AppUser] login'),
  LOGIN_SUCCESS: type('[AppUser] login success'),
  LOGIN_FAIL: type('[AppUser] login fail'),

  LOGOUT: type('[AppUser] logout'),
  LOGOUT_SUCCESS: type('[AppUser] logout success'),
  LOGOUT_FAIL: type('[AppUser] logout fail'),

  DASHBOARD: type('[AppUser] dashboard'),
  DASHBOARD_SUCCESS: type('[AppUser] dashboard success'),
  DASHBOARD_FAIL: type('[AppUser] dashboard fail'),

  FIND_BY_ROLE: type('[AppUser] findByRole'),
  FIND_BY_ROLE_SUCCESS: type('[AppUser] findByRole success'),
  FIND_BY_ROLE_FAIL: type('[AppUser] findByRole fail'),

  SYNC: type('[AppUser] sync'),
  SYNC_SUCCESS: type('[AppUser] sync success'),
  SYNC_FAIL: type('[AppUser] sync fail'),

  SYNC_LIST: type('[AppUser] syncList'),
  SYNC_LIST_SUCCESS: type('[AppUser] syncList success'),
  SYNC_LIST_FAIL: type('[AppUser] syncList fail'),

  CONSENT: type('[AppUser] consent'),
  CONSENT_SUCCESS: type('[AppUser] consent success'),
  CONSENT_FAIL: type('[AppUser] consent fail'),

  PASSWORD_RESET: type('[AppUser] passwordReset'),
  PASSWORD_RESET_SUCCESS: type('[AppUser] passwordReset success'),
  PASSWORD_RESET_FAIL: type('[AppUser] passwordReset fail'),

  UPDATE_PROFILE: type('[AppUser] updateProfile'),
  UPDATE_PROFILE_SUCCESS: type('[AppUser] updateProfile success'),
  UPDATE_PROFILE_FAIL: type('[AppUser] updateProfile fail'),

  PARTICIPATE: type('[AppUser] participate'),
  PARTICIPATE_SUCCESS: type('[AppUser] participate success'),
  PARTICIPATE_FAIL: type('[AppUser] participate fail'),

}, {
  /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * AppUser specific action types
   */
  SIGNUP: type('[AppUser] Signup'),
  SIGNUP_SUCCESS: type('[AppUser] Signup success'),
  SIGNUP_FAIL: type('[AppUser] Signup fail'),
});
export const AppUserActions =
Object.assign(BaseLoopbackActionsFactory<AppUser>(AppUserActionTypes), {

  /**
   * login Action.
   * Login a user with username/email and password.
   *
   * @param {string} include Related objects to include in the response. See the description of return value for more details.
   *   Default value: `user`.
   *  - `rememberMe` - `boolean` - Whether the authentication credentials
   *     should be remembered in localStorage across app/browser restarts.
   *     Default: `true`.
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  login: class implements Action {
    public readonly type = AppUserActionTypes.LOGIN;
      public payload: {credentials: any, include: any, customHeaders};

    constructor(credentials: any, include: any = 'user', rememberMe: boolean = true, customHeaders?: Function, public meta?: any) {
      this.payload = {credentials, include, customHeaders};
    }
  },
  /**
   * loginSuccess Action.
   * 
   * @param {any} id 
   * The response body contains properties of the AccessToken created on login.
   * Depending on the value of `include` parameter, the body may contain additional properties:
   * 
   *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
   * 
   *
   * @param {any} meta (optional).
   * 
   */
  loginSuccess: class implements Action {
    public readonly type = AppUserActionTypes.LOGIN_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * loginFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  loginFail: class implements Action {
    public readonly type = AppUserActionTypes.LOGIN_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * logout Action.
   * Logout a user with access token.
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   * @param {any} meta (optional).
   * 
   */
  logout: class implements Action {
    public readonly type = AppUserActionTypes.LOGOUT;
      
    constructor(public meta?: any) {}
  },
  /**
   * logoutSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  logoutSuccess: class implements Action {
    public readonly type = AppUserActionTypes.LOGOUT_SUCCESS;
  
    constructor(public meta?: any) {}
  },
  /**
   * logoutFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  logoutFail: class implements Action {
    public readonly type = AppUserActionTypes.LOGOUT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * dashboard Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} type 
   * @param {object} options 
   * @param {any} meta (optional).
   * 
   */
  dashboard: class implements Action {
    public readonly type = AppUserActionTypes.DASHBOARD;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * dashboardSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  dashboardSuccess: class implements Action {
    public readonly type = AppUserActionTypes.DASHBOARD_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * dashboardFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  dashboardFail: class implements Action {
    public readonly type = AppUserActionTypes.DASHBOARD_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByRole Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} role 
   * @param {object} options 
   * @param {any} meta (optional).
   * 
   */
  findByRole: class implements Action {
    public readonly type = AppUserActionTypes.FIND_BY_ROLE;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * findByRoleSuccess Action.
   * 
   * @param {any} id 
   * Data properties:
   *
   *  - `items` – `{any}` - 
   * @param {any} meta (optional).
   * 
   */
  findByRoleSuccess: class implements Action {
    public readonly type = AppUserActionTypes.FIND_BY_ROLE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByRoleFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByRoleFail: class implements Action {
    public readonly type = AppUserActionTypes.FIND_BY_ROLE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * sync Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  sync: class implements Action {
    public readonly type = AppUserActionTypes.SYNC;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * syncSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  syncSuccess: class implements Action {
    public readonly type = AppUserActionTypes.SYNC_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * syncFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  syncFail: class implements Action {
    public readonly type = AppUserActionTypes.SYNC_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * syncList Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  syncList: class implements Action {
    public readonly type = AppUserActionTypes.SYNC_LIST;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * syncListSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  syncListSuccess: class implements Action {
    public readonly type = AppUserActionTypes.SYNC_LIST_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * syncListFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  syncListFail: class implements Action {
    public readonly type = AppUserActionTypes.SYNC_LIST_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * consent Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `name` – `{string}` - 
   * @param {any} meta (optional).
   * 
   */
  consent: class implements Action {
    public readonly type = AppUserActionTypes.CONSENT;
      public payload: {email: any, name: any, customHeaders};

    constructor(email: any = {}, name: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {email, name, customHeaders};
    }
  },
  /**
   * consentSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  consentSuccess: class implements Action {
    public readonly type = AppUserActionTypes.CONSENT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * consentFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  consentFail: class implements Action {
    public readonly type = AppUserActionTypes.CONSENT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * passwordReset Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   * @param {any} meta (optional).
   * 
   */
  passwordReset: class implements Action {
    public readonly type = AppUserActionTypes.PASSWORD_RESET;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * passwordResetSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  passwordResetSuccess: class implements Action {
    public readonly type = AppUserActionTypes.PASSWORD_RESET_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * passwordResetFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  passwordResetFail: class implements Action {
    public readonly type = AppUserActionTypes.PASSWORD_RESET_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateProfile Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `password` – `{string}` - 
   * @param {any} meta (optional).
   * 
   */
  updateProfile: class implements Action {
    public readonly type = AppUserActionTypes.UPDATE_PROFILE;
      public payload: {name: any, password: any, customHeaders};

    constructor(name: any = {}, password: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {name, password, customHeaders};
    }
  },
  /**
   * updateProfileSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateProfileSuccess: class implements Action {
    public readonly type = AppUserActionTypes.UPDATE_PROFILE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateProfileFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateProfileFail: class implements Action {
    public readonly type = AppUserActionTypes.UPDATE_PROFILE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * participate Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `event` – `{string}` - 
   * @param {any} meta (optional).
   * 
   */
  participate: class implements Action {
    public readonly type = AppUserActionTypes.PARTICIPATE;
      public payload: {email: any, event: any, customHeaders};

    constructor(email: any = {}, event: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {email, event, customHeaders};
    }
  },
  /**
   * participateSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  participateSuccess: class implements Action {
    public readonly type = AppUserActionTypes.PARTICIPATE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * participateFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  participateFail: class implements Action {
    public readonly type = AppUserActionTypes.PARTICIPATE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
}, {
  /**
   * AppUser specific actions
   */
  signup: class implements Action {
    public readonly type = AppUserActionTypes.SIGNUP;

    constructor(public payload: any, public meta?: any) { }
  },

  signupSuccess: class implements Action {
    public readonly type = AppUserActionTypes.SIGNUP_SUCCESS;
    public payload: {credentials: any, data: any};

    constructor(credentials: any, data: any, public meta?: any) {
      this.payload = {credentials, data};
    }
  },

  signupFail: class implements Action {
    public readonly type = AppUserActionTypes.SIGNUP_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});