import {Component, Input, OnInit} from '@angular/core';
import {SessionDataComponent} from "../session-data/session-data.component";
import {UiService} from "../../services/ui.service";
import {PlotBand} from "@progress/kendo-angular-charts";

@Component({
    selector: 'app-session-data-steps',
    templateUrl: './session-data-steps.component.html',
    styleUrls: ['./session-data-steps.component.scss']
})
export class SessionDataStepsComponent
    extends SessionDataComponent {

    constructor(public ui:UiService) {
        super(ui);
    }

}
