import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-card-base',
    templateUrl: './card-base.component.html',
    styleUrls: ['./card-base.component.scss']
})
export class CardBaseComponent implements OnInit {

    @Input() data;

    @Output() onEdit = new EventEmitter();
    @Output() onRemove = new EventEmitter();

    // TODO highlight search

    isEditing: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }
}
