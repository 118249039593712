/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Attachment, AttachmentInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { AttachmentActionTypes } from '../actions';

export interface AttachmentsState extends EntityState<Attachment | AttachmentInterface> {};

export const AttachmentAdapter: EntityAdapter<Attachment | AttachmentInterface> = createEntityAdapter<Attachment | AttachmentInterface>();

const initialState: AttachmentsState = AttachmentAdapter.getInitialState({});

const cases = BaseReducerFactory<AttachmentsState, Attachment | AttachmentInterface>(AttachmentActionTypes, AttachmentAdapter);

/**
 * @module AttachmentsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Attachment reducer.
 */
export function AttachmentsReducer(state = initialState, action: LoopbackAction): AttachmentsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getAttachmentsState = (state: any) => state.Attachments;
export const getAttachmentsEntities = (state: any) => state.Attachments.entities;
export const getAttachmentsIds = (state: any) => state.Attachments.ids;

export const getAttachments =
  createSelector(getAttachmentsEntities, getAttachmentsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getAttachmentById(id: string) {
  return (state: any) => state.Attachments.entities[id];
}

export function getAttachmentsById(ids: string[]) {
  return createSelector(getAttachmentsEntities, (entities) => ids.map((id) => entities[id]));
}
