/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { PrintTaskStatus, PrintTaskStatusInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { PrintTaskStatusActionTypes } from '../actions';

export interface PrintTaskStatussState extends EntityState<PrintTaskStatus | PrintTaskStatusInterface> {};

export const PrintTaskStatusAdapter: EntityAdapter<PrintTaskStatus | PrintTaskStatusInterface> = createEntityAdapter<PrintTaskStatus | PrintTaskStatusInterface>();

const initialState: PrintTaskStatussState = PrintTaskStatusAdapter.getInitialState({});

const cases = BaseReducerFactory<PrintTaskStatussState, PrintTaskStatus | PrintTaskStatusInterface>(PrintTaskStatusActionTypes, PrintTaskStatusAdapter);

/**
 * @module PrintTaskStatussReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible PrintTaskStatus reducer.
 */
export function PrintTaskStatussReducer(state = initialState, action: LoopbackAction): PrintTaskStatussState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getPrintTaskStatussState = (state: any) => state.PrintTaskStatuss;
export const getPrintTaskStatussEntities = (state: any) => state.PrintTaskStatuss.entities;
export const getPrintTaskStatussIds = (state: any) => state.PrintTaskStatuss.ids;

export const getPrintTaskStatuss =
  createSelector(getPrintTaskStatussEntities, getPrintTaskStatussIds, (entities, ids) => ids.map((id) => entities[id]));

export function getPrintTaskStatusById(id: string) {
  return (state: any) => state.PrintTaskStatuss.entities[id];
}

export function getPrintTaskStatussById(ids: string[]) {
  return createSelector(getPrintTaskStatussEntities, (entities) => ids.map((id) => entities[id]));
}
