import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ChartService} from "../../services/chart.service";
import {FeatureCode} from "../../enums/feature.code";
import * as _ from "lodash";
import {FeatureDataSeriesApi} from "../../lb-sdk/services/custom";

@Component({
    selector: 'app-gait-chart',
    templateUrl: './gait-chart.component.html',
    styleUrls: ['./gait-chart.component.scss']
})
export class GaitChartComponent implements OnInit {

    @Input() isMaximized: boolean = false;

    @Input() sessionId: number;

    distributionData = [];

    constructor(public chartUi: ChartService,
                private featureDataSeriesApi: FeatureDataSeriesApi,
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sessionId)
            if (changes.sessionId.currentValue && changes.sessionId.isFirstChange()) {
                this.buildChart(changes.sessionId.currentValue);
            }
    }

    buildChart(_sessionId) {
        this.featureDataSeriesApi.find({
            where: {
                measurementSessionId: _sessionId,
                featureId: FeatureCode.LandingVector
            },
            //include: ['sensorId', 'x', 'y']
        }).subscribe((data: any) => {
            const xOffset = 40;

            let dataMapped = data.map(d => {
                return {
                    x: d.x * 20 + (d.sensorId == 2 ? xOffset : -xOffset),
                    y: d.y,
                    sensorId: d.sensorId
                }
            });

            let dataBySides = _.groupBy(dataMapped, 'sensorId');

            this.distributionData = [
                {
                    label: 'L',
                    points: dataBySides[1]
                },
                {
                    label: 'R',
                    points: dataBySides[2]
                }
            ];
        })
    }
}
