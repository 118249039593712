/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { OrderStatus, OrderStatusInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { OrderStatusActionTypes } from '../actions';

export interface OrderStatussState extends EntityState<OrderStatus | OrderStatusInterface> {};

export const OrderStatusAdapter: EntityAdapter<OrderStatus | OrderStatusInterface> = createEntityAdapter<OrderStatus | OrderStatusInterface>();

const initialState: OrderStatussState = OrderStatusAdapter.getInitialState({});

const cases = BaseReducerFactory<OrderStatussState, OrderStatus | OrderStatusInterface>(OrderStatusActionTypes, OrderStatusAdapter);

/**
 * @module OrderStatussReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible OrderStatus reducer.
 */
export function OrderStatussReducer(state = initialState, action: LoopbackAction): OrderStatussState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getOrderStatussState = (state: any) => state.OrderStatuss;
export const getOrderStatussEntities = (state: any) => state.OrderStatuss.entities;
export const getOrderStatussIds = (state: any) => state.OrderStatuss.ids;

export const getOrderStatuss =
  createSelector(getOrderStatussEntities, getOrderStatussIds, (entities, ids) => ids.map((id) => entities[id]));

export function getOrderStatusById(id: string) {
  return (state: any) => state.OrderStatuss.entities[id];
}

export function getOrderStatussById(ids: string[]) {
  return createSelector(getOrderStatussEntities, (entities) => ids.map((id) => entities[id]));
}
