import {RouteInfo} from './sidebar.metadata';
import {environment} from '../../../environments/environment';

const getRoutes = (env) => {
    switch (env) {
        case 'ecco':
            return [
                /*
                    {
                        path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
                        submenu: [
                            {path: '/dashboard/analytics', title: 'Analytics', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}
                        ]
                    },
                */
                // Common
                {path: '/dashboard', title: 'Dashboard', faIcon: 'home', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},

                // Customer
                {path: '/dashboard', title: 'Me by ECCO', image: '/assets/img/icons/icon-feet.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester'], hasSession: true},
                {path: '/customer/data', title: 'Your data', image: '/assets/img/icons/icon-data.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester'], hasSession: true},
                {path: '/customer/booking', title: 'Your booking', image: '/assets/img/icons/icon-store.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester']},
                // {path: '/customer/product', title: 'Your product', image: '/assets/img/icons/icon-product.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester'], hasSession: true},
                {path: '/customer/midsole', title: 'Your midsole', image: '/assets/img/icons/icon-product.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester'], hasSession: false},
                {path: '/customer/data-advanced', title: 'Data studio', image: '/assets/img/icons/icon-studio.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester'], hasSession: true},
                //{path: '/customer/store', title: 'Your store', image: '/assets/img/icons/icon-store.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester']},
                //{path: '/profile', title: 'Your profile', image: '/assets/img/icons/icon-profile.svg', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['tester']},

                // Admin
                //{path: '/users/list', title: 'Customers', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                {path: '/users/list/tester', title: 'Customers', faIcon: 'user', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},

                // removed for presentation
                // {
                //     path: '', title: 'Users', faIcon: 'user', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
                //     submenu: [
                //         {path: '/users/list/customer', title: 'Customers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                //         {path: '/users/list/tester', title: 'Testers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                //         {path: '/users/list/admin', title: 'Admins', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                //         {path: '/users/list/manager', title: 'Managers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                //         {path: '/users/list/developer', title: 'Developers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                //         {path: '/users/list/all', title: 'All', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                //     ],
                //     roles: ['admin', 'manager']
                // },

                {path: '/sessions/list', faIcon: 'waveform-path', title: 'Sessions', icon: 'ft-clock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                {path: '/events/list', faIcon: 'store', title: 'Events', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                {path: '/bookings/list', faIcon: 'calendar-alt', title: 'Bookings', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                {path: '/orders/list', faIcon: 'box-alt', title: 'Orders', icon: 'ft-package', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                {path: '/printers/list', faIcon: 'print', title: 'Printers', icon: 'ft-printer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                {path: '/scanners/list', faIcon: 'video', title: 'Scanners', icon: 'ft-video', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']},
                {path: '/scans/list', faIcon: 'file-video', title: 'Scans', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin']}
            ];


        case 'on':
            return [
                // Common
                {path: '/dashboard', title: 'Dashboard', faIcon: 'home', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},

                // Admin
                {path: '/users/list/tester', title: 'Customers', faIcon: 'user', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
                {path: '/sessions/list', faIcon: 'waveform-path', title: 'Sessions', icon: 'ft-clock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles: ['admin', 'manager']},
            ];
    }
}

export const ROUTES: RouteInfo[] = getRoutes(environment.client);
