/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementSession } from '../models';

export const MeasurementSessionActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementSession'), {
  SYNC: type('[MeasurementSession] sync'),
  SYNC_SUCCESS: type('[MeasurementSession] sync success'),
  SYNC_FAIL: type('[MeasurementSession] sync fail'),

  SYNC_LIST: type('[MeasurementSession] syncList'),
  SYNC_LIST_SUCCESS: type('[MeasurementSession] syncList success'),
  SYNC_LIST_FAIL: type('[MeasurementSession] syncList fail'),

  STEPS: type('[MeasurementSession] steps'),
  STEPS_SUCCESS: type('[MeasurementSession] steps success'),
  STEPS_FAIL: type('[MeasurementSession] steps fail'),

});
export const MeasurementSessionActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementSession>(MeasurementSessionActionTypes), {

  /**
   * sync Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  sync: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * syncSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  syncSuccess: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * syncFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  syncFail: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * syncList Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  syncList: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC_LIST;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * syncListSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  syncListSuccess: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC_LIST_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * syncListFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  syncListFail: class implements Action {
    public readonly type = MeasurementSessionActionTypes.SYNC_LIST_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * steps Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `sessionId` – `{number}` - 
   * @param {any} meta (optional).
   * 
   */
  steps: class implements Action {
    public readonly type = MeasurementSessionActionTypes.STEPS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * stepsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  stepsSuccess: class implements Action {
    public readonly type = MeasurementSessionActionTypes.STEPS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * stepsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  stepsFail: class implements Action {
    public readonly type = MeasurementSessionActionTypes.STEPS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});