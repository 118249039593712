/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { BookingStatus, BookingStatusInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { BookingStatusActionTypes } from '../actions';

export interface BookingStatussState extends EntityState<BookingStatus | BookingStatusInterface> {};

export const BookingStatusAdapter: EntityAdapter<BookingStatus | BookingStatusInterface> = createEntityAdapter<BookingStatus | BookingStatusInterface>();

const initialState: BookingStatussState = BookingStatusAdapter.getInitialState({});

const cases = BaseReducerFactory<BookingStatussState, BookingStatus | BookingStatusInterface>(BookingStatusActionTypes, BookingStatusAdapter);

/**
 * @module BookingStatussReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible BookingStatus reducer.
 */
export function BookingStatussReducer(state = initialState, action: LoopbackAction): BookingStatussState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getBookingStatussState = (state: any) => state.BookingStatuss;
export const getBookingStatussEntities = (state: any) => state.BookingStatuss.entities;
export const getBookingStatussIds = (state: any) => state.BookingStatuss.ids;

export const getBookingStatuss =
  createSelector(getBookingStatussEntities, getBookingStatussIds, (entities, ids) => ids.map((id) => entities[id]));

export function getBookingStatusById(id: string) {
  return (state: any) => state.BookingStatuss.entities[id];
}

export function getBookingStatussById(ids: string[]) {
  return createSelector(getBookingStatussEntities, (entities) => ids.map((id) => entities[id]));
}
