/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { PrinterStatus, PrinterStatusInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { PrinterStatusActionTypes } from '../actions';

export interface PrinterStatussState extends EntityState<PrinterStatus | PrinterStatusInterface> {};

export const PrinterStatusAdapter: EntityAdapter<PrinterStatus | PrinterStatusInterface> = createEntityAdapter<PrinterStatus | PrinterStatusInterface>();

const initialState: PrinterStatussState = PrinterStatusAdapter.getInitialState({});

const cases = BaseReducerFactory<PrinterStatussState, PrinterStatus | PrinterStatusInterface>(PrinterStatusActionTypes, PrinterStatusAdapter);

/**
 * @module PrinterStatussReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible PrinterStatus reducer.
 */
export function PrinterStatussReducer(state = initialState, action: LoopbackAction): PrinterStatussState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getPrinterStatussState = (state: any) => state.PrinterStatuss;
export const getPrinterStatussEntities = (state: any) => state.PrinterStatuss.entities;
export const getPrinterStatussIds = (state: any) => state.PrinterStatuss.ids;

export const getPrinterStatuss =
  createSelector(getPrinterStatussEntities, getPrinterStatussIds, (entities, ids) => ids.map((id) => entities[id]));

export function getPrinterStatusById(id: string) {
  return (state: any) => state.PrinterStatuss.entities[id];
}

export function getPrinterStatussById(ids: string[]) {
  return createSelector(getPrinterStatussEntities, (entities) => ids.map((id) => entities[id]));
}
