/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, PrinterTask } from '../models';

export const PrinterTaskActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('PrinterTask'), {
  GET_GENDER: type('[PrinterTask] getGender'),
  GET_GENDER_SUCCESS: type('[PrinterTask] getGender success'),
  GET_GENDER_FAIL: type('[PrinterTask] getGender fail'),

  GET_PRINTER: type('[PrinterTask] getPrinter'),
  GET_PRINTER_SUCCESS: type('[PrinterTask] getPrinter success'),
  GET_PRINTER_FAIL: type('[PrinterTask] getPrinter fail'),

});
export const PrinterTaskActions =
Object.assign(BaseLoopbackActionsFactory<PrinterTask>(PrinterTaskActionTypes), {

  /**
   * getGender Action.
   * Fetches belongsTo relation gender.
   *
   * @param {any} id PrinterTask id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getGender: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_GENDER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getGenderSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getGenderSuccess: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_GENDER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getGenderFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getGenderFail: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_GENDER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getPrinter Action.
   * Fetches belongsTo relation printer.
   *
   * @param {any} id PrinterTask id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getPrinter: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_PRINTER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getPrinterSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getPrinterSuccess: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_PRINTER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getPrinterFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getPrinterFail: class implements Action {
    public readonly type = PrinterTaskActionTypes.GET_PRINTER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});