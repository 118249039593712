import {Component, Input, OnInit} from '@angular/core';
import {MeasurementCode} from "../../enums/measurement.code";
import {MeasurementData} from "../../lb-sdk/models";
import {UiService} from "../../services/ui.service";
import {LineStyle} from "@progress/kendo-angular-charts";

@Component({
    selector: 'app-session-data',
    templateUrl: './session-data.component.html',
    styleUrls: ['./session-data.component.scss']
})
export class SessionDataComponent {

    @Input() data: any[];
    @Input() lineStyle: LineStyle;

    fieldsVisible: any = {};
    groups: any[] = [
        {
            name: 'acceleration',
            title: 'Acceleration (speed of movement)',
            title_jp: '加速度（移動の速さ）',
            description: 'The velocity (speed) that the foot travels during your stride. ' +
                'This changes according to the type of activity and the unique points/moments of each step.<br/>' +
                'Each step is a combination of acceleration through the X-Y-Z axis of the foot, with individual patterns varying between people and activities.',
            description_jp: '一歩の動作を行う際の足の速度。アクティビティーや歩行中の足の位置などによって変化します。<br/>' +
                '一歩はX・Y・Z軸における足の動作で定義されます。また、人やアクティビティーによって差があります。',

            fields: [
                {
                    field: 'x_accel',
                    code: MeasurementCode.Acceleration,
                    variation: 1,
                    title: 'Lateral Acceleration',
                    title_jp: '左右方向の加速度',
                    unit: 'm/s<sup>2</sup>',
                    description: 'The speed that the foot travels perpendicular to the body position. ' +
                        'If you stand still and swing your leg left and right, the changes in speeds are measurements of lateral acceleration.',
                    description_jp: '足が身体に対して垂直に移動する時の速度である。静止した状態で足を左右に振った時の速度の変化が左右方向の加速度になります。',
                },
                {
                    field: 'y_accel',
                    code: MeasurementCode.Acceleration,
                    variation: 2,
                    title: 'Longitudinal Acceleration',
                    title_jp: '前後方向の加速度 ',
                    unit: 'm/s<sup>2</sup>',
                    description_jp: '足が地面に並行して移動する時の速度である。静止した状態で足を上下に上げたり・下ろしたりする時の速度の変化が上下方向の加速度になります。',
                    description: 'The speed that the foot travels parallel to the body position. ' +
                        'If you stand still and swing your leg forward and back, the changes in speeds are measurements of longitudinal acceleration.'
                },
                {
                    field: 'z_accel',
                    code: MeasurementCode.Acceleration,
                    variation: 3,
                    title: 'Vertical Acceleration',
                    title_jp: '上下方向の加速度 ',
                    description_jp: '足が地面に対して垂直に移動する時の速度である。静止した状態で足を上下に上げたり・下ろしたりする時の速度の変化が上下方向の加速度になります。',
                    unit: 'm/s<sup>2</sup>',
                    description: 'The speed that the foot travels perpendicular to the ground. ' +
                        'If you stand still and lift your leg away (up) and towards (down) the ground, the changes in speeds are measurements of vertical acceleration.'
                }
            ]
        },
        {
            name: 'rotation',
            title: 'Movement/Joint Rotation',
            title_jp: '足の動き・関節の回転',
            description_jp: '一歩の動作を行う際の足の回転角度。足関節を元に測定していますが、踵や腰の動きにも影響されます。<br/>' +
                '一歩はX・Y・Z軸における足の動作で定義されます。また、人やアクティビティーによって差があります。',
            description: 'The degrees of motion of the foot during each step. ' +
                'This movement is measured around the ankle joint, however these movements are influenced by the knee and hip joints.<br/>' +
                'Each step is a combination of movement through the X-Y-Z axis at the foot, with individual patterns varying between people and activities.',
            fields: [
                {
                    field: 'pitch',
                    code: MeasurementCode.Pitch,
                    variation: 0,
                    title: 'Lateral Rotation (Y-Axis)',
                    title_jp: 'Y軸回転',
                    unit: 'deg',
                    description_jp: '足が地面に対して垂直に回転する角度の値。安静状態から足を上下に上げたり・下ろしたりする時の角度の変化がY軸回転になります。<br/>' +
                        'アクティビティーの種類や動きのアンバランスを表したりします。',
                    description: 'The degrees of movement that the foot travels perpendicular to the ground. ' +
                        'If you stand still and lift your foot away (up) and towards (down) the ground, the change of angle from when the foot was flat is the measure of lateral rotation.<br/>' +
                        'This can be an indication of the type of activity, or irregularities in movement.'
                },
                {
                    field: 'roll',
                    code: MeasurementCode.Roll,
                    variation: 0,
                    title: 'Longitudinal Rotation (X-Axis)',
                    title_jp: 'X軸回転',
                    unit: 'deg',
                    description_jp: '踵が内側と外側に回転する角度の値。安静状態から足を内側に回転させることをプロネーションと言います。<br/>' +
                        '安静状態から足を外側に回転させることをサピネーションと言います。<br/>' +
                        'アクティビティーの種類や膝で始まる動きを含め、足の動きのアンバランスを表したりします。',
                    description: 'The degrees of rotation at the ankle when the foot is rolled. ' +
                        'If you stand still and roll the foot in, lifting the outside of the foot off the ground, you are pronating the foot. If you stand still and roll the foot out, lifting the inside of the foot off the ground, you are supinating the foot.<br/>' +
                        'This can be an indication of the type of activity, or irregularities in movement, including those at the knee.'
                },
                {
                    field: 'yaw',
                    code: MeasurementCode.Yaw,
                    variation: 0,
                    title: 'Horizontal Rotation (Z-Axis)',
                    title_jp: 'Z軸回転',
                    unit: 'deg',
                    description_jp: '踵が静止状態でのつま先の部分の回転する角度の値。安静状態で踵を固定し、つま先を左右に動かすとZ軸の角度が変化します。<br/>' +
                        'アクティビティーの種類や膝で始まる動きを含め、足の動きのアンバランスを表したりします。',
                    description: 'The degrees of rotation of the forefoot when the heel is kept stationery. ' +
                        'If you stand still and keep the heel flat on the ground, moving the forefoot left and right will change the angle of horizontal rotation.<br/>' +
                        'This can be an indication of the type of activity, or irregularities in movement, including those at the hip.'
                },
            ]
        },
        {
            name: 'pressure',
            title: 'Internal Environment',
            title_jp: '内部環境',
            description_jp: '歩き方の効率の良さとフットウェアの快適性を決める要因はたくさんあります。負荷・温度・湿度はフットウェアのパフォーマンスを定める重要な要因となります。<br/>' +
                '湿度と温度の管理が悪いと快適性やパフォーマンスに悪影響が出るだけでなく、 歩行の障害などを表したりします。',
            description: 'There are many factors that influence the efficacy of our stride and footwear. ' +
                'Load, temperature, and humidity are key factors in the comfort and performance of footwear.<br/>' +
                'The combination of humidity and temperature, if left uncontrolled can negatively affect the comfort and performance of footwear, and be indicative of physiological abnormalities.',
            fields: [
                {
                    field: 'pressure',
                    code: MeasurementCode.Force,
                    variation: 1,
                    title: 'Load',
                    title_jp: '負荷\n',
                    unit: 'N/m<sup>2</sup>',
                    description_jp: '接地の際に足にかかる力はZ軸の負荷になります。この負荷は足の一定の領域に集中するか、足全体に分散されます。<br/>' +
                        '負荷は足の加速度や回転角度によって異なります。過剰の負荷や不規則的な負荷はアクティビティーの種類や最適でない歩行などを表したりします。<br/>' +
                        '過剰の負荷が長時間にわたって続くと足への悪影響が出たり、フットウェアの機能性の低下に繋がります。',
                    description: 'The load along the Z-Axis are the forces exerted on the foot when it makes contact with the ground. This load can be localised in a certain region of the foot or be an average of load across the whole foot. ' +
                        'Load is influenced by foot acceleration and rotation; a greater load, or load variabilities may be indicative of the type of activity or deviations from your optimal stride. ' +
                        'Large loading on the foot, over a period of time can negatively influence the foot and function of the footwear.'
                },
                {
                    field: 'temperature',
                    code: MeasurementCode.AmbientTemperature,
                    variation: 0,
                    title: 'Temperature',
                    title_jp: '温度',
                    unit: '<sup>o</sup>C',
                    description_jp: '人間の体にとって環境の最適な温度があります。一歩一歩が熱が発生する物理的な動作になります。<br/>' +
                        '靴は閉鎖環境の一種類であるために、熱を蓄えることになります。さらに外部環境からの影響も受けます。<br/>' +
                        '靴内の最適な気温を保つことでどのアクティビティーにおいても最大の快適性を保証することができます。',
                    description: 'The body has an optimal functional temperature range. ' +
                        'Each stride of a foot is a mechanical action, resulting in heat being released. ' +
                        'The internal environment of a shoe is enclosed and traps this heat within the shoe, however can also be influenced by the external conditions. ' +
                        'By maintaining the internal environment of a shoe in an optimal functional range, maximal comfort is achieved no matter the activity.'
                },
                {
                    field: 'humidity',
                    code: MeasurementCode.AmbientHumidity,
                    variation: 0,
                    title: 'Humidity',
                    title_jp: '湿度',
                    unit: '%',
                    description_jp: '足は体の中で最も汗腺が密集した場所になります。<br/>' +
                        'どのアクティビティーを行なっていても足は汗をかくため靴内の湿度は上がります。',
                    description: 'The feet have one of the largest concentrations of sweat glands in the body. ' +
                        'No matter the activity everyone’s feet sweat in the shoes, making the internal environment of the shoe more humid.'
                },
            ]
        },
    ];

    constructor(public ui:UiService) {
    }

}
