/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Printer, PrinterInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { PrinterActionTypes } from '../actions';

export interface PrintersState extends EntityState<Printer | PrinterInterface> {};

export const PrinterAdapter: EntityAdapter<Printer | PrinterInterface> = createEntityAdapter<Printer | PrinterInterface>();

const initialState: PrintersState = PrinterAdapter.getInitialState({});

const cases = BaseReducerFactory<PrintersState, Printer | PrinterInterface>(PrinterActionTypes, PrinterAdapter);

/**
 * @module PrintersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Printer reducer.
 */
export function PrintersReducer(state = initialState, action: LoopbackAction): PrintersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getPrintersState = (state: any) => state.Printers;
export const getPrintersEntities = (state: any) => state.Printers.entities;
export const getPrintersIds = (state: any) => state.Printers.ids;

export const getPrinters =
  createSelector(getPrintersEntities, getPrintersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getPrinterById(id: string) {
  return (state: any) => state.Printers.entities[id];
}

export function getPrintersById(ids: string[]) {
  return createSelector(getPrintersEntities, (entities) => ids.map((id) => entities[id]));
}
