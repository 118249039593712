export { ArcGaugeModule } from './arc-gauge/arc-gauge.module';
export { LinearGaugeModule } from './linear-gauge/linear-gauge.module';
export { RadialGaugeModule } from './radial-gauge/radial-gauge.module';
export { GaugesModule } from './gauges.module';
export * from './base-components';
export * from './services';
export * from './arc-gauge/arc-gauge.directives';
export * from './linear-gauge/linear-gauge.directives';
export * from './radial-gauge/radial-gauge.directives';
export { ResizeSensorComponent } from '@progress/kendo-angular-resize-sensor';
