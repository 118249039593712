/* tslint:disable */
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {flatMap} from 'rxjs/operators'
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';

import {getLoopbackAuthToken} from "../lb-sdk/reducers";
import {AppAuthActions} from "../actions/auth";
import {LoopbackAuthActions} from "../lb-sdk/actions/auth";

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private store: Store<any>,
                private router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(getLoopbackAuthToken).pipe(
            flatMap((token) => {

                // auth
                if (!token.userId) {
                    this.store.dispatch(new LoopbackAuthActions.guardFail());
                    return of(false);
                }

                // roles
                let roles = route.data['roles'] as string[];
                let rc = token.roles.filter(role => roles.indexOf(role) !== -1).length > 0;

                //console.log(roles);
                //console.log(token.roles);
                //console.log(rc);

                if (!rc)
                    this.store.dispatch(new AppAuthActions.roleGuardFail());

                return of(rc);
            })
        );
    }
}

