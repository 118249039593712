/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { FeatureActionTypes, FeatureActions } from '../actions/Feature';
import { LoopbackErrorActions } from '../actions/error';
import { FeatureApi } from '../services/index';

@Injectable()
export class FeatureEffects extends BaseLoopbackEffects {
  @Effect()
  public findByIdFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.FIND_BY_ID_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.findByIdFeatureData(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'FeatureData', 'findByIdSuccess'),
            of(new FeatureActions.findByIdFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.findByIdFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.DESTROY_BY_ID_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.destroyByIdFeatureData(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'FeatureData', 'deleteByIdSuccess'),
            of(new FeatureActions.destroyByIdFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.destroyByIdFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.UPDATE_BY_ID_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.updateByIdFeatureData(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'FeatureData', 'findByIdSuccess'),
            of(new FeatureActions.updateByIdFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.updateByIdFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.GET_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.getFeatureData(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'FeatureData', 'findSuccess'),
            of(new FeatureActions.getFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.getFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.CREATE_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.createFeatureData(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'FeatureData', 'findSuccess'),
            of(new FeatureActions.createFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.createFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.DELETE_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.deleteFeatureData(action.payload.id).pipe(
          map((response: any) => new FeatureActions.deleteFeatureDataSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new FeatureActions.deleteFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public bin$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.BIN),
      mergeMap((action: LoopbackAction) =>
        this.feature.bin(action.payload.req, action.payload.res, action.payload.feature, action.payload.key).pipe(
          map((response: any) => new FeatureActions.binSuccess(action.payload.id, action.payload.fk, action.meta)),
          catchError((error: any) => concat(
            of(new FeatureActions.binFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyFeatureData$ = this.actions$
    .pipe(
      ofType(FeatureActionTypes.CREATE_MANY_FEATUREDATA),
      mergeMap((action: LoopbackAction) =>
        this.feature.createManyFeatureData(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'FeatureData', 'findSuccess'),
            of(new FeatureActions.createManyFeatureDataSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureActions.createManyFeatureDataFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(FeatureApi) public feature: FeatureApi
  ) {
    super(actions$, feature, 'Feature', FeatureActionTypes, FeatureActions);
  }
}
