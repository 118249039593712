/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementSessionQuality, MeasurementSessionQualityInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementSessionQualityActionTypes } from '../actions';

export interface MeasurementSessionQualitysState extends EntityState<MeasurementSessionQuality | MeasurementSessionQualityInterface> {};

export const MeasurementSessionQualityAdapter: EntityAdapter<MeasurementSessionQuality | MeasurementSessionQualityInterface> = createEntityAdapter<MeasurementSessionQuality | MeasurementSessionQualityInterface>();

const initialState: MeasurementSessionQualitysState = MeasurementSessionQualityAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementSessionQualitysState, MeasurementSessionQuality | MeasurementSessionQualityInterface>(MeasurementSessionQualityActionTypes, MeasurementSessionQualityAdapter);

/**
 * @module MeasurementSessionQualitysReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementSessionQuality reducer.
 */
export function MeasurementSessionQualitysReducer(state = initialState, action: LoopbackAction): MeasurementSessionQualitysState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementSessionQualitysState = (state: any) => state.MeasurementSessionQualitys;
export const getMeasurementSessionQualitysEntities = (state: any) => state.MeasurementSessionQualitys.entities;
export const getMeasurementSessionQualitysIds = (state: any) => state.MeasurementSessionQualitys.ids;

export const getMeasurementSessionQualitys =
  createSelector(getMeasurementSessionQualitysEntities, getMeasurementSessionQualitysIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementSessionQualityById(id: string) {
  return (state: any) => state.MeasurementSessionQualitys.entities[id];
}

export function getMeasurementSessionQualitysById(ids: string[]) {
  return createSelector(getMeasurementSessionQualitysEntities, (entities) => ids.map((id) => entities[id]));
}
