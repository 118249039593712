import {Component, Input, OnInit} from '@angular/core';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GenderApi} from '../../lb-sdk/services/custom';
import {ActivatedRoute} from '@angular/router';
import {UiService} from '../../services/ui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-base-entity-edit',
    templateUrl: './base-entity-edit.component.html',
    styleUrls: ['./base-entity-edit.component.scss']
})
export class BaseEntityEditComponent
    extends DialogContentBase
    implements OnInit {

    public editForm: FormGroup = new FormGroup({
        'id': new FormControl()
    });

    dicts: any = {};
    isNew = false;
    dataItem: any;

    @Input() set item(dataItem: any) {
        this.isNew = dataItem.id == null;
        this.editForm.reset(dataItem);
        this.dataItem = dataItem;
    }

    constructor(public dialog: DialogRef,
                public route: ActivatedRoute,
                public translate: TranslateService,
                public ui: UiService) {

        super(dialog);
    }

    ngOnInit() {
    }

    public onSave(e): void {
        this.dialog.close({isSuccess: true, values: this.editForm.value});
    }

    public onCancel(e): void {
        this.dialog.close({isSuccess: false});
    }
}
