/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { BookingActionTypes, BookingActions } from '../actions/Booking';
import { LoopbackErrorActions } from '../actions/error';
import { BookingApi } from '../services/index';

@Injectable()
export class BookingEffects extends BaseLoopbackEffects {
  @Effect()
  public available$ = this.actions$
    .pipe(
      ofType(BookingActionTypes.AVAILABLE),
      mergeMap((action: LoopbackAction) =>
        this.booking.available(action.payload.id).pipe(
          map((response: any) => new BookingActions.availableSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new BookingActions.availableFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public reserved$ = this.actions$
    .pipe(
      ofType(BookingActionTypes.RESERVED),
      mergeMap((action: LoopbackAction) =>
        this.booking.reserved().pipe(
          map((response: any) => new BookingActions.reservedSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new BookingActions.reservedFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public rebook$ = this.actions$
    .pipe(
      ofType(BookingActionTypes.REBOOK),
      mergeMap((action: LoopbackAction) =>
        this.booking.rebook(action.payload.req).pipe(
          map((response: any) => new BookingActions.rebookSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new BookingActions.rebookFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public view$ = this.actions$
    .pipe(
      ofType(BookingActionTypes.VIEW),
      mergeMap((action: LoopbackAction) =>
        this.booking.view(action.payload.hash).pipe(
          map((response: any) => new BookingActions.viewSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new BookingActions.viewFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public cancel$ = this.actions$
    .pipe(
      ofType(BookingActionTypes.CANCEL),
      mergeMap((action: LoopbackAction) =>
        this.booking.cancel(action.payload.hash).pipe(
          map((response: any) => new BookingActions.cancelSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new BookingActions.cancelFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(BookingApi) public booking: BookingApi
  ) {
    super(actions$, booking, 'Booking', BookingActionTypes, BookingActions);
  }
}
