import {Component, SimpleChanges} from '@angular/core';
import {MeasurementService} from "../../services/measurement.service";
import {ChartService} from "../../services/chart.service";
import {TranslateService} from "@ngx-translate/core";
import {SessionChartComponent} from "../session-chart/session-chart.component";
import * as _ from "lodash";
import {groupBy} from "@progress/kendo-data-query";
import {LineStyle} from "@progress/kendo-angular-charts";

@Component({
    selector: 'app-session-chart-steps',
    templateUrl: './session-chart-steps.component.html',
    styleUrls: ['./session-chart-steps.component.scss']
})
export class SessionChartStepsComponent
    extends SessionChartComponent {

    featureData: any;
    ls: LineStyle = 'smooth';

    constructor(public chartUi: ChartService,
                public translate: TranslateService) {

        super(chartUi, translate);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            //console.log(this.field.code);

            this.featureData = this.data.steps.map((s: any) =>
                groupBy(_.filter(s, d => d.measurementParameterId == this.field.code && d.variation == this.field.variation), [{field: "sensorId"}])
            );
        }

        if(changes.lineStyle && changes.lineStyle.currentValue)
            this.ls = changes.lineStyle.currentValue;
    }
}

