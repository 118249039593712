/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { WorkerActionTypes, WorkerActions } from '../actions/Worker';
import { LoopbackErrorActions } from '../actions/error';
import { WorkerApi } from '../services/index';

@Injectable()
export class WorkerEffects extends BaseLoopbackEffects {
  @Effect()
  public findByIdMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.FIND_BY_ID_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.findByIdMeasurementSession(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementSession', 'WorkerMeasurementSession', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementSession', 'findByIdSuccess'),
            of(new WorkerActions.findByIdMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.findByIdMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.DESTROY_BY_ID_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.destroyByIdMeasurementSession(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'deleteByIdSuccess'),
            of(new WorkerActions.destroyByIdMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.destroyByIdMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.UPDATE_BY_ID_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.updateByIdMeasurementSession(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementSession', 'WorkerMeasurementSession', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'MeasurementSession', 'findByIdSuccess'),
            of(new WorkerActions.updateByIdMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.updateByIdMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.GET_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.getMeasurementSession(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementSession', 'WorkerMeasurementSession', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'findSuccess'),
            of(new WorkerActions.getMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.getMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.CREATE_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.createMeasurementSession(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementSession', 'WorkerMeasurementSession', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'findSuccess'),
            of(new WorkerActions.createMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.createMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.DELETE_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.deleteMeasurementSession(action.payload.id).pipe(
          map((response: any) => new WorkerActions.deleteMeasurementSessionSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new WorkerActions.deleteMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyMeasurementSession$ = this.actions$
    .pipe(
      ofType(WorkerActionTypes.CREATE_MANY_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.worker.createManyMeasurementSession(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'MeasurementSession', 'WorkerMeasurementSession', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'findSuccess'),
            of(new WorkerActions.createManyMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new WorkerActions.createManyMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(WorkerApi) public worker: WorkerApi
  ) {
    super(actions$, worker, 'Worker', WorkerActionTypes, WorkerActions);
  }
}
