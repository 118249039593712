import {Input, Output, Component, OnInit, EventEmitter} from '@angular/core';
import {LocalApiService} from '../../services/local-api.service';

@Component({
    selector: 'app-control-plusminus',
    templateUrl: './control-plusminus.component.html',
    styleUrls: ['./control-plusminus.component.scss']
})
export class ControlPlusminusComponent implements OnInit {

    @Input() param;
    @Input() value;
    selected = 0;

    @Output() changed = new EventEmitter();

    constructor(private rest: LocalApiService) {
    }

    ngOnInit() {
        if (this.value) {
            let idx = this.param.values.findIndex(v => v.code == this.value);
            this.selected = idx != -1 ? idx : 0;
        } else {
            this.selected = 0;
            //this.updateState();
        }

        this.updateState();
    }

    onMinus() {
        if (this.selected > 0) {
            this.selected--;
        } else {
            this.selected = 0;
        }

        this.updateState();
    }

    onPlus() {
        if (this.selected < this.param.values.length - 1) {
            this.selected++;
        } else {
            this.selected = this.param.values.length - 1;
        }

        this.updateState();
    }

    updateState() {
        let data = {};
        data[this.param.code] = this.param.values[this.selected].code;

        this.changed.emit({
            code: this.param.code,
            value: this.param.values[this.selected].code
        });

        // this.rest.updateLocalState(data).subscribe((data: any) => {
        //
        // });
    }
}
