/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Midsole } from '../models';

export const MidsoleActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Midsole'), {
});
export const MidsoleActions =
Object.assign(BaseLoopbackActionsFactory<Midsole>(MidsoleActionTypes), {
});