/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, BookingSlot } from '../models';

export const BookingSlotActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('BookingSlot'), {
  GET_EVENT: type('[BookingSlot] getEvent'),
  GET_EVENT_SUCCESS: type('[BookingSlot] getEvent success'),
  GET_EVENT_FAIL: type('[BookingSlot] getEvent fail'),

});
export const BookingSlotActions =
Object.assign(BaseLoopbackActionsFactory<BookingSlot>(BookingSlotActionTypes), {

  /**
   * getEvent Action.
   * Fetches belongsTo relation event.
   *
   * @param {any} id BookingSlot id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getEvent: class implements Action {
    public readonly type = BookingSlotActionTypes.GET_EVENT;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getEventSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getEventSuccess: class implements Action {
    public readonly type = BookingSlotActionTypes.GET_EVENT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getEventFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getEventFail: class implements Action {
    public readonly type = BookingSlotActionTypes.GET_EVENT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});