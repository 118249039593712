/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Worker, WorkerInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { WorkerActionTypes } from '../actions';

export interface WorkersState extends EntityState<Worker | WorkerInterface> {};

export const WorkerAdapter: EntityAdapter<Worker | WorkerInterface> = createEntityAdapter<Worker | WorkerInterface>();

const initialState: WorkersState = WorkerAdapter.getInitialState({});

const cases = BaseReducerFactory<WorkersState, Worker | WorkerInterface>(WorkerActionTypes, WorkerAdapter);

/**
 * @module WorkersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Worker reducer.
 */
export function WorkersReducer(state = initialState, action: LoopbackAction): WorkersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getWorkersState = (state: any) => state.Workers;
export const getWorkersEntities = (state: any) => state.Workers.entities;
export const getWorkersIds = (state: any) => state.Workers.ids;

export const getWorkers =
  createSelector(getWorkersEntities, getWorkersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getWorkerById(id: string) {
  return (state: any) => state.Workers.entities[id];
}

export function getWorkersById(ids: string[]) {
  return createSelector(getWorkersEntities, (entities) => ids.map((id) => entities[id]));
}
