import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-editor-base',
  templateUrl: './editor-base.component.html',
  styleUrls: ['./editor-base.component.scss']
})
export class EditorBaseComponent implements OnInit {

  @Input() conf; // is UI & options
  @Input() data; // is where we store values

  @Output() onSet = new EventEmitter(); // when value is saved (save button clicked, item in dropdown choosen and so on)

  constructor() { }

  ngOnInit() {
  }

  setParam(param, value) {
    console.log(`${param} -> ${value}`);
    this.onSet.emit({param: param, value: value});
  }
}
