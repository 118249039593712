/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, CustomMidsole } from '../models';

export const CustomMidsoleActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('CustomMidsole'), {
  GET_MASTER: type('[CustomMidsole] getMaster'),
  GET_MASTER_SUCCESS: type('[CustomMidsole] getMaster success'),
  GET_MASTER_FAIL: type('[CustomMidsole] getMaster fail'),

});
export const CustomMidsoleActions =
Object.assign(BaseLoopbackActionsFactory<CustomMidsole>(CustomMidsoleActionTypes), {

  /**
   * getMaster Action.
   * Fetches belongsTo relation master.
   *
   * @param {any} id CustomMidsole id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMaster: class implements Action {
    public readonly type = CustomMidsoleActionTypes.GET_MASTER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMasterSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMasterSuccess: class implements Action {
    public readonly type = CustomMidsoleActionTypes.GET_MASTER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMasterFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMasterFail: class implements Action {
    public readonly type = CustomMidsoleActionTypes.GET_MASTER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});