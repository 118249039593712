/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementData, MeasurementDataInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementDataActionTypes } from '../actions';

export interface MeasurementDatasState extends EntityState<MeasurementData | MeasurementDataInterface> {};

export const MeasurementDataAdapter: EntityAdapter<MeasurementData | MeasurementDataInterface> = createEntityAdapter<MeasurementData | MeasurementDataInterface>();

const initialState: MeasurementDatasState = MeasurementDataAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementDatasState, MeasurementData | MeasurementDataInterface>(MeasurementDataActionTypes, MeasurementDataAdapter);

/**
 * @module MeasurementDatasReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementData reducer.
 */
export function MeasurementDatasReducer(state = initialState, action: LoopbackAction): MeasurementDatasState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementDatasState = (state: any) => state.MeasurementDatas;
export const getMeasurementDatasEntities = (state: any) => state.MeasurementDatas.entities;
export const getMeasurementDatasIds = (state: any) => state.MeasurementDatas.ids;

export const getMeasurementDatas =
  createSelector(getMeasurementDatasEntities, getMeasurementDatasIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementDataById(id: string) {
  return (state: any) => state.MeasurementDatas.entities[id];
}

export function getMeasurementDatasById(ids: string[]) {
  return createSelector(getMeasurementDatasEntities, (entities) => ids.map((id) => entities[id]));
}
