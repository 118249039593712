/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Environment, EnvironmentInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { EnvironmentActionTypes } from '../actions';

export interface EnvironmentsState extends EntityState<Environment | EnvironmentInterface> {};

export const EnvironmentAdapter: EntityAdapter<Environment | EnvironmentInterface> = createEntityAdapter<Environment | EnvironmentInterface>();

const initialState: EnvironmentsState = EnvironmentAdapter.getInitialState({});

const cases = BaseReducerFactory<EnvironmentsState, Environment | EnvironmentInterface>(EnvironmentActionTypes, EnvironmentAdapter);

/**
 * @module EnvironmentsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Environment reducer.
 */
export function EnvironmentsReducer(state = initialState, action: LoopbackAction): EnvironmentsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getEnvironmentsState = (state: any) => state.Environments;
export const getEnvironmentsEntities = (state: any) => state.Environments.entities;
export const getEnvironmentsIds = (state: any) => state.Environments.ids;

export const getEnvironments =
  createSelector(getEnvironmentsEntities, getEnvironmentsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getEnvironmentById(id: string) {
  return (state: any) => state.Environments.entities[id];
}

export function getEnvironmentsById(ids: string[]) {
  return createSelector(getEnvironmentsEntities, (entities) => ids.map((id) => entities[id]));
}
