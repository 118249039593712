/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementDataNorm } from '../models';

export const MeasurementDataNormActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementDataNorm'), {
  GET_MEASUREMENTPARAMETER: type('[MeasurementDataNorm] getMeasurementParameter'),
  GET_MEASUREMENTPARAMETER_SUCCESS: type('[MeasurementDataNorm] getMeasurementParameter success'),
  GET_MEASUREMENTPARAMETER_FAIL: type('[MeasurementDataNorm] getMeasurementParameter fail'),

  GET_SENSOR: type('[MeasurementDataNorm] getSensor'),
  GET_SENSOR_SUCCESS: type('[MeasurementDataNorm] getSensor success'),
  GET_SENSOR_FAIL: type('[MeasurementDataNorm] getSensor fail'),

  GET_MEASUREMENTSESSION: type('[MeasurementDataNorm] getMeasurementSession'),
  GET_MEASUREMENTSESSION_SUCCESS: type('[MeasurementDataNorm] getMeasurementSession success'),
  GET_MEASUREMENTSESSION_FAIL: type('[MeasurementDataNorm] getMeasurementSession fail'),

});
export const MeasurementDataNormActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementDataNorm>(MeasurementDataNormActionTypes), {

  /**
   * getMeasurementParameter Action.
   * Fetches belongsTo relation measurementParameter.
   *
   * @param {any} id MeasurementDataNorm id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameter: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTPARAMETER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementParameterSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterSuccess: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTPARAMETER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementParameterFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterFail: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTPARAMETER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getSensor Action.
   * Fetches belongsTo relation sensor.
   *
   * @param {any} id MeasurementDataNorm id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getSensor: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_SENSOR;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getSensorSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getSensorSuccess: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_SENSOR_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getSensorFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getSensorFail: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_SENSOR_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementSession Action.
   * Fetches belongsTo relation measurementSession.
   *
   * @param {any} id MeasurementDataNorm id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSession: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTSESSION;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionSuccess: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionFail: class implements Action {
    public readonly type = MeasurementDataNormActionTypes.GET_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});