/* tslint:disable */
import {
  PrinterTask,
  PrinterStatus,
  Environment
} from '../index';

declare var Object: any;
export interface PrinterInterface {
  "title"?: string;
  "stat"?: any;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "activeTaskId"?: number;
  "statusId"?: number;
  "environmentId"?: number;
  tasks?: PrinterTask[];
  activeTask?: PrinterTask;
  status?: PrinterStatus;
  environment?: Environment;
}

export class Printer implements PrinterInterface {
  "title": string;
  "stat": any;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "activeTaskId": number;
  "statusId": number;
  "environmentId": number;
  tasks: PrinterTask[];
  activeTask: PrinterTask;
  status: PrinterStatus;
  environment: Environment;
  constructor(data?: PrinterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Printer`.
   */
  public static getModelName() {
    return "Printer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Printer for dynamic purposes.
  **/
  public static factory(data: PrinterInterface): Printer{
    return new Printer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Printer',
      plural: 'Printers',
      path: 'Printers',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "stat": {
          name: 'stat',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "activeTaskId": {
          name: 'activeTaskId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "statusId": {
          name: 'statusId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "environmentId": {
          name: 'environmentId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        tasks: {
          name: 'tasks',
          type: 'PrinterTask[]',
          model: 'PrinterTask',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'printerId'
        },
        activeTask: {
          name: 'activeTask',
          type: 'PrinterTask',
          model: 'PrinterTask',
          relationType: 'belongsTo',
                      keyFrom: 'activeTaskId',
          keyTo: 'id'
        },
        status: {
          name: 'status',
          type: 'PrinterStatus',
          model: 'PrinterStatus',
          relationType: 'belongsTo',
                      keyFrom: 'statusId',
          keyTo: 'id'
        },
        environment: {
          name: 'environment',
          type: 'Environment',
          model: 'Environment',
          relationType: 'belongsTo',
                      keyFrom: 'environmentId',
          keyTo: 'id'
        },
      }
    }
  }
}
