/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, PrinterStatus } from '../models';

export const PrinterStatusActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('PrinterStatus'), {
});
export const PrinterStatusActions =
Object.assign(BaseLoopbackActionsFactory<PrinterStatus>(PrinterStatusActionTypes), {
});