import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UiService} from "../../../services/ui.service";
import {TranslateService} from "@ngx-translate/core";
import {RadialGaugeComponent} from "pt-kendo-angular-gauges";

@Component({
    selector: 'app-content-card-lrgauges',
    templateUrl: './content-card-lrgauges.component.html',
    styleUrls: ['./content-card-lrgauges.component.scss']
})
export class ContentCardLRGaugesComponent implements OnInit, AfterViewInit {

    @Input() icon;
    @Input() title;
    @Input() hasRaw;

    @Input() isSplit;
    @Input() left;
    @Input() right;

    @Input() isCompact;

    @Input() gaugeBgPattern;
    @Input() gaugeBgSize;

    @Input() measures;

    @Input() valueMin;
    @Input() valueMax;

    isRaw: boolean = false;

    gaugeBg = '#374061';
    gaugeRange = '#60C2A6';
    gaugePointer = '#374061';

    gauge = {
        rangeSize: 5,
        startAngle: 0,
        endAngle: 360
    };

    isProcessing: boolean = true;

    constructor(public ui: UiService,
                private cd: ChangeDetectorRef,
                private translate: TranslateService) {

        this.isSplit = true;
    }

    ngOnInit() {
    }

    setRaw(value) {
        this.isRaw = value;
    }

    ngAfterViewInit() {
        if (this.measures && this.measures[1] && this.measures[2] &&
            this.measures[1].valueAvg && this.measures[2].valueAvg &&
            !isNaN(Number(this.measures[1].valueAvg)) && !isNaN(Number(this.measures[1].valueMin)) && !isNaN(Number(this.measures[1].valueMax)) &&
            !isNaN(Number(this.measures[2].valueAvg)) && !isNaN(Number(this.measures[2].valueMin)) && !isNaN(Number(this.measures[2].valueMax))
        ) {
            this.isProcessing = false;
            this.cd.detectChanges();
        }
    }

    onGaugeClick($event: MouseEvent) {
        //console.log($event);
    }
}
