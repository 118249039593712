import {LoopbackAuthEffects} from "./shared/lb-sdk/effects/auth";
import {AppUserEffects} from "./shared/lb-sdk/effects/AppUser";
import {AuthEffects} from "./shared/effects/auth.effects";

export const AppEffects = [
    LoopbackAuthEffects,
    AppUserEffects,
    AuthEffects
];

