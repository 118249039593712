/* tslint:disable */
import {
  AppUser,
  Gender,
  Event,
  Scanner
} from '../index';

declare var Object: any;
export interface FeetScanInterface {
  "scannedAt"?: Date;
  "externalId"?: string;
  "uuid"?: string;
  "folder"?: string;
  "name"?: string;
  "email"?: string;
  "metadata"?: any;
  "widthLeft"?: number;
  "widthRight"?: number;
  "lengthLeft"?: number;
  "lengthRight"?: number;
  "archLeft"?: number;
  "archRight"?: number;
  "id"?: number;
  "appUserId"?: number;
  "genderId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "eventId"?: number;
  "scannerId"?: number;
  appUser?: AppUser;
  gender?: Gender;
  event?: Event;
  scanner?: Scanner;
}

export class FeetScan implements FeetScanInterface {
  "scannedAt": Date;
  "externalId": string;
  "uuid": string;
  "folder": string;
  "name": string;
  "email": string;
  "metadata": any;
  "widthLeft": number;
  "widthRight": number;
  "lengthLeft": number;
  "lengthRight": number;
  "archLeft": number;
  "archRight": number;
  "id": number;
  "appUserId": number;
  "genderId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "eventId": number;
  "scannerId": number;
  appUser: AppUser;
  gender: Gender;
  event: Event;
  scanner: Scanner;
  constructor(data?: FeetScanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeetScan`.
   */
  public static getModelName() {
    return "FeetScan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeetScan for dynamic purposes.
  **/
  public static factory(data: FeetScanInterface): FeetScan{
    return new FeetScan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeetScan',
      plural: 'FeetScans',
      path: 'FeetScans',
      idName: 'id',
      properties: {
        "scannedAt": {
          name: 'scannedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "externalId": {
          name: 'externalId',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "uuid": {
          name: 'uuid',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "folder": {
          name: 'folder',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "email": {
          name: 'email',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "metadata": {
          name: 'metadata',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "widthLeft": {
          name: 'widthLeft',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "widthRight": {
          name: 'widthRight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "lengthLeft": {
          name: 'lengthLeft',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "lengthRight": {
          name: 'lengthRight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "archLeft": {
          name: 'archLeft',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "archRight": {
          name: 'archRight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "genderId": {
          name: 'genderId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "scannerId": {
          name: 'scannerId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                      keyFrom: 'appUserId',
          keyTo: 'id'
        },
        gender: {
          name: 'gender',
          type: 'Gender',
          model: 'Gender',
          relationType: 'belongsTo',
                      keyFrom: 'genderId',
          keyTo: 'id'
        },
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
        scanner: {
          name: 'scanner',
          type: 'Scanner',
          model: 'Scanner',
          relationType: 'belongsTo',
                      keyFrom: 'scannerId',
          keyTo: 'id'
        },
      }
    }
  }
}
