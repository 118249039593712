import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-bar-meter',
    templateUrl: './bar-meter.component.html',
    styleUrls: ['./bar-meter.component.scss']
})
export class BarMeterComponent implements OnInit {

    @Input() max: number;
    @Input() level: number;
    @Input() mirror: boolean;

    bars: any[];

    constructor() {
    }

    ngOnInit() {

        this.bars = [];
        for(let i=0; i < this.max; i++) {
            this.bars.push({
               idx: i,
               class: i < this.level ? 'bar-empty ' + (this.level > 1 ? (this.level > 2 ? 'bar-filled-danger' : 'bar-filled-warning') :'bar-filled') : 'bar-empty'
            });
        }
    }
}
