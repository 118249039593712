import {Injectable} from "@angular/core";
import {FeatureDataApi, FeatureDataSeriesApi, FeetScanApi, MeasurementSessionApi} from "../lb-sdk/services/custom";
import {FeetScan, MeasurementSession} from "../lb-sdk/models";
import {groupBy} from "@progress/kendo-data-query";
import * as _ from "lodash";
import {MeasurementCode} from "../enums/measurement.code";
import {FeatureCode} from "../enums/feature.code";

@Injectable()
export class MeasurementService {

    constructor(private sessionApi: MeasurementSessionApi,
                private scanApi: FeetScanApi,
                private featureDataSeriesApi: FeatureDataSeriesApi,
                private featureDataApi: FeatureDataApi) {
    }

    public getSession(sessionId: number, callback: Function) {

        this.sessionApi.findById(sessionId, {
            //include: ['appUser', 'measurementData'],
            include: ['appUser', 'measurementDataNorm'],
            order: ['time ASC']/*, skip: 0, offset: 0, limit: 100*/
        }).subscribe((_session: MeasurementSession) => {
            callback(null, _session);
        });
    }

    public getSessionSteps(_sessionId: number, callback: Function) {

        this.sessionApi.steps(_sessionId)
            .subscribe((_steps: any) => {
                    callback(null, _steps);
                },
                (err: Error) => {
                    callback(err, null);
                },
                () => {
                })
        ;
    }

    public getLastUserSession(userId: number, callback: Function) {
        this.sessionApi.find({
            where: {
                appUserId: userId,
                //id: 1276 // debug, owner 650, temporary to 102
            },
            include: ['measurementDataNorm'],
            limit: 1
        }).subscribe(
            (sessions: any[]) => {
                if (sessions && sessions.length > 0)
                    return callback(null, sessions[0]);

                return callback("Session not found", null);
            },
            (err: Error) => callback(err)
        );
    }

    public getDistributionData(sessionId: number, callback: Function) {
        this.featureDataSeriesApi.find({
            where: {
                measurementSessionId: sessionId,
                featureId: FeatureCode.LandingVector
            },
            //include: ['sensorId', 'x', 'y']
        }).subscribe((data: any) => {
            const xOffset = 40;

            let dataMapped = data.map(d => {
                return {
                    x: d.x * 20 + (d.sensorId == 2 ? xOffset : -xOffset),
                    y: d.y,
                    sensorId: d.sensorId
                }
            });

            let dataBySides = _.groupBy(dataMapped, 'sensorId');

            return callback(null, [
                {
                    label: 'L',
                    points: dataBySides[1]
                },
                {
                    label: 'R',
                    points: dataBySides[2]
                }
            ]);
        })
    }

    public prepareMeasures(features) {
        let measures = {};

        let byFeature = _.groupBy(features, 'featureId');

        if (byFeature[3]) {
            let percLeft = Math.floor((+byFeature[3][0].value - 0.2) / 1.6 * 100);
            measures['distribution'] = {
                valueMin: +byFeature[3][0].value - 0.1,
                valueMax: +byFeature[3][0].value + 0.1,
                percentLeft: 100 - percLeft,
                percentRight: percLeft,
                value: +byFeature[3][0].value
            };
        }

        let byParameter = _.groupBy(byFeature[4], 'measurementParameterId');

        measures['pitch'] = _.keyBy(byParameter[MeasurementCode.Pitch], 'sensorId');
        measures['roll'] = _.keyBy(byParameter[MeasurementCode.Roll], 'sensorId');
        measures['yaw'] = _.keyBy(byParameter[MeasurementCode.Yaw], 'sensorId');

        if (!measures['pitch'][1]) measures['pitch'] = null;
        if (!measures['roll'][1]) measures['roll'] = null;
        if (!measures['yaw'][1]) measures['yaw'] = null;

        return measures;
    }

    public getMeasures(sessionId: number, callback: Function) {
        this.featureDataApi.find({
            where: {
                measurementSessionId: sessionId
            }
        }).subscribe((features: any) => {
            return callback(null, this.prepareMeasures(features));
        });
    }

    public getScanForUser(userId: number, callback: Function) {
        this.scanApi.find({where: {appUserId: userId}}).subscribe(
            (scans: FeetScan[]) => {
                let scan = scans[0];

                if (!scans || scans.length < 1)
                    return callback("No scan found", null);

                callback(null, scan);
            },
            (err: Error) => callback(err)
        );
    }

}
