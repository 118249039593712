import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {Router, ActivatedRoute} from '@angular/router';
import {AppUserApi, MeasurementSessionApi} from '../lb-sdk/services/custom';
import {LoopBackAuth} from '../lb-sdk/services/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {getLoopbackAuthAccount} from '../lb-sdk/reducers';
import {ConfigService} from '../services/config.service';
import {customAnimations} from '../animations/custom-animations';
import {UiService} from '../services/ui.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: customAnimations
})

export class SidebarComponent implements OnInit, AfterViewInit {

    @ViewChild('toggleIcon', {static: false}) toggleIcon: ElementRef;
    public menuItems: any[];
    depth: number;
    activeTitle: string;
    activeTitles: string[] = [];
    expanded: boolean;
    nav_collapsed_open = false;
    logoUrl = 'assets/img/logo.png';
    public config: any = {};

    userInfo: any = {};
    hasSession: boolean;

    isAdmin = false;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private sessionApi: MeasurementSessionApi,
        private user: AppUserApi,
        private store: Store<any>,
        private configService: ConfigService,
        public ui: UiService,
        private auth: LoopBackAuth) {

        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }

        this.store.select(getLoopbackAuthAccount).subscribe((data: any) => {
            this.userInfo = data;

            if (this.userInfo) {
                // this.userInfo.name = data.name ? data.name : data.email;

                //this.userInfo.name = data.email;
                this.userInfo.initials = data.email.substring(0, 2).toUpperCase();

                // this.userInfo.badge = '123456787';

                const roles = this.auth.getCurrentUserRoles();

                // Role for display in UI (replaced with badge number for customer)
                if (roles.indexOf('admin') !== -1) {
                    //this.userInfo.isVisible = true;
                    this.isAdmin = true;
                    this.userInfo.role = 'Administrator';
                } else if (roles.indexOf('manager') !== -1) {
                    this.userInfo.role = 'Manager';
                } else {
                    // this.userInfo.role = 'Customer';
                    this.userInfo.role = '';
                }

                if(this.userInfo.id == 102) {
                    this.ui.isDemoAccount = true;
                }

                sessionApi.count({appUserId: this.userInfo.id}).subscribe((info: any) => {
                    this.hasSession = info.count > 0;

                    this.setupMenu();
                });
            }
        });
    }

    setupMenu() {
        this.menuItems = ROUTES.filter((menuItem) => {

            // Filter item
            if (menuItem.roles) {
                if (this.auth.getCurrentUserRoles().filter(role => menuItem.roles.indexOf(role) !== -1).length == 0) {
                    return null;
                }
            }

            // Filter 1st submenu
            const subItems = menuItem.submenu;

            if (subItems) {
                menuItem.submenu = subItems.filter((subItem) => {
                    if (subItem.roles) {
                        if (this.auth.getCurrentUserRoles().filter(role => subItem.roles.indexOf(role) !== -1).length == 0) {
                            return null;
                        }
                    }

                    return subItem;
                });
            }

            // TODO filter all sublevels

            // Filter by sessions
            if (menuItem.hasSession && !this.hasSession) {
                return null;
            }

            return menuItem;
        });
    }

    ngOnInit() {
        this.config = this.configService.templateConf;

        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/logo-dark.png';
        } else {
            this.logoUrl = 'assets/img/logo.png';
        }
    }

    ngAfterViewInit() {

        setTimeout(() => {
            if (this.config.layout.sidebar.collapsed !== undefined) {
                if (this.config.layout.sidebar.collapsed === true) {
                    this.expanded = false;
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = true;
                } else if (this.config.layout.sidebar.collapsed === false) {
                    this.expanded = true;
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = false;
                }
            }
        }, 0);


    }

    toggleSlideInOut() {
        this.expanded = !this.expanded;
    }

    handleToggle(titles) {
        this.activeTitles = titles;
    }

    // NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1) {
            this.router.navigate(['forms/ngx/wizard'], {skipLocationChange: false});
        }
    }
}
