/* tslint:disable */
import {Action} from '@ngrx/store';
import {type} from "../lb-sdk/util";

export const AppAuthActionTypes = {
    ROLE_GUARD_FAIL: type('[AppAuth] Role Guard fail'),
};

export const AppAuthActions = {
    roleGuardFail: class implements Action {
        public readonly type = AppAuthActionTypes.ROLE_GUARD_FAIL;
    },
};
