import { NgModule } from '@angular/core';
import { ResizeSensorModule } from '@progress/kendo-angular-resize-sensor';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { ThemeService } from './services';
/**
 * @hidden
 */
export class SharedModule {
}
SharedModule.decorators = [
    { type: NgModule, args: [{
                exports: [ResizeSensorModule],
                providers: [
                    { provide: IntlService, useClass: CldrIntlService },
                    ThemeService
                ]
            },] },
];
/** @nocollapse */
SharedModule.ctorParameters = () => [];
