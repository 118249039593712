import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullScreenComponent} from './layouts/full-screen/full-screen.component';

import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';
import {FullScreen_Routes} from './shared/routes/fullscreen-layout.routes';
import {SalespersonGuard} from './shared/guards/salesperson.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {path: '', component: FullLayoutComponent, data: {title: 'Content with sidebar/topbar etc.'}, children: Full_ROUTES},
    {path: '', component: ContentLayoutComponent, data: {title: 'Just content'}, children: CONTENT_ROUTES},
    {path: '', component: FullScreenComponent, data: {title: 'Full screen content'}, children: FullScreen_Routes},
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
