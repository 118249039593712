/* tslint:disable */

declare var Object: any;
export interface LocationInterface {
  "managers"?: any;
  "events"?: any;
  "title"?: string;
  "image"?: string;
  "venue"?: string;
  "address"?: string;
  "contacts"?: string;
  "workingHours"?: string;
  "mapLink"?: string;
  "lat"?: string;
  "lon"?: string;
  "id"?: number;
  "appUserId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Location implements LocationInterface {
  "managers": any;
  "events": any;
  "title": string;
  "image": string;
  "venue": string;
  "address": string;
  "contacts": string;
  "workingHours": string;
  "mapLink": string;
  "lat": string;
  "lon": string;
  "id": number;
  "appUserId": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: LocationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Location`.
   */
  public static getModelName() {
    return "Location";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Location for dynamic purposes.
  **/
  public static factory(data: LocationInterface): Location{
    return new Location(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Location',
      plural: 'Locations',
      path: 'Locations',
      idName: 'id',
      properties: {
        "managers": {
          name: 'managers',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "events": {
          name: 'events',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "image": {
          name: 'image',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "venue": {
          name: 'venue',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "address": {
          name: 'address',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "contacts": {
          name: 'contacts',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "workingHours": {
          name: 'workingHours',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "mapLink": {
          name: 'mapLink',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "lat": {
          name: 'lat',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "lon": {
          name: 'lon',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
