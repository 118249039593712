/* tslint:disable */
import {
  Event
} from '../index';

declare var Object: any;
export interface EnvironmentInterface {
  "title"?: string;
  "config"?: any;
  "id"?: number;
  "eventId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  event?: Event;
}

export class Environment implements EnvironmentInterface {
  "title": string;
  "config": any;
  "id": number;
  "eventId": number;
  "createdAt": Date;
  "updatedAt": Date;
  event: Event;
  constructor(data?: EnvironmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Environment`.
   */
  public static getModelName() {
    return "Environment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Environment for dynamic purposes.
  **/
  public static factory(data: EnvironmentInterface): Environment{
    return new Environment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Environment',
      plural: 'Environments',
      path: 'Environments',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "config": {
          name: 'config',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
      }
    }
  }
}
