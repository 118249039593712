/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { PrinterTask, PrinterTaskInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { PrinterTaskActionTypes } from '../actions';

export interface PrinterTasksState extends EntityState<PrinterTask | PrinterTaskInterface> {};

export const PrinterTaskAdapter: EntityAdapter<PrinterTask | PrinterTaskInterface> = createEntityAdapter<PrinterTask | PrinterTaskInterface>();

const initialState: PrinterTasksState = PrinterTaskAdapter.getInitialState({});

const cases = BaseReducerFactory<PrinterTasksState, PrinterTask | PrinterTaskInterface>(PrinterTaskActionTypes, PrinterTaskAdapter);

/**
 * @module PrinterTasksReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible PrinterTask reducer.
 */
export function PrinterTasksReducer(state = initialState, action: LoopbackAction): PrinterTasksState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getPrinterTasksState = (state: any) => state.PrinterTasks;
export const getPrinterTasksEntities = (state: any) => state.PrinterTasks.entities;
export const getPrinterTasksIds = (state: any) => state.PrinterTasks.ids;

export const getPrinterTasks =
  createSelector(getPrinterTasksEntities, getPrinterTasksIds, (entities, ids) => ids.map((id) => entities[id]));

export function getPrinterTaskById(id: string) {
  return (state: any) => state.PrinterTasks.entities[id];
}

export function getPrinterTasksById(ids: string[]) {
  return createSelector(getPrinterTasksEntities, (entities) => ids.map((id) => entities[id]));
}
