import { Component, ContentChildren } from '@angular/core';
import { CollectionComponent } from '../base-components';
import { ColorComponent } from './color.component';
import { CollectionChangesService, ConfigurationService } from '../services';
/**
 * A collection of one or more ArcGauge colors
 * ([more information and example]({% slug colorranges_arcgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-arcgauge [value]="value">
 *              <kendo-arcgauge-colors>
 *                  <kendo-arcgauge-color *ngFor="let item of colors"
 *                      [from]="item.from" [to]="item.to" [color]="item.color">
 *                  </kendo-arcgauge-color>
 *              </kendo-arcgauge-colors>
 *         </kendo-arcgauge>
 *     `
 * })
 * export class AppComponent {
 *     public value: number = 10;
 *
 *     public colors: any[] = [{
 *         to: 25,
 *         color: '#0058e9'
 *     }, {
 *         from: 25,
 *         to: 50,
 *         color: '#37b400'
 *     }, {
 *         from: 50,
 *         to: 75,
 *         color: '#ffc000'
 *     }, {
 *         from: 75,
 *         color: '#f31700'
 *     }];
 * }
 *
 * ```
 */
export class ColorsComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('colors', configurationService, collectionChangesService);
    }
}
ColorsComponent.decorators = [
    { type: Component, args: [{
                providers: [CollectionChangesService],
                selector: 'kendo-arcgauge-colors',
                template: ''
            },] },
];
/** @nocollapse */
ColorsComponent.ctorParameters = () => [
    { type: ConfigurationService, },
    { type: CollectionChangesService, },
];
ColorsComponent.propDecorators = {
    'children': [{ type: ContentChildren, args: [ColorComponent,] },],
};
