/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementParameter } from '../models';

export const MeasurementParameterActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementParameter'), {
});
export const MeasurementParameterActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementParameter>(MeasurementParameterActionTypes), {
});