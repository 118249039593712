/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Location } from '../models';

export const LocationActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Location'), {
});
export const LocationActions =
Object.assign(BaseLoopbackActionsFactory<Location>(LocationActionTypes), {
});