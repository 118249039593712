/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { AttachmentActionTypes, AttachmentActions } from '../actions/Attachment';
import { LoopbackErrorActions } from '../actions/error';
import { AttachmentApi } from '../services/index';

@Injectable()
export class AttachmentEffects extends BaseLoopbackEffects {
  @Effect()
  public getContainers$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.GET_CONTAINERS),
      mergeMap((action: LoopbackAction) =>
        this.attachment.getContainers().pipe(
          map((response: any) => new AttachmentActions.getContainersSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.getContainersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createContainer$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.CREATE_CONTAINER),
      mergeMap((action: LoopbackAction) =>
        this.attachment.createContainer(action.payload.options).pipe(
          map((response: any) => new AttachmentActions.createContainerSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.createContainerFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyContainer$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.DESTROY_CONTAINER),
      mergeMap((action: LoopbackAction) =>
        this.attachment.destroyContainer(action.payload.container).pipe(
          map((response: any) => new AttachmentActions.destroyContainerSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.destroyContainerFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getContainer$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.GET_CONTAINER),
      mergeMap((action: LoopbackAction) =>
        this.attachment.getContainer(action.payload.container).pipe(
          map((response: any) => new AttachmentActions.getContainerSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.getContainerFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getFiles$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.GET_FILES),
      mergeMap((action: LoopbackAction) =>
        this.attachment.getFiles(action.payload.container).pipe(
          map((response: any) => new AttachmentActions.getFilesSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.getFilesFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getFile$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.GET_FILE),
      mergeMap((action: LoopbackAction) =>
        this.attachment.getFile(action.payload.container, action.payload.file).pipe(
          map((response: any) => new AttachmentActions.getFileSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.getFileFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public removeFile$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.REMOVE_FILE),
      mergeMap((action: LoopbackAction) =>
        this.attachment.removeFile(action.payload.container, action.payload.file).pipe(
          map((response: any) => new AttachmentActions.removeFileSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.removeFileFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public upload$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.UPLOAD),
      mergeMap((action: LoopbackAction) =>
        this.attachment.upload(action.payload.container, action.payload.req, action.payload.res).pipe(
          map((response: any) => new AttachmentActions.uploadSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.uploadFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public download$ = this.actions$
    .pipe(
      ofType(AttachmentActionTypes.DOWNLOAD),
      mergeMap((action: LoopbackAction) =>
        this.attachment.download(action.payload.container, action.payload.file, action.payload.req, action.payload.res).pipe(
          map((response: any) => new AttachmentActions.downloadSuccess(action.payload.id, action.payload.fk, action.meta)),
          catchError((error: any) => concat(
            of(new AttachmentActions.downloadFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(AttachmentApi) public attachment: AttachmentApi
  ) {
    super(actions$, attachment, 'Attachment', AttachmentActionTypes, AttachmentActions);
  }
}
