import { Input } from '@angular/core';
import { CollectionItemComponent } from './collection-item.component';
/**
 * @hidden
 */
export class RangeComponent extends CollectionItemComponent {
}
RangeComponent.propDecorators = {
    'from': [{ type: Input },],
    'to': [{ type: Input },],
    'opacity': [{ type: Input },],
    'color': [{ type: Input },],
};
