/* tslint:disable */
import {
  Feature,
  Sensor,
  MeasurementParameter,
  MeasurementSession
} from '../index';

declare var Object: any;
export interface FeatureDataSeriesInterface {
  "time"?: number;
  "value"?: number;
  "x"?: number;
  "y"?: number;
  "id"?: number;
  "featureId"?: number;
  "sensorId"?: number;
  "measurementParameterId"?: number;
  "measurementSessionId"?: number;
  feature?: Feature;
  sensor?: Sensor;
  measurementParameter?: MeasurementParameter;
  measurementSession?: MeasurementSession;
}

export class FeatureDataSeries implements FeatureDataSeriesInterface {
  "time": number;
  "value": number;
  "x": number;
  "y": number;
  "id": number;
  "featureId": number;
  "sensorId": number;
  "measurementParameterId": number;
  "measurementSessionId": number;
  feature: Feature;
  sensor: Sensor;
  measurementParameter: MeasurementParameter;
  measurementSession: MeasurementSession;
  constructor(data?: FeatureDataSeriesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeatureDataSeries`.
   */
  public static getModelName() {
    return "FeatureDataSeries";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeatureDataSeries for dynamic purposes.
  **/
  public static factory(data: FeatureDataSeriesInterface): FeatureDataSeries{
    return new FeatureDataSeries(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeatureDataSeries',
      plural: 'FeatureDataSeries',
      path: 'FeatureDataSeries',
      idName: 'id',
      properties: {
        "time": {
          name: 'time',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "value": {
          name: 'value',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "x": {
          name: 'x',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "y": {
          name: 'y',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "featureId": {
          name: 'featureId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sensorId": {
          name: 'sensorId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementParameterId": {
          name: 'measurementParameterId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "measurementSessionId": {
          name: 'measurementSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        feature: {
          name: 'feature',
          type: 'Feature',
          model: 'Feature',
          relationType: 'belongsTo',
                      keyFrom: 'featureId',
          keyTo: 'id'
        },
        sensor: {
          name: 'sensor',
          type: 'Sensor',
          model: 'Sensor',
          relationType: 'belongsTo',
                      keyFrom: 'sensorId',
          keyTo: 'id'
        },
        measurementParameter: {
          name: 'measurementParameter',
          type: 'MeasurementParameter',
          model: 'MeasurementParameter',
          relationType: 'belongsTo',
                      keyFrom: 'measurementParameterId',
          keyTo: 'id'
        },
        measurementSession: {
          name: 'measurementSession',
          type: 'MeasurementSession',
          model: 'MeasurementSession',
          relationType: 'belongsTo',
                      keyFrom: 'measurementSessionId',
          keyTo: 'id'
        },
      }
    }
  }
}
