/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Printer } from '../models';

export const PrinterActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Printer'), {
  FIND_BY_ID_TASKS: type('[Printer] findByIdTasks'),
  FIND_BY_ID_TASKS_SUCCESS: type('[Printer] findByIdTasks success'),
  FIND_BY_ID_TASKS_FAIL: type('[Printer] findByIdTasks fail'),

  DESTROY_BY_ID_TASKS: type('[Printer] destroyByIdTasks'),
  DESTROY_BY_ID_TASKS_SUCCESS: type('[Printer] destroyByIdTasks success'),
  DESTROY_BY_ID_TASKS_FAIL: type('[Printer] destroyByIdTasks fail'),

  UPDATE_BY_ID_TASKS: type('[Printer] updateByIdTasks'),
  UPDATE_BY_ID_TASKS_SUCCESS: type('[Printer] updateByIdTasks success'),
  UPDATE_BY_ID_TASKS_FAIL: type('[Printer] updateByIdTasks fail'),

  GET_ACTIVETASK: type('[Printer] getActiveTask'),
  GET_ACTIVETASK_SUCCESS: type('[Printer] getActiveTask success'),
  GET_ACTIVETASK_FAIL: type('[Printer] getActiveTask fail'),

  GET_STATUS: type('[Printer] getStatus'),
  GET_STATUS_SUCCESS: type('[Printer] getStatus success'),
  GET_STATUS_FAIL: type('[Printer] getStatus fail'),

  GET_ENVIRONMENT: type('[Printer] getEnvironment'),
  GET_ENVIRONMENT_SUCCESS: type('[Printer] getEnvironment success'),
  GET_ENVIRONMENT_FAIL: type('[Printer] getEnvironment fail'),

  GET_TASKS: type('[Printer] getTasks'),
  GET_TASKS_SUCCESS: type('[Printer] getTasks success'),
  GET_TASKS_FAIL: type('[Printer] getTasks fail'),

  CREATE_TASKS: type('[Printer] createTasks'),
  CREATE_TASKS_SUCCESS: type('[Printer] createTasks success'),
  CREATE_TASKS_FAIL: type('[Printer] createTasks fail'),

  DELETE_TASKS: type('[Printer] deleteTasks'),
  DELETE_TASKS_SUCCESS: type('[Printer] deleteTasks success'),
  DELETE_TASKS_FAIL: type('[Printer] deleteTasks fail'),

  CREATE_MANY_TASKS: type('[Printer] createManyTasks'),
  CREATE_MANY_TASKS_SUCCESS: type('[Printer] createManyTasks success'),
  CREATE_MANY_TASKS_FAIL: type('[Printer] createManyTasks fail'),

});
export const PrinterActions =
Object.assign(BaseLoopbackActionsFactory<Printer>(PrinterActionTypes), {

  /**
   * findByIdTasks Action.
   * Find a related item by id for tasks.
   *
   * @param {any} id Printer id
   * @param {any} fk Foreign key for tasks
   * @param {any} meta (optional).
   * 
   */
  findByIdTasks: class implements Action {
    public readonly type = PrinterActionTypes.FIND_BY_ID_TASKS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdTasksSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.FIND_BY_ID_TASKS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.FIND_BY_ID_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdTasks Action.
   * Delete a related item by id for tasks.
   *
   * @param {any} id Printer id
   * @param {any} fk Foreign key for tasks
   * @param {any} meta (optional).
   * 
   */
  destroyByIdTasks: class implements Action {
    public readonly type = PrinterActionTypes.DESTROY_BY_ID_TASKS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdTasksSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.DESTROY_BY_ID_TASKS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.DESTROY_BY_ID_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdTasks Action.
   * Update a related item by id for tasks.
   *
   * @param {any} id Printer id
   * @param {any} fk Foreign key for tasks
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdTasks: class implements Action {
    public readonly type = PrinterActionTypes.UPDATE_BY_ID_TASKS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdTasksSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.UPDATE_BY_ID_TASKS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.UPDATE_BY_ID_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getActiveTask Action.
   * Fetches belongsTo relation activeTask.
   *
   * @param {any} id Printer id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getActiveTask: class implements Action {
    public readonly type = PrinterActionTypes.GET_ACTIVETASK;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getActiveTaskSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getActiveTaskSuccess: class implements Action {
    public readonly type = PrinterActionTypes.GET_ACTIVETASK_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getActiveTaskFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getActiveTaskFail: class implements Action {
    public readonly type = PrinterActionTypes.GET_ACTIVETASK_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getStatus Action.
   * Fetches belongsTo relation status.
   *
   * @param {any} id Printer id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getStatus: class implements Action {
    public readonly type = PrinterActionTypes.GET_STATUS;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getStatusSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getStatusSuccess: class implements Action {
    public readonly type = PrinterActionTypes.GET_STATUS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getStatusFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getStatusFail: class implements Action {
    public readonly type = PrinterActionTypes.GET_STATUS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getEnvironment Action.
   * Fetches belongsTo relation environment.
   *
   * @param {any} id Printer id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getEnvironment: class implements Action {
    public readonly type = PrinterActionTypes.GET_ENVIRONMENT;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getEnvironmentSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getEnvironmentSuccess: class implements Action {
    public readonly type = PrinterActionTypes.GET_ENVIRONMENT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getEnvironmentFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getEnvironmentFail: class implements Action {
    public readonly type = PrinterActionTypes.GET_ENVIRONMENT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getTasks Action.
   * Queries tasks of Printer.
   *
   * @param {any} id Printer id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getTasks: class implements Action {
    public readonly type = PrinterActionTypes.GET_TASKS;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getTasksSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.GET_TASKS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.GET_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createTasks Action.
   * Creates a new instance in tasks of this model.
   *
   * @param {any} id Printer id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createTasks: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_TASKS;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createTasksSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_TASKS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteTasks Action.
   * Deletes all tasks of this model.
   *
   * @param {any} id Printer id
   * @param {any} meta (optional).
   * 
   */
  deleteTasks: class implements Action {
    public readonly type = PrinterActionTypes.DELETE_TASKS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteTasksSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.DELETE_TASKS_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.DELETE_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyTasks Action.
   * Creates a new instance in tasks of this model.
   *
   * @param {any} id Printer id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyTasks: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_MANY_TASKS;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyTasksSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyTasksSuccess: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_MANY_TASKS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyTasksFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyTasksFail: class implements Action {
    public readonly type = PrinterActionTypes.CREATE_MANY_TASKS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});