import {Component, Input, OnInit} from '@angular/core';
import {UiService} from "../../../services/ui.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-content-card-lr',
    templateUrl: './content-card-lr.component.html',
    styleUrls: ['./content-card-lr.component.scss']
})
export class ContentCardLrComponent implements OnInit {

    @Input() icon;
    @Input() title;
    @Input() hasRaw;
    @Input() isCompact;
    @Input() isSplit;
    @Input() left;
    @Input() right;

    isRaw: boolean = false;

    constructor(public ui: UiService,
                private translate: TranslateService) {
    }

    ngOnInit() {
    }

    setRaw(value) {
        this.isRaw = value;
    }
}
