import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserActions} from '../lb-sdk/actions';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '../services/ui.service';
import {LayoutService} from '../services/layout.service';
import {ConfigService} from '../services/config.service';
import {getLoopbackAuthAccount} from '../lb-sdk/reducers';
import {LoopBackAuth} from '../lb-sdk/services/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [NgbDropdownConfig]
})

export class NavbarComponent implements OnInit, AfterViewInit {

    toggleClass = 'ft-maximize';
    placement = 'bottom-right';
    public isCollapsed = true;
    @Output() toggleHideSidebar = new EventEmitter<Object>();

    public config: any = {};

    userInfo: any;

    toolsEnabled: boolean = environment.client == 'ecco';

    constructor(public translate: TranslateService,
                private layoutService: LayoutService,
                private configService: ConfigService,
                private dropdownConfig: NgbDropdownConfig,
                private router: Router,
                public ui: UiService,
                private store: Store<any>,
                public auth: LoopBackAuth,
                private route: ActivatedRoute) {

        // const browserLang: string = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');

        translate.use('en'); // TODO switch ui language
        //translate.use('ja');

        this.store.select(getLoopbackAuthAccount).subscribe((data: any) => {
            this.userInfo = data;

            if (this.userInfo) {
                //this.userInfo.name = data.name ? data.name : data.email;

                //this.userInfo.name = data.email;
                //this.userInfo.initials = data.email.substring(0, 2).toUpperCase();

                const roles = this.auth.getCurrentUserRoles();

                // Role for display in UI (replaced with badge number for customer)
                if (roles.indexOf('admin') === -1) {
                    this.userInfo.isVisible = true;
                }
            }
        });
    }

    ngOnInit() {
        this.config = this.configService.templateConf;

        //this.dropdownConfig.placement = 'top';
        //dropdownConfig.autoClose = false;
    }

    ngAfterViewInit() {
        if (this.config.layout.dir) {
            const dir = this.config.layout.dir;
            if (dir === 'rtl') {
                this.placement = 'bottom-left';
            } else if (dir === 'ltr') {
                this.placement = 'bottom-right';
            }
        }
    }

    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    onLogout() {
        this.store.dispatch(new AppUserActions.logout({}));
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize';
        }
    }

    toggleNotificationSidebar() {
        this.layoutService.emitChange(true);
    }

    toggleSidebar() {
        const appSidebar = document.getElementsByClassName('app-sidebar')[0];
        if (appSidebar.classList.contains('hide-sidebar')) {
            this.toggleHideSidebar.emit(false);
        } else {
            this.toggleHideSidebar.emit(true);
        }
    }
}
