/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, OrderStatus } from '../models';

export const OrderStatusActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('OrderStatus'), {
});
export const OrderStatusActions =
Object.assign(BaseLoopbackActionsFactory<OrderStatus>(OrderStatusActionTypes), {
});