/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { EventActionTypes, EventActions } from '../actions/Event';
import { LoopbackErrorActions } from '../actions/error';
import { EventApi } from '../services/index';

@Injectable()
export class EventEffects extends BaseLoopbackEffects {
  @Effect()
  public findByIdManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.FIND_BY_ID_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.findByIdManagers(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'AppUser', 'findByIdSuccess'),
            of(new EventActions.findByIdManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.findByIdManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DESTROY_BY_ID_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.destroyByIdManagers(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'AppUser', 'deleteByIdSuccess'),
            of(new EventActions.destroyByIdManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.destroyByIdManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.UPDATE_BY_ID_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.updateByIdManagers(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'AppUser', 'findByIdSuccess'),
            of(new EventActions.updateByIdManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.updateByIdManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getLocation$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_LOCATION),
      mergeMap((action: LoopbackAction) =>
        this.event.getLocation(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Location', 'findSuccess'),
            of(new EventActions.getLocationSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getLocationFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.FIND_BY_ID_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.findByIdProducts(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Product', 'EventProduct', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Product', 'findByIdSuccess'),
            of(new EventActions.findByIdProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.findByIdProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DESTROY_BY_ID_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.destroyByIdProducts(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Product', 'deleteByIdSuccess'),
            of(new EventActions.destroyByIdProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.destroyByIdProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.UPDATE_BY_ID_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.updateByIdProducts(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Product', 'EventProduct', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Product', 'findByIdSuccess'),
            of(new EventActions.updateByIdProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.updateByIdProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.FIND_BY_ID_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.findByIdBookings(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Booking', 'findByIdSuccess'),
            of(new EventActions.findByIdBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.findByIdBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DESTROY_BY_ID_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.destroyByIdBookings(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Booking', 'deleteByIdSuccess'),
            of(new EventActions.destroyByIdBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.destroyByIdBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.UPDATE_BY_ID_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.updateByIdBookings(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Booking', 'findByIdSuccess'),
            of(new EventActions.updateByIdBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.updateByIdBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public findByIdGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.FIND_BY_ID_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.findByIdGenders(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Gender', 'EventGender', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Gender', 'findByIdSuccess'),
            of(new EventActions.findByIdGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.findByIdGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public destroyByIdGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DESTROY_BY_ID_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.destroyByIdGenders(action.payload.id, action.payload.fk).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Gender', 'deleteByIdSuccess'),
            of(new EventActions.destroyByIdGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.destroyByIdGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public updateByIdGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.UPDATE_BY_ID_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.updateByIdGenders(action.payload.id, action.payload.fk, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Gender', 'EventGender', 'findSuccess'),
            resolver({id: action.payload.id, data: response, meta: action.meta}, 'Gender', 'findByIdSuccess'),
            of(new EventActions.updateByIdGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.updateByIdGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getStatus$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_STATUS),
      mergeMap((action: LoopbackAction) =>
        this.event.getStatus(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'EventStatus', 'findSuccess'),
            of(new EventActions.getStatusSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getStatusFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.getManagers(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'AppUser', 'findSuccess'),
            of(new EventActions.getManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.createManagers(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'AppUser', 'findSuccess'),
            of(new EventActions.createManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DELETE_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.deleteManagers(action.payload.id).pipe(
          map((response: any) => new EventActions.deleteManagersSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new EventActions.deleteManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.getProducts(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Product', 'EventProduct', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Product', 'findSuccess'),
            of(new EventActions.getProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.createProducts(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Product', 'EventProduct', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Product', 'findSuccess'),
            of(new EventActions.createProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DELETE_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.deleteProducts(action.payload.id).pipe(
          map((response: any) => new EventActions.deleteProductsSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new EventActions.deleteProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.getBookings(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Booking', 'findSuccess'),
            of(new EventActions.getBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.createBookings(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Booking', 'findSuccess'),
            of(new EventActions.createBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DELETE_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.deleteBookings(action.payload.id).pipe(
          map((response: any) => new EventActions.deleteBookingsSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new EventActions.deleteBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.GET_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.getGenders(action.payload.id, action.payload.filter).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Gender', 'EventGender', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Gender', 'findSuccess'),
            of(new EventActions.getGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.getGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.createGenders(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Gender', 'EventGender', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Gender', 'findSuccess'),
            of(new EventActions.createGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public deleteGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.DELETE_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.deleteGenders(action.payload.id).pipe(
          map((response: any) => new EventActions.deleteGendersSuccess(action.payload, action.meta)),
          catchError((error: any) => concat(
            of(new EventActions.deleteGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyManagers$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_MANY_MANAGERS),
      mergeMap((action: LoopbackAction) =>
        this.event.createManyManagers(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'AppUser', 'findSuccess'),
            of(new EventActions.createManyManagersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createManyManagersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyProducts$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_MANY_PRODUCTS),
      mergeMap((action: LoopbackAction) =>
        this.event.createManyProducts(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Product', 'EventProduct', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Product', 'findSuccess'),
            of(new EventActions.createManyProductsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createManyProductsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyBookings$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_MANY_BOOKINGS),
      mergeMap((action: LoopbackAction) =>
        this.event.createManyBookings(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Booking', 'findSuccess'),
            of(new EventActions.createManyBookingsSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createManyBookingsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public createManyGenders$ = this.actions$
    .pipe(
      ofType(EventActionTypes.CREATE_MANY_GENDERS),
      mergeMap((action: LoopbackAction) =>
        this.event.createManyGenders(action.payload.id, action.payload.data).pipe(
          mergeMap((response: any) => concat(
            resolveThrough(action, response, 'Gender', 'EventGender', 'findSuccess'),
            resolver({data: response, meta: action.meta}, 'Gender', 'findSuccess'),
            of(new EventActions.createManyGendersSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new EventActions.createManyGendersFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(EventApi) public event: EventApi
  ) {
    super(actions$, event, 'Event', EventActionTypes, EventActions);
  }
}
