import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {map, catchError, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocalApiService {

    public localEndpoint: string;

    constructor(private http: HttpClient) {
        const apiConfig = window['apiConfig'];
        this.localEndpoint = apiConfig.localEndpoint;
    }

    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    public getLocalState(): Observable<any> {
        return this.http.get(this.localEndpoint + 'States/1').pipe(
            map(this.extractData));
    }

    public updateLocalState(data): Observable<any> {
        return this.http.patch(this.localEndpoint + 'States/1', data);
    }

    public postJob(data): Observable<any> {
        return this.http.post(this.localEndpoint + 'Jobs/', data);
    }

    public shareAuthKey(key): Observable<any> {
        return this.http.post(this.localEndpoint + 'States/set-auth', {key: key});
    }
}
