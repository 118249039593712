import {Injectable} from '@angular/core';
import {UiService} from './ui.service';
import {LocalApiService} from './local-api.service';
import {AppUserApi, EventApi} from '../lb-sdk/services/custom';

@Injectable({
    providedIn: 'root'
})
export class ArchitectService {

    constructor(private ui: UiService,
                private localApi: LocalApiService,
                private eventApi: EventApi,
                private userApi: AppUserApi
    ) {
    }

    loadWorkingEvent(callback: Function) {
        this.ui.workingEventId = localStorage.getItem('workingEventId');

        if (this.ui.workingEventId) {
            if (!this.ui.workingEvent || this.ui.workingEvent.id != this.ui.workingEventId) {
                // we need to load group data

                this.eventApi.findById(this.ui.workingEventId).subscribe((_event: any) => {
                    this.ui.workingEvent = _event;

                    return callback();
                });

            } else {
                // all done - just return
                return callback();
            }

        } else {
            // there is no event set - clean up and return
            this.ui.workingEvent = null;
            return callback();
        }
    }

    loadWorkingUser(callback: Function) {
        this.ui.workingUserId = localStorage.getItem('workingUserId');

        if (this.ui.workingUserId) {
            if (!this.ui.workingUser || this.ui.workingUserId != this.ui.workingUser.id) {
                // we need to load user data

                this.ui.workingMidsole = {};
                this.ui.workingMidsoleId = null;

                this.userApi.findById(this.ui.workingUserId, {include: ['workingSession', 'workingScan', 'gender', 'midsoles']}).subscribe((_user: any) => {
                    this.ui.workingUser = _user;

                    //console.log(this.ui.workingUser);

                    if (!this.ui.localConfig.apiEnabled) {
                        return callback();
                    }

                    this.localApi.updateLocalState({
                        user: this.ui.workingUserId,
                        card: this.ui.workingUser.badge,
                        gender: this.ui.workingUser.genderId
                    }).subscribe(() => callback);
                });
            } else {
                // all done - just return
                return callback();
            }
        } else {
            // there is no user set - clean up and return
            this.ui.workingUser = null;

            if (!this.ui.localConfig.apiEnabled) {
                return callback();
            }

            this.localApi.updateLocalState({user: null, card: null, gender: null}).subscribe(() => callback);
        }

    }

    loadWorkingGroup(opts: any, callback: Function) {
        this.ui.workingGroupId = localStorage.getItem('workingGroupId');

        if (this.ui.workingGroupId) {
            if (!this.ui.workingGroup || this.ui.workingGroup.id != this.ui.workingGroupId) {
                // we need to load group data

                this.eventApi.findById(this.ui.workingGroupId).subscribe((_event: any) => {
                    this.ui.workingGroup = _event;
                    let q = Object.assign({}, {originEventId: this.ui.workingGroupId}, opts);
                    this.userApi.find({where: q, order: ['id ASC']})
                        .subscribe((_users: any[]) => {
                            this.ui.workingGroup.users = _users;
                            this.ui.workingGroup.userIdx = -1;

                            if (_users && _users.length > 0) {
                                this.ui.workingGroup.userIdx = 0;

                                // TODO check working user if it's the same group just determine it's index

                                localStorage.setItem('workingUserId', _users[this.ui.workingGroup.userIdx].id);
                            }

                            return callback();
                        });
                });
            } else {
                // all done - just return
                return callback();
            }
        } else {
            // there is no group set - clean up and return
            this.ui.workingGroup = null;
            return callback();
        }
    }

    updateUserFromCloud(cb: Function = null) {
        this.userApi.findById(this.ui.workingUserId,
            {include: ['workingSession', 'workingScan', 'gender', 'midsoles']})
            .subscribe((_user: any) => {
                if (_user.id && this.ui.workingUser) {
                    this.ui.workingUser.workingSessionId = _user.workingSessionId;
                    this.ui.workingUser.workingScanId = _user.workingScanId;
                    this.ui.workingUser.workingSession = _user.workingSession;
                    this.ui.workingUser.workingScan = _user.workingScan;

                    this.ui.workingUser.midsoles = _user.midsoles;

                    if(cb)
                        cb();
                }
            });
    }

}
