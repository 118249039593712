/* tslint:disable */
import { SDKToken } from './models/BaseModels';

import * as reducers from './reducers/index';

import { LoopbackAuthEffects } from './effects/auth';
import { ProductEffects } from './effects/Product';
import { AppUserEffects } from './effects/AppUser';
import { MeasurementSessionEffects } from './effects/MeasurementSession';
import { MeasurementDataEffects } from './effects/MeasurementData';
import { GenderEffects } from './effects/Gender';
import { MeasurementDataCompactEffects } from './effects/MeasurementDataCompact';
import { FeetScanEffects } from './effects/FeetScan';
import { AttachmentEffects } from './effects/Attachment';
import { MeasurementParameterEffects } from './effects/MeasurementParameter';
import { FeatureEffects } from './effects/Feature';
import { FeatureDataEffects } from './effects/FeatureData';
import { SensorEffects } from './effects/Sensor';
import { PrinterEffects } from './effects/Printer';
import { LocationEffects } from './effects/Location';
import { BookingEffects } from './effects/Booking';
import { EventEffects } from './effects/Event';
import { PrinterTaskEffects } from './effects/PrinterTask';
import { OrderEffects } from './effects/Order';
import { OrderStatusEffects } from './effects/OrderStatus';
import { PrintTaskStatusEffects } from './effects/PrintTaskStatus';
import { BookingStatusEffects } from './effects/BookingStatus';
import { ScannerEffects } from './effects/Scanner';
import { MeasurementDataNormEffects } from './effects/MeasurementDataNorm';
import { FeatureDataSeriesEffects } from './effects/FeatureDataSeries';
import { WorkerEffects } from './effects/Worker';
import { BookingSlotEffects } from './effects/BookingSlot';
import { MeasurementSessionIntegrityEffects } from './effects/MeasurementSessionIntegrity';
import { MeasurementSessionQualityEffects } from './effects/MeasurementSessionQuality';
import { EventStatusEffects } from './effects/EventStatus';
import { PrinterStatusEffects } from './effects/PrinterStatus';
import { LogEffects } from './effects/Log';
import { EnvironmentEffects } from './effects/Environment';
import { MidsoleEffects } from './effects/Midsole';
import { CustomMidsoleEffects } from './effects/CustomMidsole';

export interface LoopbackStateInterface {
  LoopbackAuth: SDKToken;
  Products: reducers.ProductsState;
  AppUsers: reducers.AppUsersState;
  MeasurementSessions: reducers.MeasurementSessionsState;
  MeasurementDatas: reducers.MeasurementDatasState;
  Genders: reducers.GendersState;
  MeasurementDataCompacts: reducers.MeasurementDataCompactsState;
  FeetScans: reducers.FeetScansState;
  Attachments: reducers.AttachmentsState;
  MeasurementParameters: reducers.MeasurementParametersState;
  Features: reducers.FeaturesState;
  FeatureDatas: reducers.FeatureDatasState;
  Sensors: reducers.SensorsState;
  Printers: reducers.PrintersState;
  Locations: reducers.LocationsState;
  Bookings: reducers.BookingsState;
  Events: reducers.EventsState;
  PrinterTasks: reducers.PrinterTasksState;
  Orders: reducers.OrdersState;
  OrderStatuss: reducers.OrderStatussState;
  PrintTaskStatuss: reducers.PrintTaskStatussState;
  BookingStatuss: reducers.BookingStatussState;
  Scanners: reducers.ScannersState;
  MeasurementDataNorms: reducers.MeasurementDataNormsState;
  FeatureDataSeriess: reducers.FeatureDataSeriessState;
  Workers: reducers.WorkersState;
  BookingSlots: reducers.BookingSlotsState;
  MeasurementSessionIntegritys: reducers.MeasurementSessionIntegritysState;
  MeasurementSessionQualitys: reducers.MeasurementSessionQualitysState;
  EventStatuss: reducers.EventStatussState;
  PrinterStatuss: reducers.PrinterStatussState;
  Logs: reducers.LogsState;
  Environments: reducers.EnvironmentsState;
  Midsoles: reducers.MidsolesState;
  CustomMidsoles: reducers.CustomMidsolesState;
};

export const LoopbackReducer = {
  LoopbackAuth: reducers.LoopbackAuthReducer,
	Products: reducers.ProductsReducer,
	AppUsers: reducers.AppUsersReducer,
	MeasurementSessions: reducers.MeasurementSessionsReducer,
	MeasurementDatas: reducers.MeasurementDatasReducer,
	Genders: reducers.GendersReducer,
	MeasurementDataCompacts: reducers.MeasurementDataCompactsReducer,
	FeetScans: reducers.FeetScansReducer,
	Attachments: reducers.AttachmentsReducer,
	MeasurementParameters: reducers.MeasurementParametersReducer,
	Features: reducers.FeaturesReducer,
	FeatureDatas: reducers.FeatureDatasReducer,
	Sensors: reducers.SensorsReducer,
	Printers: reducers.PrintersReducer,
	Locations: reducers.LocationsReducer,
	Bookings: reducers.BookingsReducer,
	Events: reducers.EventsReducer,
	PrinterTasks: reducers.PrinterTasksReducer,
	Orders: reducers.OrdersReducer,
	OrderStatuss: reducers.OrderStatussReducer,
	PrintTaskStatuss: reducers.PrintTaskStatussReducer,
	BookingStatuss: reducers.BookingStatussReducer,
	Scanners: reducers.ScannersReducer,
	MeasurementDataNorms: reducers.MeasurementDataNormsReducer,
	FeatureDataSeriess: reducers.FeatureDataSeriessReducer,
	Workers: reducers.WorkersReducer,
	BookingSlots: reducers.BookingSlotsReducer,
	MeasurementSessionIntegritys: reducers.MeasurementSessionIntegritysReducer,
	MeasurementSessionQualitys: reducers.MeasurementSessionQualitysReducer,
	EventStatuss: reducers.EventStatussReducer,
	PrinterStatuss: reducers.PrinterStatussReducer,
	Logs: reducers.LogsReducer,
	Environments: reducers.EnvironmentsReducer,
	Midsoles: reducers.MidsolesReducer,
	CustomMidsoles: reducers.CustomMidsolesReducer,
};

export const LoopbackEffects = [
  LoopbackAuthEffects,
  ProductEffects,
  AppUserEffects,
  MeasurementSessionEffects,
  MeasurementDataEffects,
  GenderEffects,
  MeasurementDataCompactEffects,
  FeetScanEffects,
  AttachmentEffects,
  MeasurementParameterEffects,
  FeatureEffects,
  FeatureDataEffects,
  SensorEffects,
  PrinterEffects,
  LocationEffects,
  BookingEffects,
  EventEffects,
  PrinterTaskEffects,
  OrderEffects,
  OrderStatusEffects,
  PrintTaskStatusEffects,
  BookingStatusEffects,
  ScannerEffects,
  MeasurementDataNormEffects,
  FeatureDataSeriesEffects,
  WorkerEffects,
  BookingSlotEffects,
  MeasurementSessionIntegrityEffects,
  MeasurementSessionQualityEffects,
  EventStatusEffects,
  PrinterStatusEffects,
  LogEffects,
  EnvironmentEffects,
  MidsoleEffects,
  CustomMidsoleEffects,
];
