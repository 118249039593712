/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Event, EventInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { EventActionTypes } from '../actions';

export interface EventsState extends EntityState<Event | EventInterface> {};

export const EventAdapter: EntityAdapter<Event | EventInterface> = createEntityAdapter<Event | EventInterface>();

const initialState: EventsState = EventAdapter.getInitialState({});

const cases = BaseReducerFactory<EventsState, Event | EventInterface>(EventActionTypes, EventAdapter);

/**
 * @module EventsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Event reducer.
 */
export function EventsReducer(state = initialState, action: LoopbackAction): EventsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getEventsState = (state: any) => state.Events;
export const getEventsEntities = (state: any) => state.Events.entities;
export const getEventsIds = (state: any) => state.Events.ids;

export const getEvents =
  createSelector(getEventsEntities, getEventsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getEventById(id: string) {
  return (state: any) => state.Events.entities[id];
}

export function getEventsById(ids: string[]) {
  return createSelector(getEventsEntities, (entities) => ids.map((id) => entities[id]));
}
