/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { AppUser, AppUserInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { AppUserActionTypes } from '../actions';

export interface AppUsersState extends EntityState<AppUser | AppUserInterface> {};

export const AppUserAdapter: EntityAdapter<AppUser | AppUserInterface> = createEntityAdapter<AppUser | AppUserInterface>();

const initialState: AppUsersState = AppUserAdapter.getInitialState({});

const cases = BaseReducerFactory<AppUsersState, AppUser | AppUserInterface>(AppUserActionTypes, AppUserAdapter);

/**
 * @module AppUsersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible AppUser reducer.
 */
export function AppUsersReducer(state = initialState, action: LoopbackAction): AppUsersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getAppUsersState = (state: any) => state.AppUsers;
export const getAppUsersEntities = (state: any) => state.AppUsers.entities;
export const getAppUsersIds = (state: any) => state.AppUsers.ids;

export const getAppUsers =
  createSelector(getAppUsersEntities, getAppUsersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getAppUserById(id: string) {
  return (state: any) => state.AppUsers.entities[id];
}

export function getAppUsersById(ids: string[]) {
  return createSelector(getAppUsersEntities, (entities) => ids.map((id) => entities[id]));
}
