/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Feature, FeatureInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { FeatureActionTypes } from '../actions';

export interface FeaturesState extends EntityState<Feature | FeatureInterface> {};

export const FeatureAdapter: EntityAdapter<Feature | FeatureInterface> = createEntityAdapter<Feature | FeatureInterface>();

const initialState: FeaturesState = FeatureAdapter.getInitialState({});

const cases = BaseReducerFactory<FeaturesState, Feature | FeatureInterface>(FeatureActionTypes, FeatureAdapter);

/**
 * @module FeaturesReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Feature reducer.
 */
export function FeaturesReducer(state = initialState, action: LoopbackAction): FeaturesState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getFeaturesState = (state: any) => state.Features;
export const getFeaturesEntities = (state: any) => state.Features.entities;
export const getFeaturesIds = (state: any) => state.Features.ids;

export const getFeatures =
  createSelector(getFeaturesEntities, getFeaturesIds, (entities, ids) => ids.map((id) => entities[id]));

export function getFeatureById(id: string) {
  return (state: any) => state.Features.entities[id];
}

export function getFeaturesById(ids: string[]) {
  return createSelector(getFeaturesEntities, (entities) => ids.map((id) => entities[id]));
}
