import {of} from 'rxjs/observable/of';
import {flatMap} from 'rxjs/operators'
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';

import {getLoopbackAuthToken} from "../lb-sdk/reducers";
import {AppAuthActions} from "../actions/auth";
import {LoopBackAuth} from '../lb-sdk/services/core';

@Injectable()
export class SalespersonGuard implements CanActivate {

    constructor(private store: Store<any>,
                private auth: LoopBackAuth,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

        if(this.auth.userHasRole('salesperson'))
            return this.router.parseUrl('/architect/overview');

        return true;
    }
}
