import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {GenderApi} from "../lb-sdk/services/custom";
import {map} from "rxjs/operators";

@Injectable()
export class GendersResolver implements Resolve<any> {

    constructor(private api: GenderApi) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.api.find();
    }
}
