import { Component, OnInit } from '@angular/core';

import * as THREE from 'three';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {SSAARenderPass} from 'three/examples/jsm/postprocessing/SSAARenderPass';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {CopyShader} from 'three/examples/jsm/shaders/CopyShader';
import {SSAOBlurShader, SSAODepthShader, SSAOShader} from 'three/examples/jsm/shaders/SSAOShader';
import {FXAAShader} from 'three/examples/jsm/shaders/FXAAShader';
import * as TWEEN from '@tweenjs/tween.js';

@Component({
  selector: 'app-scene3d',
  templateUrl: './scene3d.component.html',
  styleUrls: ['./scene3d.component.scss']
})
export class Scene3dComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
