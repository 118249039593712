/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, FeatureDataSeries } from '../models';

export const FeatureDataSeriesActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('FeatureDataSeries'), {
  GET_FEATURE: type('[FeatureDataSeries] getFeature'),
  GET_FEATURE_SUCCESS: type('[FeatureDataSeries] getFeature success'),
  GET_FEATURE_FAIL: type('[FeatureDataSeries] getFeature fail'),

  GET_SENSOR: type('[FeatureDataSeries] getSensor'),
  GET_SENSOR_SUCCESS: type('[FeatureDataSeries] getSensor success'),
  GET_SENSOR_FAIL: type('[FeatureDataSeries] getSensor fail'),

  GET_MEASUREMENTPARAMETER: type('[FeatureDataSeries] getMeasurementParameter'),
  GET_MEASUREMENTPARAMETER_SUCCESS: type('[FeatureDataSeries] getMeasurementParameter success'),
  GET_MEASUREMENTPARAMETER_FAIL: type('[FeatureDataSeries] getMeasurementParameter fail'),

  GET_MEASUREMENTSESSION: type('[FeatureDataSeries] getMeasurementSession'),
  GET_MEASUREMENTSESSION_SUCCESS: type('[FeatureDataSeries] getMeasurementSession success'),
  GET_MEASUREMENTSESSION_FAIL: type('[FeatureDataSeries] getMeasurementSession fail'),

});
export const FeatureDataSeriesActions =
Object.assign(BaseLoopbackActionsFactory<FeatureDataSeries>(FeatureDataSeriesActionTypes), {

  /**
   * getFeature Action.
   * Fetches belongsTo relation feature.
   *
   * @param {any} id FeatureDataSeries id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getFeature: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_FEATURE;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getFeatureSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getFeatureSuccess: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_FEATURE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getFeatureFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getFeatureFail: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_FEATURE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getSensor Action.
   * Fetches belongsTo relation sensor.
   *
   * @param {any} id FeatureDataSeries id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getSensor: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_SENSOR;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getSensorSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getSensorSuccess: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_SENSOR_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getSensorFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getSensorFail: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_SENSOR_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementParameter Action.
   * Fetches belongsTo relation measurementParameter.
   *
   * @param {any} id FeatureDataSeries id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameter: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTPARAMETER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementParameterSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterSuccess: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTPARAMETER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementParameterFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterFail: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTPARAMETER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementSession Action.
   * Fetches belongsTo relation measurementSession.
   *
   * @param {any} id FeatureDataSeries id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSession: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTSESSION;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionSuccess: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionFail: class implements Action {
    public readonly type = FeatureDataSeriesActionTypes.GET_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});