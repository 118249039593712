/* tslint:disable */
import {
  Gender,
  Printer
} from '../index';

declare var Object: any;
export interface PrinterTaskInterface {
  "code"?: string;
  "size"?: number;
  "arch"?: number;
  "vector"?: number;
  "hexagon"?: number;
  "fit"?: number;
  "remote"?: number;
  "id"?: number;
  "genderId"?: number;
  "printerId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  gender?: Gender;
  printer?: Printer;
}

export class PrinterTask implements PrinterTaskInterface {
  "code": string;
  "size": number;
  "arch": number;
  "vector": number;
  "hexagon": number;
  "fit": number;
  "remote": number;
  "id": number;
  "genderId": number;
  "printerId": number;
  "createdAt": Date;
  "updatedAt": Date;
  gender: Gender;
  printer: Printer;
  constructor(data?: PrinterTaskInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PrinterTask`.
   */
  public static getModelName() {
    return "PrinterTask";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PrinterTask for dynamic purposes.
  **/
  public static factory(data: PrinterTaskInterface): PrinterTask{
    return new PrinterTask(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PrinterTask',
      plural: 'PrinterTasks',
      path: 'PrinterTasks',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "size": {
          name: 'size',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "arch": {
          name: 'arch',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "vector": {
          name: 'vector',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "hexagon": {
          name: 'hexagon',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "fit": {
          name: 'fit',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "remote": {
          name: 'remote',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "genderId": {
          name: 'genderId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "printerId": {
          name: 'printerId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
        gender: {
          name: 'gender',
          type: 'Gender',
          model: 'Gender',
          relationType: 'belongsTo',
                      keyFrom: 'genderId',
          keyTo: 'id'
        },
        printer: {
          name: 'printer',
          type: 'Printer',
          model: 'Printer',
          relationType: 'belongsTo',
                      keyFrom: 'printerId',
          keyTo: 'id'
        },
      }
    }
  }
}
