/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { MeasurementSessionActionTypes, MeasurementSessionActions } from '../actions/MeasurementSession';
import { LoopbackErrorActions } from '../actions/error';
import { MeasurementSessionApi } from '../services/index';

@Injectable()
export class MeasurementSessionEffects extends BaseLoopbackEffects {
  @Effect()
  public sync$ = this.actions$
    .pipe(
      ofType(MeasurementSessionActionTypes.SYNC),
      mergeMap((action: LoopbackAction) =>
        this.measurementsession.sync(action.payload.session).pipe(
          map((response: any) => new MeasurementSessionActions.syncSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new MeasurementSessionActions.syncFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public syncList$ = this.actions$
    .pipe(
      ofType(MeasurementSessionActionTypes.SYNC_LIST),
      mergeMap((action: LoopbackAction) =>
        this.measurementsession.syncList(action.payload.ids).pipe(
          map((response: any) => new MeasurementSessionActions.syncListSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new MeasurementSessionActions.syncListFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public steps$ = this.actions$
    .pipe(
      ofType(MeasurementSessionActionTypes.STEPS),
      mergeMap((action: LoopbackAction) =>
        this.measurementsession.steps(action.payload.sessionId).pipe(
          map((response: any) => new MeasurementSessionActions.stepsSuccess(action.payload.id, response, action.meta)),
          catchError((error: any) => concat(
            of(new MeasurementSessionActions.stepsFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(MeasurementSessionApi) public measurementsession: MeasurementSessionApi
  ) {
    super(actions$, measurementsession, 'MeasurementSession', MeasurementSessionActionTypes, MeasurementSessionActions);
  }
}
