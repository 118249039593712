/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementSession, MeasurementSessionInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementSessionActionTypes } from '../actions';

export interface MeasurementSessionsState extends EntityState<MeasurementSession | MeasurementSessionInterface> {};

export const MeasurementSessionAdapter: EntityAdapter<MeasurementSession | MeasurementSessionInterface> = createEntityAdapter<MeasurementSession | MeasurementSessionInterface>();

const initialState: MeasurementSessionsState = MeasurementSessionAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementSessionsState, MeasurementSession | MeasurementSessionInterface>(MeasurementSessionActionTypes, MeasurementSessionAdapter);

/**
 * @module MeasurementSessionsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementSession reducer.
 */
export function MeasurementSessionsReducer(state = initialState, action: LoopbackAction): MeasurementSessionsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementSessionsState = (state: any) => state.MeasurementSessions;
export const getMeasurementSessionsEntities = (state: any) => state.MeasurementSessions.entities;
export const getMeasurementSessionsIds = (state: any) => state.MeasurementSessions.ids;

export const getMeasurementSessions =
  createSelector(getMeasurementSessionsEntities, getMeasurementSessionsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementSessionById(id: string) {
  return (state: any) => state.MeasurementSessions.entities[id];
}

export function getMeasurementSessionsById(ids: string[]) {
  return createSelector(getMeasurementSessionsEntities, (entities) => ids.map((id) => entities[id]));
}
