/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Location, LocationInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { LocationActionTypes } from '../actions';

export interface LocationsState extends EntityState<Location | LocationInterface> {};

export const LocationAdapter: EntityAdapter<Location | LocationInterface> = createEntityAdapter<Location | LocationInterface>();

const initialState: LocationsState = LocationAdapter.getInitialState({});

const cases = BaseReducerFactory<LocationsState, Location | LocationInterface>(LocationActionTypes, LocationAdapter);

/**
 * @module LocationsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Location reducer.
 */
export function LocationsReducer(state = initialState, action: LoopbackAction): LocationsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getLocationsState = (state: any) => state.Locations;
export const getLocationsEntities = (state: any) => state.Locations.entities;
export const getLocationsIds = (state: any) => state.Locations.ids;

export const getLocations =
  createSelector(getLocationsEntities, getLocationsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getLocationById(id: string) {
  return (state: any) => state.Locations.entities[id];
}

export function getLocationsById(ids: string[]) {
  return createSelector(getLocationsEntities, (entities) => ids.map((id) => entities[id]));
}
