import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
export class LabelsComponent extends SettingsComponent {
}
LabelsComponent.propDecorators = {
    'background': [{ type: Input },],
    'border': [{ type: Input },],
    'color': [{ type: Input },],
    'font': [{ type: Input },],
    'format': [{ type: Input },],
    'margin': [{ type: Input },],
    'padding': [{ type: Input },],
    'content': [{ type: Input },],
    'visible': [{ type: Input },],
};
