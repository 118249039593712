import {Component, OnInit} from '@angular/core';
import {BaseEntityListComponent} from '../../base-entity-list/base-entity-list.component';
import {DialogService} from '@progress/kendo-angular-dialog';
import {TranslateService} from '@ngx-translate/core';
import {ApiExService} from '../../../services/api-ex.service';
import {TableViewMode} from '../../../enums/tableviewmode.code';

@Component({
    selector: 'app-custom-entity-list',
    templateUrl: './custom-entity-list.component.html',
    styleUrls: ['./custom-entity-list.component.scss']
})
export class CustomEntityListComponent
    extends BaseEntityListComponent
    implements OnInit {

    constructor(public dialogService: DialogService,
                public translate: TranslateService,
                public apiEx: ApiExService) {
        super(dialogService, translate, apiEx);

        this.tableViewMode = TableViewMode.Cards;
        this.blockHeader = null;
        this.api = apiEx;
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
