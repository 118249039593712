import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Effect, Actions, ofType} from '@ngrx/effects';
import {of} from 'rxjs/observable/of';

import {AppUserApi} from '../lb-sdk/services/custom';
import {LoopBackAuth} from '../lb-sdk/services/core';
import {AppUserActions, AppUserActionTypes} from '../lb-sdk/actions';
import {Store} from '@ngrx/store';
import {LoopbackAuthActions, LoopbackAuthActionTypes} from '../lb-sdk/actions/auth';
import {map} from 'rxjs/operators';
import {LoopbackAction} from '../lb-sdk/models/BaseModels';
import {AppAuthActionTypes} from '../actions/auth';

@Injectable()
export class AuthEffects /*extends LoopbackAuthEffects*/ {

    @Effect({dispatch: false})
    loginSuccess$ = this.actions$.pipe(
        ofType(AppUserActionTypes.LOGIN_SUCCESS),
        map(() => {
            // this.router.navigate(['/customer/profile']);
            this.router.navigate(['/']);
        })
    );

    @Effect({dispatch: false})
    public loginFail$ = this.actions$.pipe(
        ofType(AppUserActionTypes.LOGIN_FAIL),
        map((data: any) => {
        })
    );

    @Effect({dispatch: false})
    signupSuccess$ = this.actions$.pipe(
        ofType(AppUserActionTypes.SIGNUP_SUCCESS),
        map((data: any) => {
            this.store.dispatch(new AppUserActions.login({
                email: data.payload.credentials.email,
                password: data.payload.credentials.password
            }));

        })
    );

    @Effect({dispatch: false})
    logoutSuccess$ = this.actions$.pipe(
        ofType(AppUserActionTypes.LOGOUT_SUCCESS),
        map(authed => {
            this.router.navigate(['/account/login']);
        })
    );

    @Effect({dispatch: false})
    guardFail$ = this.actions$.pipe(
        ofType(LoopbackAuthActionTypes.GUARD_FAIL),
        map(() => {
            this.router.navigate(['/account/login']);
        })
    );

    @Effect({dispatch: false})
    roleGuardFail$ = this.actions$.pipe(
        ofType(AppAuthActionTypes.ROLE_GUARD_FAIL),
        map(() => {
            this.router.navigate(['/']);
        })
    );

    constructor(private actions$: Actions,
                private store: Store<any>,
                private auth: LoopBackAuth,
                private user: AppUserApi,
                private router: Router) {
    }

}
