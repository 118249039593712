import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-inline-param-editor',
    templateUrl: './inline-param-editor.component.html',
    styleUrls: ['./inline-param-editor.component.scss']
})
export class InlineParamEditorComponent implements OnInit {

    @Input() conf; // is UI & options
    @Input() data; // is where we store values

    @Output() onSet = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

    setParam(param, value) {
        console.log(`${param} -> ${value}`);
        this.onSet.emit({param: param, value: value});
    }

    getDisplayValue() {
        const value = this.data[this.conf.param];

        if (!value) {
            return '?';
        }

        if (this.conf.type == 'options') {
            return this.conf.options[value].text;
        }

        return value;
    }
}
