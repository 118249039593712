/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Event } from '../models';

export const EventActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Event'), {
  FIND_BY_ID_MANAGERS: type('[Event] findByIdManagers'),
  FIND_BY_ID_MANAGERS_SUCCESS: type('[Event] findByIdManagers success'),
  FIND_BY_ID_MANAGERS_FAIL: type('[Event] findByIdManagers fail'),

  DESTROY_BY_ID_MANAGERS: type('[Event] destroyByIdManagers'),
  DESTROY_BY_ID_MANAGERS_SUCCESS: type('[Event] destroyByIdManagers success'),
  DESTROY_BY_ID_MANAGERS_FAIL: type('[Event] destroyByIdManagers fail'),

  UPDATE_BY_ID_MANAGERS: type('[Event] updateByIdManagers'),
  UPDATE_BY_ID_MANAGERS_SUCCESS: type('[Event] updateByIdManagers success'),
  UPDATE_BY_ID_MANAGERS_FAIL: type('[Event] updateByIdManagers fail'),

  GET_LOCATION: type('[Event] getLocation'),
  GET_LOCATION_SUCCESS: type('[Event] getLocation success'),
  GET_LOCATION_FAIL: type('[Event] getLocation fail'),

  FIND_BY_ID_PRODUCTS: type('[Event] findByIdProducts'),
  FIND_BY_ID_PRODUCTS_SUCCESS: type('[Event] findByIdProducts success'),
  FIND_BY_ID_PRODUCTS_FAIL: type('[Event] findByIdProducts fail'),

  DESTROY_BY_ID_PRODUCTS: type('[Event] destroyByIdProducts'),
  DESTROY_BY_ID_PRODUCTS_SUCCESS: type('[Event] destroyByIdProducts success'),
  DESTROY_BY_ID_PRODUCTS_FAIL: type('[Event] destroyByIdProducts fail'),

  UPDATE_BY_ID_PRODUCTS: type('[Event] updateByIdProducts'),
  UPDATE_BY_ID_PRODUCTS_SUCCESS: type('[Event] updateByIdProducts success'),
  UPDATE_BY_ID_PRODUCTS_FAIL: type('[Event] updateByIdProducts fail'),

  LINK_PRODUCTS: type('[Event] linkProducts'),
  LINK_PRODUCTS_SUCCESS: type('[Event] linkProducts success'),
  LINK_PRODUCTS_FAIL: type('[Event] linkProducts fail'),

  UNLINK_PRODUCTS: type('[Event] unlinkProducts'),
  UNLINK_PRODUCTS_SUCCESS: type('[Event] unlinkProducts success'),
  UNLINK_PRODUCTS_FAIL: type('[Event] unlinkProducts fail'),

  FIND_BY_ID_BOOKINGS: type('[Event] findByIdBookings'),
  FIND_BY_ID_BOOKINGS_SUCCESS: type('[Event] findByIdBookings success'),
  FIND_BY_ID_BOOKINGS_FAIL: type('[Event] findByIdBookings fail'),

  DESTROY_BY_ID_BOOKINGS: type('[Event] destroyByIdBookings'),
  DESTROY_BY_ID_BOOKINGS_SUCCESS: type('[Event] destroyByIdBookings success'),
  DESTROY_BY_ID_BOOKINGS_FAIL: type('[Event] destroyByIdBookings fail'),

  UPDATE_BY_ID_BOOKINGS: type('[Event] updateByIdBookings'),
  UPDATE_BY_ID_BOOKINGS_SUCCESS: type('[Event] updateByIdBookings success'),
  UPDATE_BY_ID_BOOKINGS_FAIL: type('[Event] updateByIdBookings fail'),

  FIND_BY_ID_GENDERS: type('[Event] findByIdGenders'),
  FIND_BY_ID_GENDERS_SUCCESS: type('[Event] findByIdGenders success'),
  FIND_BY_ID_GENDERS_FAIL: type('[Event] findByIdGenders fail'),

  DESTROY_BY_ID_GENDERS: type('[Event] destroyByIdGenders'),
  DESTROY_BY_ID_GENDERS_SUCCESS: type('[Event] destroyByIdGenders success'),
  DESTROY_BY_ID_GENDERS_FAIL: type('[Event] destroyByIdGenders fail'),

  UPDATE_BY_ID_GENDERS: type('[Event] updateByIdGenders'),
  UPDATE_BY_ID_GENDERS_SUCCESS: type('[Event] updateByIdGenders success'),
  UPDATE_BY_ID_GENDERS_FAIL: type('[Event] updateByIdGenders fail'),

  LINK_GENDERS: type('[Event] linkGenders'),
  LINK_GENDERS_SUCCESS: type('[Event] linkGenders success'),
  LINK_GENDERS_FAIL: type('[Event] linkGenders fail'),

  UNLINK_GENDERS: type('[Event] unlinkGenders'),
  UNLINK_GENDERS_SUCCESS: type('[Event] unlinkGenders success'),
  UNLINK_GENDERS_FAIL: type('[Event] unlinkGenders fail'),

  GET_STATUS: type('[Event] getStatus'),
  GET_STATUS_SUCCESS: type('[Event] getStatus success'),
  GET_STATUS_FAIL: type('[Event] getStatus fail'),

  GET_MANAGERS: type('[Event] getManagers'),
  GET_MANAGERS_SUCCESS: type('[Event] getManagers success'),
  GET_MANAGERS_FAIL: type('[Event] getManagers fail'),

  CREATE_MANAGERS: type('[Event] createManagers'),
  CREATE_MANAGERS_SUCCESS: type('[Event] createManagers success'),
  CREATE_MANAGERS_FAIL: type('[Event] createManagers fail'),

  DELETE_MANAGERS: type('[Event] deleteManagers'),
  DELETE_MANAGERS_SUCCESS: type('[Event] deleteManagers success'),
  DELETE_MANAGERS_FAIL: type('[Event] deleteManagers fail'),

  GET_PRODUCTS: type('[Event] getProducts'),
  GET_PRODUCTS_SUCCESS: type('[Event] getProducts success'),
  GET_PRODUCTS_FAIL: type('[Event] getProducts fail'),

  CREATE_PRODUCTS: type('[Event] createProducts'),
  CREATE_PRODUCTS_SUCCESS: type('[Event] createProducts success'),
  CREATE_PRODUCTS_FAIL: type('[Event] createProducts fail'),

  DELETE_PRODUCTS: type('[Event] deleteProducts'),
  DELETE_PRODUCTS_SUCCESS: type('[Event] deleteProducts success'),
  DELETE_PRODUCTS_FAIL: type('[Event] deleteProducts fail'),

  GET_BOOKINGS: type('[Event] getBookings'),
  GET_BOOKINGS_SUCCESS: type('[Event] getBookings success'),
  GET_BOOKINGS_FAIL: type('[Event] getBookings fail'),

  CREATE_BOOKINGS: type('[Event] createBookings'),
  CREATE_BOOKINGS_SUCCESS: type('[Event] createBookings success'),
  CREATE_BOOKINGS_FAIL: type('[Event] createBookings fail'),

  DELETE_BOOKINGS: type('[Event] deleteBookings'),
  DELETE_BOOKINGS_SUCCESS: type('[Event] deleteBookings success'),
  DELETE_BOOKINGS_FAIL: type('[Event] deleteBookings fail'),

  GET_GENDERS: type('[Event] getGenders'),
  GET_GENDERS_SUCCESS: type('[Event] getGenders success'),
  GET_GENDERS_FAIL: type('[Event] getGenders fail'),

  CREATE_GENDERS: type('[Event] createGenders'),
  CREATE_GENDERS_SUCCESS: type('[Event] createGenders success'),
  CREATE_GENDERS_FAIL: type('[Event] createGenders fail'),

  DELETE_GENDERS: type('[Event] deleteGenders'),
  DELETE_GENDERS_SUCCESS: type('[Event] deleteGenders success'),
  DELETE_GENDERS_FAIL: type('[Event] deleteGenders fail'),

  CREATE_MANY_MANAGERS: type('[Event] createManyManagers'),
  CREATE_MANY_MANAGERS_SUCCESS: type('[Event] createManyManagers success'),
  CREATE_MANY_MANAGERS_FAIL: type('[Event] createManyManagers fail'),

  CREATE_MANY_PRODUCTS: type('[Event] createManyProducts'),
  CREATE_MANY_PRODUCTS_SUCCESS: type('[Event] createManyProducts success'),
  CREATE_MANY_PRODUCTS_FAIL: type('[Event] createManyProducts fail'),

  CREATE_MANY_BOOKINGS: type('[Event] createManyBookings'),
  CREATE_MANY_BOOKINGS_SUCCESS: type('[Event] createManyBookings success'),
  CREATE_MANY_BOOKINGS_FAIL: type('[Event] createManyBookings fail'),

  CREATE_MANY_GENDERS: type('[Event] createManyGenders'),
  CREATE_MANY_GENDERS_SUCCESS: type('[Event] createManyGenders success'),
  CREATE_MANY_GENDERS_FAIL: type('[Event] createManyGenders fail'),

});
export const EventActions =
Object.assign(BaseLoopbackActionsFactory<Event>(EventActionTypes), {

  /**
   * findByIdManagers Action.
   * Find a related item by id for managers.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for managers
   * @param {any} meta (optional).
   * 
   */
  findByIdManagers: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_MANAGERS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdManagersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_MANAGERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdManagersFail: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdManagers Action.
   * Delete a related item by id for managers.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for managers
   * @param {any} meta (optional).
   * 
   */
  destroyByIdManagers: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_MANAGERS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdManagersSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_MANAGERS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdManagersFail: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdManagers Action.
   * Update a related item by id for managers.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for managers
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdManagers: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_MANAGERS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdManagersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_MANAGERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdManagersFail: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getLocation Action.
   * Fetches belongsTo relation location.
   *
   * @param {any} id Event id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getLocation: class implements Action {
    public readonly type = EventActionTypes.GET_LOCATION;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getLocationSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getLocationSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_LOCATION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getLocationFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getLocationFail: class implements Action {
    public readonly type = EventActionTypes.GET_LOCATION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdProducts Action.
   * Find a related item by id for products.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for products
   * @param {any} meta (optional).
   * 
   */
  findByIdProducts: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_PRODUCTS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdProductsFail: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdProducts Action.
   * Delete a related item by id for products.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for products
   * @param {any} meta (optional).
   * 
   */
  destroyByIdProducts: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_PRODUCTS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdProductsSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_PRODUCTS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdProductsFail: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdProducts Action.
   * Update a related item by id for products.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for products
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdProducts: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_PRODUCTS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdProductsFail: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkProducts Action.
   * Add a related item by id for products.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for products
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkProducts: class implements Action {
    public readonly type = EventActionTypes.LINK_PRODUCTS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.LINK_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkProductsFail: class implements Action {
    public readonly type = EventActionTypes.LINK_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkProducts Action.
   * Remove the products relation to an item by id.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for products
   * @param {any} meta (optional).
   * 
   */
  unlinkProducts: class implements Action {
    public readonly type = EventActionTypes.UNLINK_PRODUCTS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkProductsSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.UNLINK_PRODUCTS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkProductsFail: class implements Action {
    public readonly type = EventActionTypes.UNLINK_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdBookings Action.
   * Find a related item by id for bookings.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for bookings
   * @param {any} meta (optional).
   * 
   */
  findByIdBookings: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_BOOKINGS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdBookingsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_BOOKINGS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdBookingsFail: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdBookings Action.
   * Delete a related item by id for bookings.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for bookings
   * @param {any} meta (optional).
   * 
   */
  destroyByIdBookings: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_BOOKINGS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdBookingsSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_BOOKINGS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdBookingsFail: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdBookings Action.
   * Update a related item by id for bookings.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for bookings
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdBookings: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_BOOKINGS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdBookingsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_BOOKINGS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdBookingsFail: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdGenders Action.
   * Find a related item by id for genders.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for genders
   * @param {any} meta (optional).
   * 
   */
  findByIdGenders: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_GENDERS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdGendersFail: class implements Action {
    public readonly type = EventActionTypes.FIND_BY_ID_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdGenders Action.
   * Delete a related item by id for genders.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for genders
   * @param {any} meta (optional).
   * 
   */
  destroyByIdGenders: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_GENDERS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdGendersSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_GENDERS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdGendersFail: class implements Action {
    public readonly type = EventActionTypes.DESTROY_BY_ID_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdGenders Action.
   * Update a related item by id for genders.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for genders
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdGenders: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_GENDERS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdGendersFail: class implements Action {
    public readonly type = EventActionTypes.UPDATE_BY_ID_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkGenders Action.
   * Add a related item by id for genders.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for genders
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkGenders: class implements Action {
    public readonly type = EventActionTypes.LINK_GENDERS;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.LINK_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkGendersFail: class implements Action {
    public readonly type = EventActionTypes.LINK_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkGenders Action.
   * Remove the genders relation to an item by id.
   *
   * @param {any} id Event id
   * @param {any} fk Foreign key for genders
   * @param {any} meta (optional).
   * 
   */
  unlinkGenders: class implements Action {
    public readonly type = EventActionTypes.UNLINK_GENDERS;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkGendersSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.UNLINK_GENDERS_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkGendersFail: class implements Action {
    public readonly type = EventActionTypes.UNLINK_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getStatus Action.
   * Fetches belongsTo relation status.
   *
   * @param {any} id Event id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getStatus: class implements Action {
    public readonly type = EventActionTypes.GET_STATUS;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getStatusSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getStatusSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_STATUS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getStatusFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getStatusFail: class implements Action {
    public readonly type = EventActionTypes.GET_STATUS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getManagers Action.
   * Queries managers of Event.
   *
   * @param {any} id Event id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getManagers: class implements Action {
    public readonly type = EventActionTypes.GET_MANAGERS;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getManagersSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_MANAGERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getManagersFail: class implements Action {
    public readonly type = EventActionTypes.GET_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManagers Action.
   * Creates a new instance in managers of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManagers: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANAGERS;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManagersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANAGERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManagersFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteManagers Action.
   * Deletes all managers of this model.
   *
   * @param {any} id Event id
   * @param {any} meta (optional).
   * 
   */
  deleteManagers: class implements Action {
    public readonly type = EventActionTypes.DELETE_MANAGERS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteManagersSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.DELETE_MANAGERS_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteManagersFail: class implements Action {
    public readonly type = EventActionTypes.DELETE_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getProducts Action.
   * Queries products of Event.
   *
   * @param {any} id Event id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getProducts: class implements Action {
    public readonly type = EventActionTypes.GET_PRODUCTS;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getProductsFail: class implements Action {
    public readonly type = EventActionTypes.GET_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createProducts Action.
   * Creates a new instance in products of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createProducts: class implements Action {
    public readonly type = EventActionTypes.CREATE_PRODUCTS;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createProductsFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteProducts Action.
   * Deletes all products of this model.
   *
   * @param {any} id Event id
   * @param {any} meta (optional).
   * 
   */
  deleteProducts: class implements Action {
    public readonly type = EventActionTypes.DELETE_PRODUCTS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteProductsSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.DELETE_PRODUCTS_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteProductsFail: class implements Action {
    public readonly type = EventActionTypes.DELETE_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getBookings Action.
   * Queries bookings of Event.
   *
   * @param {any} id Event id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getBookings: class implements Action {
    public readonly type = EventActionTypes.GET_BOOKINGS;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getBookingsSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_BOOKINGS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getBookingsFail: class implements Action {
    public readonly type = EventActionTypes.GET_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createBookings Action.
   * Creates a new instance in bookings of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createBookings: class implements Action {
    public readonly type = EventActionTypes.CREATE_BOOKINGS;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createBookingsSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_BOOKINGS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createBookingsFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteBookings Action.
   * Deletes all bookings of this model.
   *
   * @param {any} id Event id
   * @param {any} meta (optional).
   * 
   */
  deleteBookings: class implements Action {
    public readonly type = EventActionTypes.DELETE_BOOKINGS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteBookingsSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.DELETE_BOOKINGS_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteBookingsFail: class implements Action {
    public readonly type = EventActionTypes.DELETE_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getGenders Action.
   * Queries genders of Event.
   *
   * @param {any} id Event id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getGenders: class implements Action {
    public readonly type = EventActionTypes.GET_GENDERS;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.GET_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getGendersFail: class implements Action {
    public readonly type = EventActionTypes.GET_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createGenders Action.
   * Creates a new instance in genders of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createGenders: class implements Action {
    public readonly type = EventActionTypes.CREATE_GENDERS;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createGendersFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteGenders Action.
   * Deletes all genders of this model.
   *
   * @param {any} id Event id
   * @param {any} meta (optional).
   * 
   */
  deleteGenders: class implements Action {
    public readonly type = EventActionTypes.DELETE_GENDERS;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteGendersSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.DELETE_GENDERS_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteGendersFail: class implements Action {
    public readonly type = EventActionTypes.DELETE_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyManagers Action.
   * Creates a new instance in managers of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyManagers: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_MANAGERS;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyManagersSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyManagersSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_MANAGERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyManagersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyManagersFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_MANAGERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyProducts Action.
   * Creates a new instance in products of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyProducts: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_PRODUCTS;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyProductsSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyProductsSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_PRODUCTS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyProductsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyProductsFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_PRODUCTS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyBookings Action.
   * Creates a new instance in bookings of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyBookings: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_BOOKINGS;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyBookingsSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyBookingsSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_BOOKINGS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyBookingsFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyBookingsFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_BOOKINGS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyGenders Action.
   * Creates a new instance in genders of this model.
   *
   * @param {any} id Event id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyGenders: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_GENDERS;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyGendersSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyGendersSuccess: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_GENDERS_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyGendersFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyGendersFail: class implements Action {
    public readonly type = EventActionTypes.CREATE_MANY_GENDERS_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});