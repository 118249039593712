/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, EventStatus } from '../models';

export const EventStatusActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('EventStatus'), {
});
export const EventStatusActions =
Object.assign(BaseLoopbackActionsFactory<EventStatus>(EventStatusActionTypes), {
});