export enum MeasurementCode
{
    Unknown,
    DataFormat,
    Acceleration,
    AngularVelocity,
    MagneticField,
    Quaternion,
    Force,
    AmbientTemperature,
    BarometricPressure,
    AmbientHumidity,
    StateOfCharge,
    Voltage,
    Current,
    BatteryStatus,
    Pitch,
    Roll,
    Yaw,
    CommandOutput
};