import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppStoreModule} from './app.store';
import {SharedModule} from './shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {SDKBrowserModule, LoopBackConfig} from './shared/lb-sdk';

import {AuthGuard} from './shared/lb-sdk/guards';
import {RoleGuard} from './shared/guards/role.guard';
import {DialogModule} from '@progress/kendo-angular-dialog';

import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';


import {PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return params.key;
    }
}

// Icons

import {fal} from '@fortawesome/pro-light-svg-icons';
// import {fab} from '@fortawesome/free-brands-svg-icons';
import {FullScreenComponent} from './layouts/full-screen/full-screen.component';
import {WorkingEventGuard} from './shared/guards/working-event.guard';
import {SalespersonGuard} from './shared/guards/salesperson.guard';
import {HttpErrorInterceptor} from './shared/interceptors/http.interceptor';
import {UiService} from './shared/services/ui.service';

export function uiProvider(provider: UiService) {
    return () => provider.loadParams();
}


@NgModule({
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        AppStoreModule,
        SharedModule.forRoot(),
        SDKBrowserModule.forRoot(),
        // NgbModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            // missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),

        FontAwesomeModule,

        DialogModule,
        PerfectScrollbarModule
    ],
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        FullScreenComponent
    ],
    providers: [
        AuthGuard,
        RoleGuard,
        WorkingEventGuard,
        SalespersonGuard,
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: uiProvider, deps: [UiService], multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary, faConfig: FaConfig) {
        const apiConfig = window['apiConfig'];

        LoopBackConfig.setBaseURL(apiConfig.baseUrl);
        LoopBackConfig.setApiVersion(apiConfig.version);

        library.addIconPacks(fal);
        faConfig.defaultPrefix = 'fal';
    }
}
