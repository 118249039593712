import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {ViewMode} from '../enums/viewmode.code';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class UiService {

    public isDemoAccount: boolean;

    constructor(
        private translateService: TranslateService,
        private http: HttpClient
    ) {
    }

    public params = {};

    // working<XXX> / localStorage
    public workingUserId;
    public workingUser: any;
    public workingGroupId;
    public workingGroup: any;
    public workingEventId;
    public workingEvent: any;
    public workingMidsoleId;
    public workingMidsole: any = {};
    public workingBooking: any;

    public localConfig = {
        apiEnabled: false
    };

    public endSession() {
        localStorage.removeItem('workingGroupId');
        localStorage.removeItem('workingUserId');

        this.workingGroup = null;
        this.workingGroupId = null;
        this.workingUser = null;
        this.workingUserId = null;
    }

    public setWorkingEventId(id) {
        this.workingEventId = id;

        if (!this.workingEventId) {
            this.workingEvent = null;
            localStorage.removeItem('workingEventId');
        } else {
            localStorage.setItem('workingEventId', this.workingEventId);
        }
    }

    public setWorkingUserId(id) {
        this.endSession();
        localStorage.setItem('workingUserId', id);
    }

    public setWorkingGroupId(id) {
        this.endSession();
        localStorage.setItem('workingGroupId', id);
    }

    //

    public noEmailSuffux = '@unique.id';

    // This used for status display
    public availability = {
        api: {
            local: true,
            cloud: true
        }
    };

    public getProfileImage(user: any) {
        if (user.id == 102) {
            return '/assets/img/customer/demo-avatar.jpg';
        }
        return '/assets/portrait/avatar-' + this.getAvatarCode(user) + '.png';
    }

    // View modes

    public viewMode: ViewMode = ViewMode.Demo;

    // i18n

    public isJp() {
        return this.translateService.currentLang == 'ja'
    }

    public isEn() {
        return this.translateService.currentLang == 'en'
    }

    // Dialogs

    private isDialogActive = new BehaviorSubject<boolean>(false);
    public isDialogActive$ = this.isDialogActive.asObservable();

    public setDialogActive(state: boolean) {
        this.isDialogActive.next(state);
    }

    // Images
    //imageBaseUrl = 'https://ile-cp.s3.eu-central-1.amazonaws.com/qu/crm';
    imageBaseUrl = '/assets/img/logo/ds';

    getImageUrl(filename) {
        //return `${this.imageBaseUrl}/${environment.client}/${environment.theme}/${filename}`;
        return `${this.imageBaseUrl}/${filename}`;
    }

    // Dates

    public formatDate(src) {
        let src_ = new Date(src);
        let dt = moment(src_, moment.HTML5_FMT.DATETIME_LOCAL_MS);
        let y = dt.year();
        let m = dt.month() + 1;
        let d = dt.date();
        let h = dt.hour();
        let mn = dt.minute();

        return y + '/' + m + '/' + d + ' ' + h + ':' + (mn > 10 ? mn : '0' + mn);
    }

    public formatDateDb(src) {
        let src_ = new Date(src);
        let dt = moment(src_, moment.HTML5_FMT.DATETIME_LOCAL_MS);
        let y = dt.year();
        let m = dt.month() + 1;
        let d = dt.date();
        let h = dt.hour();
        let mn = dt.minute();

        return y + '-' + (m > 10 ? m : '0' + m) + '-' + (d > 10 ? d : '0' + d); // + ' ' + h + ':' + (mn > 10 ? mn : '0' + mn);
    }

    // Metrics

    m2mm(value) {
        return Math.floor(parseFloat(value) * 1000);
    }

    // User data

    getAvatarCode(user) {
        let genderId = user.genderId ? user.genderId : 2;
        let version = user.id % 10;

        return genderId + '-' + version;
    }

    getGender(user) {
        switch (+user.genderId) {
            case 1:
                return 'Male';
            case 2:
                return 'Female';
        }

        return '?';
    }

    isValidEmail(email) {
        if (!email) {
            return false;
        }

        return email.indexOf(this.noEmailSuffux) === -1;
    }

    public loadParams() {
        return new Promise((resolve, reject) => {
            this.http
                .get('/api/Uis/parameters')
                .subscribe((res: any) => {
                    this.params = res;
                    resolve(true);
                })
        })
    }
}
