import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {SelectableSettings} from '@progress/kendo-angular-grid';
import {SortDescriptor} from '@progress/kendo-data-query';
import {AppGridColumn} from '../../../events/list/list.component';
import {BaseLoopBackApi} from '../../lb-sdk/services/core';
import {TableViewMode} from '../../enums/tableviewmode.code';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {catchError, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {AppUser} from '../../lb-sdk/models';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {BaseEntityEditComponent} from '../base-entity-edit/base-entity-edit.component';
import {GridRestDataDirective} from '../../directives/grid-rest-data.directive';
import * as moment from 'moment';
import {LoopBackConfig} from '../../lb-sdk';
import {ApiExService} from '../../services/api-ex.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-base-entity-list',
    templateUrl: './base-entity-list.component.html',
    styleUrls: ['./base-entity-list.component.scss']
})
export class BaseEntityListComponent implements OnInit, OnChanges {

    @ViewChild('grid', {read: GridRestDataDirective, static: false}) public gridRestDataDirective: GridRestDataDirective;

    @Input() user: number;
    @Input() event: number;
    @Input() limit = 0;
    @Input() blockHeader = 'Generic';

    // advanced
    @Input() entity: string;
    @Input() cardTemplate: TemplateRef<any>;
    @Input() cardHeaderTemplate: TemplateRef<any>;
    @Input() cardFooterTemplate: TemplateRef<any>;
    @Input() conf: any;

    isPageable = true;

    get isFilterable(): boolean {
        return this.tableViewMode === TableViewMode.Table
    };

    get isSortable(): boolean {
        return this.tableViewMode === TableViewMode.Table
    };

    canAdd = true;
    canEdit = true;
    canDelete = true;
    canExport = false;

    TableViewMode = TableViewMode;
    tableViewMode: TableViewMode = TableViewMode.Table;

    text: any = {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete'
    };

    // Preprocess data
    public dataProcessor: any; // function

    // API
    defaultTitleField = 'title';

    gridCols: AppGridColumn[] = [
        {field: 'title', title: 'Title'}
    ];

    api: BaseLoopBackApi;
    include: any;
    editableFields: any;
    defaultPageSize = 10;

    // Sort/group

    selectableSettings: SelectableSettings = {
        enabled: false,
        checkboxOnly: true,
        mode: 'multiple'
    };

    public defaultSort: SortDescriptor[] = [/*{
        field: 'title',
        dir: 'asc' THROWS ERROR IF THERE IS NO title FIELD
    }*/];

    public defaultFilter: any;
    public filter: any;

    // Interaction
    itemToRemove: any;
    editComponent: Function = BaseEntityEditComponent;

    // search
    public searchText = new Subject<string>();


    constructor(public dialogService: DialogService,
                public translate: TranslateService,
                public apiEx: ApiExService) {
    }

    ngOnInit() {
        this.searchText.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            //mergeMap(search => this.getValues()) // in getValues we can get actual data using REST and then proceed further
        ).subscribe((value) => {
            this.onProcessSearch(value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user && changes.user.currentValue) {
            if (!changes.user.isFirstChange()) {
                this.onReadData();
            }
        }

        if(changes.conf && changes.conf.currentValue) {
            console.log("init the list");
            this.onReadData();
        }
    }

    onSelectionChange(e: any) {
        /*    if (e.selected) {
              e.selectedRows[0].dataItem.isSelected = e.selected;

              if (this.rowsToCompare.length > 1) {
                this.rowsToCompare[1].isSelected = false;
                this.rowsToCompare = this.rowsToCompare.slice(0, 1);
              }

              this.rowsToCompare.push(e.selectedRows[0].dataItem);
            } else {
              // If deselect 1st clear all rowsToCompare
              if (this.rowsToCompare.length > 0 && this.rowsToCompare[0].id == e.deselectedRows[0].dataItem.id) {
                if (this.rowsToCompare.length > 1)
                  this.rowsToCompare[1].isSelected = false;

                this.rowsToCompare = [];
              } else {
                if (this.rowsToCompare.length > 1) {
                  this.rowsToCompare = this.rowsToCompare.slice(0, 1);
                }
              }
              e.deselectedRows[0].dataItem.isSelected = e.selected;
            }*/
    }

    isRowSelected(row: any) {
        return !!row.dataItem.isSelected;
    }

    // override
    protected onItemClicked(dataItem) {
        //console.log('[ITEM CLICKED] override required');
    }

    public onCellClick(e: any) {
        // if (e.columnIndex == 0) return; // if checkbox column -> uncomment

        if (e && e.dataItem && e.dataItem.id) {
            this.onItemClicked(e.dataItem);
            // TODO this.router.navigate(['/events/details/', e.dataItem.id]);
        }
    }

    public toggleTableMode() {
        this.tableViewMode = this.tableViewMode === TableViewMode.Table ? TableViewMode.Cards : TableViewMode.Table;
    }

    public setTableMode(mode) {
        this.tableViewMode = mode;
    }

    protected showError(errorText: string) {
        const dialog: DialogRef = this.dialogService.open({
            title: 'Error occured',
            // content: '<p class="text-center m-3">' + errorText + '</p>',
            content: errorText,
            actions: [
                {text: 'OK', primary: true}
            ]
        });
    }

    public onSearch(keyword: string) {
        this.searchText.next(keyword);
    }

    // override
    protected onProcessSearch(keyword: string) {
        console.log('[SEARCH] override required');
    }

    // override
    protected onAddItem() {
        return {};
    }

    public addHandler() {

        // const user: any = new AppUser();
        // user.roleId = 4;
        //this.openEditDialog(user); // TODO move to user
        this.openEditDialog(this.onAddItem());
    }

    // override
    protected onEditItem(item) {
        if (!this.editableFields) {
            return item;
        }

        let editablePart = {id: item.id};

        for (let f of Object.keys(this.editableFields)) {
            editablePart[f] = item[f];
        }

        return editablePart;
    }

    public editHandler({dataItem}) {
        if (dataItem.roles && dataItem.roles.length > 0) {
            dataItem.roleId = dataItem.roles[0].id;
        }

        this.openEditDialog(this.onEditItem(dataItem));
    }

    protected generateFormControl(fieldOptions) {
        return new FormControl();
    }

    protected prepareEditForm(instance: any, dataItem: any) {
        if (this.editableFields) {
            let controls = {id: new FormControl()};

            for (let f of Object.keys(this.editableFields)) {
                controls[f] = this.generateFormControl(this.editableFields[f]);
            }

            instance.editForm = new FormGroup(controls);
        } else {
            console.log('[EDIT] override required');
        }
    }

    protected openEditDialog(dataItem) {
        // Open
        const dialogRef = this.dialogService.open({
            content: this.editComponent
        });

        this.prepareEditForm(dialogRef.content.instance, dataItem);
        // Dicts
        // let dicts = dialogRef.content.instance.dicts;

        // Data
        dialogRef.content.instance.item = dataItem;

        // Result
        dialogRef.result.subscribe((r: any) => {

            if (r.isSuccess) {
                this.onSaveData(r.values);
            }
        });
    }

    // override
    protected onSaveData(dataItem: any) {
        if (dataItem.id) {
            this.apiEx.updateAttributesEx(this.api.getModelName(), dataItem.id, dataItem)
                .pipe(catchError(err => of({err})))
                .subscribe((data: any) => {
                    if (data.err) {
                        this.showError(data.err.message);
                    } else {
                        this.onReadData();
                    }
                });
        } else {
            // TODO create
        }
    }

    protected onRemoveItem(dataItem) {
        this.api
            .deleteById(dataItem.id)
            .pipe(catchError(err => of({err})))
            .subscribe((data: any) => {
                if (data.err) {
                    this.showError(data.err.message);
                } else {
                    this.onReadData();
                }
            });
    }

    protected onReadData() {
        this.gridRestDataDirective.rebind();
    }

    public removeHandler(e: any, template: TemplateRef<any>) {
        this.itemToRemove = e.dataItem;

        const dialog: DialogRef = this.dialogService.open({
            title: this.translate.instant('Confirm'),
            content: template,
            actions: [
                {text: this.translate.instant('Yes'), primary: true, isSuccess: true},
                {text: this.translate.instant('No')},
            ]
        });

        dialog.result.subscribe((r: any) => {
            this.itemToRemove = null;

            if (r.isSuccess) {
                this.onRemoveItem(e.dataItem);
            }
        });
    }

}
