import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-button-hex',
    templateUrl: './button-hex.component.html',
    styleUrls: ['./button-hex.component.scss']
})
export class ButtonHexComponent implements OnInit {

    @Input() text;
    @Input() image;
    @Input() icon;

    @Input() disabled;

    constructor() {
    }

    ngOnInit() {
    }
}
