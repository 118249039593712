import {Injectable} from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {of} from 'rxjs/observable/of';
import {Observable} from 'rxjs/Observable';
import {finalize, tap} from 'rxjs/operators';
import {UiService} from '../services/ui.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private ui: UiService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apiConfig = window['apiConfig'];

        // if we need to modify req (https://angular.io/guide/http#intercepting-all-requests-or-responses)
        // const authReq = req.clone({
        //       headers: req.headers.set('Authorization', authToken)
        //     });

        const started = Date.now();

        const isLocalApiCall: boolean = req.url.startsWith(apiConfig.localEndpoint);
        const isCloudApiCall: boolean = req.url.startsWith(apiConfig.baseUrl + '/' + apiConfig.version);

        return next
            .handle(req)
            .pipe(
                tap(
                    (httpEvent: HttpEvent<any>) => {
                        if (httpEvent instanceof HttpResponse) {
                            if (isLocalApiCall) {
                                this.ui.availability.api.local = true;
                            }
                            if (isCloudApiCall) {
                                this.ui.availability.api.cloud = true;
                            }
                        }

                        //console.log(httpEvent); // TODO log all events here is possible
                    },
                    (error: HttpErrorResponse) => {

                        if (error instanceof Error) {

                        } else {
                            if (isLocalApiCall) {
                                this.ui.availability.api.local = false;
                            }
                            if (isCloudApiCall) {
                                this.ui.availability.api.cloud = false;
                            }
                        }
                    }
                ),
                finalize(() => {
                    const elapsed = Date.now() - started;
                    // TODO report too long timeouts
                    //const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
                })
            );
    }
}
