import {Directive, Input, OnInit} from '@angular/core';
import {DataBindingDirective, GridComponent} from '@progress/kendo-angular-grid';
import {BaseLoopBackApi} from '../lb-sdk/services/core';
import {ApiExService} from '../services/api-ex.service';

@Directive({
    selector: '[appGridRestData]'
})
export class GridRestDataDirective
    extends DataBindingDirective {

    @Input('appGridRestData') owner: any;

    api: BaseLoopBackApi;
    modelName: string;

    include: any;

    constructor(grid: GridComponent) {
        super(grid);
    }

    public ngOnInit(): void {
        super.ngOnInit(); // do not forget to call the base implementation
        this.rebind();
    }

    private loadFromDesignatedApi(q: any) {

        this.api.find(q).subscribe((rows: any) => {
            this.api.count(q.where).subscribe((info: any) => {

                this.grid.data = {
                    data: this.owner.dataProcessor ? this.owner.dataProcessor(rows) : rows,
                    total: info.count
                };
                this.grid.loading = false;

                this.notifyDataChange();
            });
        });
    }

    private loadFromUniversalApi(q: any) {
        const apiEx: any = this.api;

        apiEx.findEx(this.modelName, q).subscribe((rows: any) => {
            apiEx.countEx(this.modelName, q.where).subscribe((info: any) => {

                this.grid.data = {
                    data: this.owner.dataProcessor ? this.owner.dataProcessor(rows) : rows,
                    total: info.count
                };
                this.grid.loading = false;

                this.notifyDataChange();
            });
        });
    }

    public rebind(): void {
        this.grid.loading = true; // optionally show loading indicator

        // Load owners properties
        this.api = this.owner.api;
        this.include = this.owner.include;
        this.modelName = this.owner.entity;

        // if api is set but modelName is not - we have normal api (not apiEx) so we can just update it
        if (this.api && !this.modelName) {
            this.modelName = this.api.getModelName();
        }

        // to load the data we need to be sure that both api & modelName are known (for apiEx we should have separate case as it doesn't have modelName set)
        if (!this.api || !this.modelName) { // no api set - can't proceed
            this.grid.loading = false;
            return;
        }

        let limit = this.owner.limit > 0 ? this.owner.limit : this.state.take;
        let q: any = {
            skip: this.state.skip,
            limit: limit,
            include: this.include
        };

        if (this.owner.defaultFilter) {
            q.where = this.owner.defaultFilter;
        }

        if (this.owner.filter) {
            if (!q.where) {
                q.where = {};
            }

            q.where = {and: [q.where, this.owner.filter]};
        }

        if (this.owner.user > 0) {
            if (!q.where) {
                q.where = {};
            }

            q.where.appUserId = this.owner.user;
        }

        if (this.owner.event > 0) {
            if (!q.where) {
                q.where = {};
            }

            // qfix
            if (this.api.getModelName() == 'AppUser') {
                q.where.originEventId = this.owner.event;
            } else {
                q.where.eventId = this.owner.event;
            }
        }

        if (this.owner.defaultSort && this.owner.defaultSort.length > 0) {
            q.order = [this.owner.defaultSort[0].field + ' ' + this.owner.defaultSort[0].dir];
        }

        if (this.api.getModelName()) {
            this.loadFromDesignatedApi(q);
        } else {
            this.loadFromUniversalApi(q);
        }

        //this.products.query(this.state);
        //console.log(this.state);
    }
}
