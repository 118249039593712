import { Input, Component } from '@angular/core';
import { CollectionChangesService, ConfigurationService } from '../services';
import { CollectionItemComponent } from '../base-components';
/**
 * The configuration options for a pointer item of a LinearGauge.
 */
export class LinearPointerComponent extends CollectionItemComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
}
LinearPointerComponent.decorators = [
    { type: Component, args: [{
                providers: [ConfigurationService],
                selector: 'kendo-lineargauge-pointer',
                template: ''
            },] },
];
/** @nocollapse */
LinearPointerComponent.ctorParameters = () => [
    { type: ConfigurationService, },
    { type: CollectionChangesService, },
];
LinearPointerComponent.propDecorators = {
    'border': [{ type: Input },],
    'color': [{ type: Input },],
    'margin': [{ type: Input },],
    'opacity': [{ type: Input },],
    'shape': [{ type: Input },],
    'size': [{ type: Input },],
    'value': [{ type: Input },],
};
