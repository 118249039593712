/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Worker } from '../models';

export const WorkerActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Worker'), {
  FIND_BY_ID_MEASUREMENTSESSION: type('[Worker] findByIdMeasurementSession'),
  FIND_BY_ID_MEASUREMENTSESSION_SUCCESS: type('[Worker] findByIdMeasurementSession success'),
  FIND_BY_ID_MEASUREMENTSESSION_FAIL: type('[Worker] findByIdMeasurementSession fail'),

  DESTROY_BY_ID_MEASUREMENTSESSION: type('[Worker] destroyByIdMeasurementSession'),
  DESTROY_BY_ID_MEASUREMENTSESSION_SUCCESS: type('[Worker] destroyByIdMeasurementSession success'),
  DESTROY_BY_ID_MEASUREMENTSESSION_FAIL: type('[Worker] destroyByIdMeasurementSession fail'),

  UPDATE_BY_ID_MEASUREMENTSESSION: type('[Worker] updateByIdMeasurementSession'),
  UPDATE_BY_ID_MEASUREMENTSESSION_SUCCESS: type('[Worker] updateByIdMeasurementSession success'),
  UPDATE_BY_ID_MEASUREMENTSESSION_FAIL: type('[Worker] updateByIdMeasurementSession fail'),

  LINK_MEASUREMENTSESSION: type('[Worker] linkMeasurementSession'),
  LINK_MEASUREMENTSESSION_SUCCESS: type('[Worker] linkMeasurementSession success'),
  LINK_MEASUREMENTSESSION_FAIL: type('[Worker] linkMeasurementSession fail'),

  UNLINK_MEASUREMENTSESSION: type('[Worker] unlinkMeasurementSession'),
  UNLINK_MEASUREMENTSESSION_SUCCESS: type('[Worker] unlinkMeasurementSession success'),
  UNLINK_MEASUREMENTSESSION_FAIL: type('[Worker] unlinkMeasurementSession fail'),

  GET_MEASUREMENTSESSION: type('[Worker] getMeasurementSession'),
  GET_MEASUREMENTSESSION_SUCCESS: type('[Worker] getMeasurementSession success'),
  GET_MEASUREMENTSESSION_FAIL: type('[Worker] getMeasurementSession fail'),

  CREATE_MEASUREMENTSESSION: type('[Worker] createMeasurementSession'),
  CREATE_MEASUREMENTSESSION_SUCCESS: type('[Worker] createMeasurementSession success'),
  CREATE_MEASUREMENTSESSION_FAIL: type('[Worker] createMeasurementSession fail'),

  DELETE_MEASUREMENTSESSION: type('[Worker] deleteMeasurementSession'),
  DELETE_MEASUREMENTSESSION_SUCCESS: type('[Worker] deleteMeasurementSession success'),
  DELETE_MEASUREMENTSESSION_FAIL: type('[Worker] deleteMeasurementSession fail'),

  CREATE_MANY_MEASUREMENTSESSION: type('[Worker] createManyMeasurementSession'),
  CREATE_MANY_MEASUREMENTSESSION_SUCCESS: type('[Worker] createManyMeasurementSession success'),
  CREATE_MANY_MEASUREMENTSESSION_FAIL: type('[Worker] createManyMeasurementSession fail'),

});
export const WorkerActions =
Object.assign(BaseLoopbackActionsFactory<Worker>(WorkerActionTypes), {

  /**
   * findByIdMeasurementSession Action.
   * Find a related item by id for measurementSession.
   *
   * @param {any} id Worker id
   * @param {any} fk Foreign key for measurementSession
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.FIND_BY_ID_MEASUREMENTSESSION;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.FIND_BY_ID_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.FIND_BY_ID_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdMeasurementSession Action.
   * Delete a related item by id for measurementSession.
   *
   * @param {any} id Worker id
   * @param {any} fk Foreign key for measurementSession
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.DESTROY_BY_ID_MEASUREMENTSESSION;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.DESTROY_BY_ID_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.DESTROY_BY_ID_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdMeasurementSession Action.
   * Update a related item by id for measurementSession.
   *
   * @param {any} id Worker id
   * @param {any} fk Foreign key for measurementSession
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.UPDATE_BY_ID_MEASUREMENTSESSION;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.UPDATE_BY_ID_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.UPDATE_BY_ID_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkMeasurementSession Action.
   * Add a related item by id for measurementSession.
   *
   * @param {any} id Worker id
   * @param {any} fk Foreign key for measurementSession
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.LINK_MEASUREMENTSESSION;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.LINK_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.LINK_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkMeasurementSession Action.
   * Remove the measurementSession relation to an item by id.
   *
   * @param {any} id Worker id
   * @param {any} fk Foreign key for measurementSession
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.UNLINK_MEASUREMENTSESSION;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.UNLINK_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.UNLINK_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementSession Action.
   * Queries measurementSession of Worker.
   *
   * @param {any} id Worker id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.GET_MEASUREMENTSESSION;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.GET_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.GET_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createMeasurementSession Action.
   * Creates a new instance in measurementSession of this model.
   *
   * @param {any} id Worker id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MEASUREMENTSESSION;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteMeasurementSession Action.
   * Deletes all measurementSession of this model.
   *
   * @param {any} id Worker id
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.DELETE_MEASUREMENTSESSION;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.DELETE_MEASUREMENTSESSION_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.DELETE_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyMeasurementSession Action.
   * Creates a new instance in measurementSession of this model.
   *
   * @param {any} id Worker id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementSession: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MANY_MEASUREMENTSESSION;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementSessionSuccess: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MANY_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementSessionFail: class implements Action {
    public readonly type = WorkerActionTypes.CREATE_MANY_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});