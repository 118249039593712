/* tslint:disable */
import { SDKToken, LoopbackAction } from '../models/BaseModels';
import { LoopbackAuthActionTypes } from '../actions/auth';
import { AppUserActionTypes } from '../actions/AppUser';

const initialState: SDKToken = {
  id: null,
  ttl: null,
  scopes: null,
  created: null,
  userId: null,
  user: null,
  roles: [],
  progress: null,
  error: null,
  rememberMe: null
};

/**
 * @module LoopbackAuthReducer
 * @author João Ribeiro <@JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible authentication reducer.
 */
export function LoopbackAuthReducer(state = initialState, action: LoopbackAction): SDKToken {
  switch (action.type) {
    case AppUserActionTypes.LOGIN_SUCCESS:
    case LoopbackAuthActionTypes.SET_TOKEN:
    case LoopbackAuthActionTypes.LOAD_TOKEN_SUCCESS: {
      const token: SDKToken = action.payload;

      return Object.assign({}, token);
    }

    case AppUserActionTypes.LOGIN_FAIL: {
      const error: any = action.payload;

      let updateState = Object.assign({}, state);
      updateState.error = error;

      return updateState;
    }

    case LoopbackAuthActionTypes.SET_USER: {
      const user: any = action.payload;
      let updateState = Object.assign({}, state);

      updateState.user = user;
      updateState.userId = user.id;

      return updateState;
    }

    case AppUserActionTypes.LOGOUT_SUCCESS: {
      return Object.assign({}, initialState);
    }
    case AppUserActionTypes.LOGOUT_FAIL: {
      return Object.assign({}, initialState);
    }

    case LoopbackAuthActionTypes.UPDATE_USER_PROPERTIES_SUCCESS:
    case LoopbackAuthActionTypes.UPDATE_USER_STATE_SUCCESS: {
      const userProperties: any = action.payload;
      let updateState = Object.assign({}, state);

      updateState.user = Object.assign({}, updateState.user, userProperties);

      return updateState;
    }

    default: {
      return state;
    }
  }
}

export const getLoopbackAuthState = (state: any) => state.LoopbackAuth;
export const getLoopbackAuthToken = (state: any) => state.LoopbackAuth;
export const getLoopbackAuthAccount = (state: any) => state.LoopbackAuth.user;
export const getLoopbackAuthAccountId = (state: any) => state.LoopbackAuth.userId;
