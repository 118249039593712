/* tslint:disable */
import {
  Event
} from '../index';

declare var Object: any;
export interface BookingSlotInterface {
  "hour"?: number;
  "minute"?: number;
  "duration"?: number;
  "start"?: string;
  "end"?: string;
  "id"?: number;
  "eventId"?: number;
  event?: Event;
}

export class BookingSlot implements BookingSlotInterface {
  "hour": number;
  "minute": number;
  "duration": number;
  "start": string;
  "end": string;
  "id": number;
  "eventId": number;
  event: Event;
  constructor(data?: BookingSlotInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BookingSlot`.
   */
  public static getModelName() {
    return "BookingSlot";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BookingSlot for dynamic purposes.
  **/
  public static factory(data: BookingSlotInterface): BookingSlot{
    return new BookingSlot(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BookingSlot',
      plural: 'BookingSlots',
      path: 'BookingSlots',
      idName: 'id',
      properties: {
        "hour": {
          name: 'hour',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "minute": {
          name: 'minute',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "duration": {
          name: 'duration',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "start": {
          name: 'start',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "end": {
          name: 'end',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
      }
    }
  }
}
