/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, MeasurementData } from '../models';

export const MeasurementDataActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('MeasurementData'), {
});
export const MeasurementDataActions =
Object.assign(BaseLoopbackActionsFactory<MeasurementData>(MeasurementDataActionTypes), {
});