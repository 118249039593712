/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, FeetScan } from '../models';

export const FeetScanActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('FeetScan'), {
  GET_APPUSER: type('[FeetScan] getAppUser'),
  GET_APPUSER_SUCCESS: type('[FeetScan] getAppUser success'),
  GET_APPUSER_FAIL: type('[FeetScan] getAppUser fail'),

  GET_GENDER: type('[FeetScan] getGender'),
  GET_GENDER_SUCCESS: type('[FeetScan] getGender success'),
  GET_GENDER_FAIL: type('[FeetScan] getGender fail'),

  GET_EVENT: type('[FeetScan] getEvent'),
  GET_EVENT_SUCCESS: type('[FeetScan] getEvent success'),
  GET_EVENT_FAIL: type('[FeetScan] getEvent fail'),

  GET_SCANNER: type('[FeetScan] getScanner'),
  GET_SCANNER_SUCCESS: type('[FeetScan] getScanner success'),
  GET_SCANNER_FAIL: type('[FeetScan] getScanner fail'),

  IMPORT: type('[FeetScan] import'),
  IMPORT_SUCCESS: type('[FeetScan] import success'),
  IMPORT_FAIL: type('[FeetScan] import fail'),

  FILE: type('[FeetScan] file'),
  FILE_SUCCESS: type('[FeetScan] file success'),
  FILE_FAIL: type('[FeetScan] file fail'),

});
export const FeetScanActions =
Object.assign(BaseLoopbackActionsFactory<FeetScan>(FeetScanActionTypes), {

  /**
   * getAppUser Action.
   * Fetches belongsTo relation appUser.
   *
   * @param {any} id FeetScan id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getAppUser: class implements Action {
    public readonly type = FeetScanActionTypes.GET_APPUSER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getAppUserSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getAppUserSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.GET_APPUSER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getAppUserFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getAppUserFail: class implements Action {
    public readonly type = FeetScanActionTypes.GET_APPUSER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getGender Action.
   * Fetches belongsTo relation gender.
   *
   * @param {any} id FeetScan id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getGender: class implements Action {
    public readonly type = FeetScanActionTypes.GET_GENDER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getGenderSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getGenderSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.GET_GENDER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getGenderFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getGenderFail: class implements Action {
    public readonly type = FeetScanActionTypes.GET_GENDER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getEvent Action.
   * Fetches belongsTo relation event.
   *
   * @param {any} id FeetScan id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getEvent: class implements Action {
    public readonly type = FeetScanActionTypes.GET_EVENT;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getEventSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getEventSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.GET_EVENT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getEventFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getEventFail: class implements Action {
    public readonly type = FeetScanActionTypes.GET_EVENT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getScanner Action.
   * Fetches belongsTo relation scanner.
   *
   * @param {any} id FeetScan id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getScanner: class implements Action {
    public readonly type = FeetScanActionTypes.GET_SCANNER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getScannerSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getScannerSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.GET_SCANNER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getScannerFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getScannerFail: class implements Action {
    public readonly type = FeetScanActionTypes.GET_SCANNER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * import Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   *
   *  - `res` – `{object}` - 
   *
   *  - `type` – `{string}` - 
   * @param {any} meta (optional).
   * 
   */
  import: class implements Action {
    public readonly type = FeetScanActionTypes.IMPORT;
      public payload: {req: any, res: any, type: any, customHeaders};

    constructor(req: any = {}, res: any = {}, type: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {req, res, type, customHeaders};
    }
  },
  /**
   * importSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  importSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.IMPORT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * importFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  importFail: class implements Action {
    public readonly type = FeetScanActionTypes.IMPORT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * file Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} req 
   * @param {object} res 
   * @param {string} folder 
   * @param {string} key 
   * @param {any} meta (optional).
   * 
   */
  file: class implements Action {
    public readonly type = FeetScanActionTypes.FILE;
      public payload: {req: any, res: any, folder: any, key: any, customHeaders};

    constructor(req: any = {}, res: any = {}, folder: any = {}, key: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {req, res, folder, key, customHeaders};
    }
  },
  /**
   * fileSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  fileSuccess: class implements Action {
    public readonly type = FeetScanActionTypes.FILE_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * fileFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  fileFail: class implements Action {
    public readonly type = FeetScanActionTypes.FILE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});