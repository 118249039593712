/* tslint:disable */
import {
  Midsole
} from '../index';

declare var Object: any;
export interface CustomMidsoleInterface {
  "arch"?: string;
  "fit"?: number;
  "hardness": number;
  "memo"?: string;
  "id"?: number;
  "masterId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  master?: Midsole;
}

export class CustomMidsole implements CustomMidsoleInterface {
  "arch": string;
  "fit": number;
  "hardness": number;
  "memo": string;
  "id": number;
  "masterId": number;
  "createdAt": Date;
  "updatedAt": Date;
  master: Midsole;
  constructor(data?: CustomMidsoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CustomMidsole`.
   */
  public static getModelName() {
    return "CustomMidsole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CustomMidsole for dynamic purposes.
  **/
  public static factory(data: CustomMidsoleInterface): CustomMidsole{
    return new CustomMidsole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CustomMidsole',
      plural: 'CustomMidsoles',
      path: 'CustomMidsoles',
      idName: 'id',
      properties: {
        "arch": {
          name: 'arch',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "fit": {
          name: 'fit',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "hardness": {
          name: 'hardness',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "memo": {
          name: 'memo',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "masterId": {
          name: 'masterId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
        master: {
          name: 'master',
          type: 'Midsole',
          model: 'Midsole',
          relationType: 'belongsTo',
                      keyFrom: 'masterId',
          keyTo: 'id'
        },
      }
    }
  }
}
