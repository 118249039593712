import {AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ConfigService} from '../../shared/services/config.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {UiService} from '../../shared/services/ui.service';
import {AppUserApi, EventApi, MeasurementSessionApi} from '../../shared/lb-sdk/services/custom';
import {TranslateService} from '@ngx-translate/core';
import {LocalApiService} from '../../shared/services/local-api.service';

import {interval} from 'rxjs/internal/observable/interval';
import {startWith, switchMap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {getLoopbackAuthAccount, getLoopbackAuthToken} from '../../shared/lb-sdk/reducers';
import {Store} from '@ngrx/store';
import 'rxjs-compat/add/operator/filter';
import {ArchitectService} from '../../shared/services/architect.service';
import {ApiExService} from '../../shared/services/api-ex.service';

@Component({
    selector: 'app-full-screen',
    templateUrl: './full-screen.component.html',
    styleUrls: ['./full-screen.component.scss']
})
export class FullScreenComponent implements OnInit, AfterViewInit {
    public config: any = {};
    direction: 'ltr';
    @ViewChild('content-wrapper', {static: false}) wrapper: ElementRef;

    pageTitleDefault = 'QUANT-U';
    pageTitle = this.pageTitleDefault;

    canGoBack = false;

    homeLink = '/architect/overview';
    backLink: string;

    toolsLink = '/architect/tools';
    settingsLink = '/architect/settings';

    constructor(private configService: ConfigService,
                private router: Router,
                private translate: TranslateService,
                public ui: UiService,
                private eventApi: EventApi,
                private store: Store<any>,
                private localApi: LocalApiService,
                private activatedRoute: ActivatedRoute,
                private architectService: ArchitectService,
                private userApi: AppUserApi,
                private apiEx: ApiExService,
                private sessionApi: MeasurementSessionApi,
                @Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2) {

        // qfix for salesperson
        translate.use('en');

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data) {
                        return child.snapshot.data;
                    } else {
                        return null;
                    }
                }
                return null;
            })
            .subscribe((data: any) => {

                this.architectService.loadWorkingEvent(() => {
                    this.architectService.loadWorkingGroup({}, () => {
                        this.architectService.loadWorkingUser(() => {
                            this.checkIfSessionExists();
                            this.updateEventState();
                        });
                    });
                });

                // page title

                if (data) {
                    this.pageTitle = data.title ? data.title : this.pageTitleDefault;
                }

                // back button qfix

                this.canGoBack = !this.router.url.startsWith(this.homeLink);

                if (this.canGoBack) {
                    if (this.router.url.indexOf('search/') !== -1) {
                        this.backLink = '/architect/search'
                    } else if (this.router.url.indexOf('tools/') !== -1) {
                        this.backLink = '/architect/tools'
                    } else {
                        this.backLink = this.homeLink;
                    }
                }
            });
    }

    updateEventState() {
        if (this.ui.workingEvent) {
            // console.log(this.ui.workingEvent);

            if (!this.ui.workingEvent.state) {
                this.ui.workingEvent.state = {};
            }

            if (this.ui.workingEvent.state.userId != this.ui.workingUserId
                || !this.ui.workingUser && this.ui.workingEvent.state.sessionId
                || this.ui.workingUser && this.ui.workingEvent.state.sessionId != this.ui.workingUser.workingSessionId) {

                this.ui.workingEvent.state.userId = this.ui.workingUserId ? +this.ui.workingUserId : null;
                this.ui.workingEvent.state.sessionId = this.ui.workingUser ? +this.ui.workingUser.workingSessionId : null;

                this.apiEx.updateAttributesEx(this.eventApi.getModelName(), this.ui.workingEvent.id,
                    {
                        state: this.ui.workingEvent.state,
                    })
                    .subscribe((_event: any) => {
                        // console.log(_event);
                    });
            }
        }
    }

    checkIfSessionExists() {
        //console.log(this.ui.workingUser);

        if (!this.ui.workingUser || this.ui.workingUser.workingSessionId) {
            return;
        }

        //console.log('gonna create session!');

        this.sessionApi.create({
            appUserId: this.ui.workingUserId,
            eventId: this.ui.workingEventId,
        }).subscribe((_session: any) => {

            //console.log(_session);

            this.apiEx.updateAttributesEx(this.userApi.getModelName(), this.ui.workingUserId,
                {
                    workingSessionId: _session.id,
                })
                .subscribe((_user: any) => {
                    this.architectService.updateUserFromCloud(() => {
                        this.updateEventState();
                    });
                });
        });

    }

    ngOnInit() {
        // theme config
        this.config = this.configService.templateConf;

        // cloud api poll
        interval(5000).subscribe(() => {
            if (this.ui.workingUserId) {
                this.architectService.updateUserFromCloud();
            }
        });

        // local api setup qfix (should use it's own login data)
        this.store.select(getLoopbackAuthToken).subscribe((_token: any) => {
            if (this.ui.localConfig.apiEnabled) {
                this.localApi.shareAuthKey(_token.id).subscribe();
            }
        });

    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.config.layout.dir) {
                this.direction = this.config.layout.dir;
            }

            if (this.config.layout.variant === 'dark') {
                this.renderer.addClass(this.document.body, 'layout-dark');
            } else if (this.config.layout.variant === 'light') {
                this.renderer.addClass(this.document.body, 'layout-light');
            } else if (this.config.layout.variant === 'Transparent') {
                this.renderer.addClass(this.document.body, 'layout-dark');
                this.renderer.addClass(this.document.body, 'layout-transparent');
                if (this.config.layout.sidebar.backgroundColor) {
                    this.renderer.addClass(this.document.body, this.config.layout.sidebar.backgroundColor);
                } else {
                    this.renderer.addClass(this.document.body, 'bg-glass-1');
                }
            }
        }, 0);

    }


    onBack() {
        this.router.navigate([this.backLink]);
    }

    goHome() {
        this.router.navigate([this.homeLink]);
    }
}
