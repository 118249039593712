/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Booking } from '../models';

export const BookingActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Booking'), {
  AVAILABLE: type('[Booking] available'),
  AVAILABLE_SUCCESS: type('[Booking] available success'),
  AVAILABLE_FAIL: type('[Booking] available fail'),

  RESERVED: type('[Booking] reserved'),
  RESERVED_SUCCESS: type('[Booking] reserved success'),
  RESERVED_FAIL: type('[Booking] reserved fail'),

  REBOOK: type('[Booking] rebook'),
  REBOOK_SUCCESS: type('[Booking] rebook success'),
  REBOOK_FAIL: type('[Booking] rebook fail'),

  VIEW: type('[Booking] view'),
  VIEW_SUCCESS: type('[Booking] view success'),
  VIEW_FAIL: type('[Booking] view fail'),

  CANCEL: type('[Booking] cancel'),
  CANCEL_SUCCESS: type('[Booking] cancel success'),
  CANCEL_FAIL: type('[Booking] cancel fail'),

});
export const BookingActions =
Object.assign(BaseLoopbackActionsFactory<Booking>(BookingActionTypes), {

  /**
   * available Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} id 
   * @param {any} meta (optional).
   * 
   */
  available: class implements Action {
    public readonly type = BookingActionTypes.AVAILABLE;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * availableSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  availableSuccess: class implements Action {
    public readonly type = BookingActionTypes.AVAILABLE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * availableFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  availableFail: class implements Action {
    public readonly type = BookingActionTypes.AVAILABLE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * reserved Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   * @param {any} meta (optional).
   * 
   */
  reserved: class implements Action {
    public readonly type = BookingActionTypes.RESERVED;
      
    constructor(public meta?: any) {}
  },
  /**
   * reservedSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  reservedSuccess: class implements Action {
    public readonly type = BookingActionTypes.RESERVED_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * reservedFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  reservedFail: class implements Action {
    public readonly type = BookingActionTypes.RESERVED_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * rebook Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `req` – `{object}` - 
   * @param {any} meta (optional).
   * 
   */
  rebook: class implements Action {
    public readonly type = BookingActionTypes.REBOOK;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * rebookSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  rebookSuccess: class implements Action {
    public readonly type = BookingActionTypes.REBOOK_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * rebookFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  rebookFail: class implements Action {
    public readonly type = BookingActionTypes.REBOOK_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * view Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} hash 
   * @param {any} meta (optional).
   * 
   */
  view: class implements Action {
    public readonly type = BookingActionTypes.VIEW;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * viewSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  viewSuccess: class implements Action {
    public readonly type = BookingActionTypes.VIEW_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * viewFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  viewFail: class implements Action {
    public readonly type = BookingActionTypes.VIEW_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * cancel Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} hash 
   * @param {any} meta (optional).
   * 
   */
  cancel: class implements Action {
    public readonly type = BookingActionTypes.CANCEL;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * cancelSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  cancelSuccess: class implements Action {
    public readonly type = BookingActionTypes.CANCEL_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * cancelFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  cancelFail: class implements Action {
    public readonly type = BookingActionTypes.CANCEL_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});