/* tslint:disable */

declare var Object: any;
export interface BookingInterface {
  "start"?: Date;
  "duration"?: number;
  "hash"?: string;
  "reserved"?: boolean;
  "id"?: number;
  "appUserId"?: number;
  "productId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "eventId"?: number;
  "slotId"?: number;
  appUser?: any;
  product?: any;
  event?: any;
  slot?: any;
}

export class Booking implements BookingInterface {
  "start": Date;
  "duration": number;
  "hash": string;
  "reserved": boolean;
  "id": number;
  "appUserId": number;
  "productId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "eventId": number;
  "slotId": number;
  appUser: any;
  product: any;
  event: any;
  slot: any;
  constructor(data?: BookingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Booking`.
   */
  public static getModelName() {
    return "Booking";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Booking for dynamic purposes.
  **/
  public static factory(data: BookingInterface): Booking{
    return new Booking(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Booking',
      plural: 'Bookings',
      path: 'Bookings',
      idName: 'id',
      properties: {
        "start": {
          name: 'start',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "duration": {
          name: 'duration',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "hash": {
          name: 'hash',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "reserved": {
          name: 'reserved',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "productId": {
          name: 'productId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "slotId": {
          name: 'slotId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'appUserId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'productId',
          keyTo: 'id'
        },
        event: {
          name: 'event',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
        slot: {
          name: 'slot',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'slotId',
          keyTo: 'id'
        },
      }
    }
  }
}
