import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MeasurementService} from '../../services/measurement.service';
import {FeetScan} from '../../lb-sdk/models';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-feet-3d-volumental',
    templateUrl: './feet-3d-volumental.component.html',
    styleUrls: ['./feet-3d-volumental.component.scss']
})
export class Feet3dVolumentalComponent implements OnInit, OnChanges {

    @Input() userId: number;
    @Input() scanUuid: string;
    @Input() scanId: string;

    volumentalUri: any;

    constructor(
        private measurementService: MeasurementService,
        private sanitizer: DomSanitizer
    ) {

    }

    buildUri(uuid: string) {
        if (!uuid) {
            this.volumentalUri = '';
            return;
            uuid = '1a8f5a4e-ccb0-436a-9898-5fc93c52910f';
        }

        let uri = 'https://my.volumental.com/en/ecco/' + uuid + '/';
        this.volumentalUri = this.sanitizer.bypassSecurityTrustResourceUrl(uri);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userId) {
            if (changes.userId.currentValue/* && changes.userId.isFirstChange()*/) {
                this.measurementService.getScanForUser(this.userId, (err: Error, scan: FeetScan) => {
                    //scan = new FeetScan(); // debug
                    //scan.folder = '97fc2863-f54e-48c1-a2d3-ac0d4710e5e7';

                    if (scan) {
                        this.buildUri(scan.uuid);
                    }
                    else
                        this.buildUri(null); // qfix, need to add fake versions for all sizes and genders
                });
            }
        }

        if (changes.scanId) // TODO !!!!!!!!!!!!!
        {
            if (changes.scanId.currentValue && changes.scanId.isFirstChange()) {
                this.measurementService.getScanForUser(102, (err: Error, scan: FeetScan) => {
                    //scan = new FeetScan(); // debug
                    //scan.folder = '97fc2863-f54e-48c1-a2d3-ac0d4710e5e7';

                    if (scan) {
                        this.buildUri(scan.uuid);
                    }
                });
            }
        }

        if (changes.scanUuid && changes.scanUuid.currentValue && changes.scanUuid.isFirstChange()) {
            this.buildUri(changes.scanUuid.currentValue);
        }

    }

    ngOnInit() {
    }

}
