/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementDataNorm, MeasurementDataNormInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementDataNormActionTypes } from '../actions';

export interface MeasurementDataNormsState extends EntityState<MeasurementDataNorm | MeasurementDataNormInterface> {};

export const MeasurementDataNormAdapter: EntityAdapter<MeasurementDataNorm | MeasurementDataNormInterface> = createEntityAdapter<MeasurementDataNorm | MeasurementDataNormInterface>();

const initialState: MeasurementDataNormsState = MeasurementDataNormAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementDataNormsState, MeasurementDataNorm | MeasurementDataNormInterface>(MeasurementDataNormActionTypes, MeasurementDataNormAdapter);

/**
 * @module MeasurementDataNormsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementDataNorm reducer.
 */
export function MeasurementDataNormsReducer(state = initialState, action: LoopbackAction): MeasurementDataNormsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementDataNormsState = (state: any) => state.MeasurementDataNorms;
export const getMeasurementDataNormsEntities = (state: any) => state.MeasurementDataNorms.entities;
export const getMeasurementDataNormsIds = (state: any) => state.MeasurementDataNorms.ids;

export const getMeasurementDataNorms =
  createSelector(getMeasurementDataNormsEntities, getMeasurementDataNormsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementDataNormById(id: string) {
  return (state: any) => state.MeasurementDataNorms.entities[id];
}

export function getMeasurementDataNormsById(ids: string[]) {
  return createSelector(getMeasurementDataNormsEntities, (entities) => ids.map((id) => entities[id]));
}
