/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Scanner } from '../models';

export const ScannerActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Scanner'), {
  GET_EVENT: type('[Scanner] getEvent'),
  GET_EVENT_SUCCESS: type('[Scanner] getEvent success'),
  GET_EVENT_FAIL: type('[Scanner] getEvent fail'),

  GET_LOCATION: type('[Scanner] getLocation'),
  GET_LOCATION_SUCCESS: type('[Scanner] getLocation success'),
  GET_LOCATION_FAIL: type('[Scanner] getLocation fail'),

});
export const ScannerActions =
Object.assign(BaseLoopbackActionsFactory<Scanner>(ScannerActionTypes), {

  /**
   * getEvent Action.
   * Fetches belongsTo relation event.
   *
   * @param {any} id Scanner id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getEvent: class implements Action {
    public readonly type = ScannerActionTypes.GET_EVENT;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getEventSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getEventSuccess: class implements Action {
    public readonly type = ScannerActionTypes.GET_EVENT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getEventFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getEventFail: class implements Action {
    public readonly type = ScannerActionTypes.GET_EVENT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getLocation Action.
   * Fetches belongsTo relation location.
   *
   * @param {any} id Scanner id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getLocation: class implements Action {
    public readonly type = ScannerActionTypes.GET_LOCATION;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getLocationSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getLocationSuccess: class implements Action {
    public readonly type = ScannerActionTypes.GET_LOCATION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getLocationFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getLocationFail: class implements Action {
    public readonly type = ScannerActionTypes.GET_LOCATION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});