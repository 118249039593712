/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { FeatureDataSeries, FeatureDataSeriesInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { FeatureDataSeriesActionTypes } from '../actions';

export interface FeatureDataSeriessState extends EntityState<FeatureDataSeries | FeatureDataSeriesInterface> {};

export const FeatureDataSeriesAdapter: EntityAdapter<FeatureDataSeries | FeatureDataSeriesInterface> = createEntityAdapter<FeatureDataSeries | FeatureDataSeriesInterface>();

const initialState: FeatureDataSeriessState = FeatureDataSeriesAdapter.getInitialState({});

const cases = BaseReducerFactory<FeatureDataSeriessState, FeatureDataSeries | FeatureDataSeriesInterface>(FeatureDataSeriesActionTypes, FeatureDataSeriesAdapter);

/**
 * @module FeatureDataSeriessReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible FeatureDataSeries reducer.
 */
export function FeatureDataSeriessReducer(state = initialState, action: LoopbackAction): FeatureDataSeriessState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getFeatureDataSeriessState = (state: any) => state.FeatureDataSeriess;
export const getFeatureDataSeriessEntities = (state: any) => state.FeatureDataSeriess.entities;
export const getFeatureDataSeriessIds = (state: any) => state.FeatureDataSeriess.ids;

export const getFeatureDataSeriess =
  createSelector(getFeatureDataSeriessEntities, getFeatureDataSeriessIds, (entities, ids) => ids.map((id) => entities[id]));

export function getFeatureDataSeriesById(id: string) {
  return (state: any) => state.FeatureDataSeriess.entities[id];
}

export function getFeatureDataSeriessById(ids: string[]) {
  return createSelector(getFeatureDataSeriessEntities, (entities) => ids.map((id) => entities[id]));
}
