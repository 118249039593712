/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { EventStatus, EventStatusInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { EventStatusActionTypes } from '../actions';

export interface EventStatussState extends EntityState<EventStatus | EventStatusInterface> {};

export const EventStatusAdapter: EntityAdapter<EventStatus | EventStatusInterface> = createEntityAdapter<EventStatus | EventStatusInterface>();

const initialState: EventStatussState = EventStatusAdapter.getInitialState({});

const cases = BaseReducerFactory<EventStatussState, EventStatus | EventStatusInterface>(EventStatusActionTypes, EventStatusAdapter);

/**
 * @module EventStatussReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible EventStatus reducer.
 */
export function EventStatussReducer(state = initialState, action: LoopbackAction): EventStatussState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getEventStatussState = (state: any) => state.EventStatuss;
export const getEventStatussEntities = (state: any) => state.EventStatuss.entities;
export const getEventStatussIds = (state: any) => state.EventStatuss.ids;

export const getEventStatuss =
  createSelector(getEventStatussEntities, getEventStatussIds, (entities, ids) => ids.map((id) => entities[id]));

export function getEventStatusById(id: string) {
  return (state: any) => state.EventStatuss.entities[id];
}

export function getEventStatussById(ids: string[]) {
  return createSelector(getEventStatussEntities, (entities) => ids.map((id) => entities[id]));
}
