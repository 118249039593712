import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '../lb-sdk/guards';

// Max height & width - 100%

export const FullScreen_Routes: Routes = [
    {
        path: 'architect',
        canActivate: [AuthGuard],
        loadChildren: () => import('../../architect/architect.module').then(m => m.ArchitectModule)
    },
];
