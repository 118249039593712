import {Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {getLoopbackAuthAccount} from '../../shared/lb-sdk/reducers';
import {AppUserActions} from '../../shared/lb-sdk/actions';
import {NgForm} from '@angular/forms';
import {UiService} from '../services/ui.service';

@Component({
    selector: 'app-profile-page',
    templateUrl: './profile-page.component.html',
    styleUrls: ['./profile-page.component.scss']
})

export class ProfilePageComponent implements OnInit {

    _user: any = {};
    isEdit: boolean = false;

    @ViewChild('f', {static: false}) _form: NgForm;

    constructor(private store: Store<any>,
                public ui: UiService) {
        this.store.select(getLoopbackAuthAccount).subscribe((data: any) => {
            this._user = data;
            //console.log(data);
        });
    }

    ngOnInit() {
        this.isEdit = false;
    }

    onEdit() {
        this.isEdit = true;
    }

    onSave() {
        let data = this._form.value;

        if (data.inputPassNew != '' && data.inputPassConfirm != '' && data.inputPassNew == data.inputPassConfirm) {

            this.store.dispatch(new AppUserActions.updateProfile({
                name: '',
                password: data.inputPassNew
            }))
        }

        this.isEdit = false;
    }

    onCancel() {
        this.isEdit = false;
    }

    onLogout() {
        this.store.dispatch(new AppUserActions.logout({}));
    }

}
