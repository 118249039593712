/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Gender } from '../models';

export const GenderActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Gender'), {
});
export const GenderActions =
Object.assign(BaseLoopbackActionsFactory<Gender>(GenderActionTypes), {
});