import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MeasurementService} from "../../services/measurement.service";
import {MeasurementCode} from "../../enums/measurement.code";

import * as _ from "lodash";
import {groupBy, GroupResult} from "@progress/kendo-data-query";
import {LineStyle, MarkersVisualArgs, PlotBand} from "@progress/kendo-angular-charts";
import {TranslateService} from "@ngx-translate/core";
import {ChartService} from "../../services/chart.service";

@Component({
    selector: 'app-session-chart',
    templateUrl: './session-chart.component.html',
    styleUrls: ['./session-chart.component.scss']
})
export class SessionChartComponent implements OnChanges {

    @Input() field: any;
    @Input() data: any;
    @Input() isMobile: boolean = false;
    @Input() lineStyle: LineStyle;

    series: GroupResult[];

    seriesLabels = {
        1: this.translate.instant('Left'),
        2: this.translate.instant('Right')
    };

    constructor(public chartUi: ChartService,
                public translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue /*&& changes.data.isFirstChange() - had to remove as when component is nested it won't work with it */) {
            // Filter & sort
            let dataSorted = _.sortBy(_.filter(this.data, d => d.measurementParameterId == this.field.code && d.variation == this.field.variation), 'timeOffset');

            // Split by sensors and sort by sensor id (left should be first and yellow)
            this.series = _.sortBy(groupBy(dataSorted, [{field: "sensorId"}]), 'value');
        }
    }
}

