/* tslint:disable */
import {
  Event,
  Location
} from '../index';

declare var Object: any;
export interface ScannerInterface {
  "name"?: string;
  "stat"?: any;
  "id"?: number;
  "eventId"?: number;
  "locationId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  event?: Event;
  location?: Location;
}

export class Scanner implements ScannerInterface {
  "name": string;
  "stat": any;
  "id": number;
  "eventId": number;
  "locationId": number;
  "createdAt": Date;
  "updatedAt": Date;
  event: Event;
  location: Location;
  constructor(data?: ScannerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Scanner`.
   */
  public static getModelName() {
    return "Scanner";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Scanner for dynamic purposes.
  **/
  public static factory(data: ScannerInterface): Scanner{
    return new Scanner(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Scanner',
      plural: 'Scanners',
      path: 'Scanners',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "stat": {
          name: 'stat',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "locationId": {
          name: 'locationId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
        location: {
          name: 'location',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                      keyFrom: 'locationId',
          keyTo: 'id'
        },
      }
    }
  }
}
