import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ToggleFullscreenDirective} from './directives/toggle-fullscreen.directive';
import {ProfilePageComponent} from './profile/profile-page.component';

import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {GendersResolver} from './resolvers/genders.resolver';

import {MeasurementService} from './services/measurement.service';
// import {Feet3dComponent} from './ui/feet-3d/feet-3d.component';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SessionDataComponent} from './ui/session-data/session-data.component';
import {SessionChartComponent} from './ui/session-chart/session-chart.component';
import {ChartsModule} from '@progress/kendo-angular-charts';
import {UiService} from './services/ui.service';
import {GaitChartComponent} from './ui/gait-chart/gait-chart.component';
import {ChartService} from './services/chart.service';
import {FileUploaderComponent} from './ui/file-uploader/file-uploader.component';
import {UploadModule} from '@progress/kendo-angular-upload';
import {ContentCardComponent} from './ui/content-cards/content-card/content-card.component';
import {ContentCardLrComponent} from './ui/content-cards/content-card-lr/content-card-lr.component';
import {ContentCardLRGaugesComponent} from './ui/content-cards/content-card-lrgauges/content-card-lrgauges.component';

import {GaugesModule} from 'pt-kendo-angular-gauges';
import {SessionDataStepsComponent} from './ui/session-data-steps/session-data-steps.component';
import {SessionChartStepsComponent} from './ui/session-chart-steps/session-chart-steps.component';
import {Feet3dVolumentalComponent} from './ui/feet-3d-volumental/feet-3d-volumental.component';
import {GridRestDataDirective} from './directives/grid-rest-data.directive';
import {GridModule} from '@progress/kendo-angular-grid';
import {BaseEntityListComponent} from './ui/base-entity-list/base-entity-list.component';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {BaseEntityEditComponent} from './ui/base-entity-edit/base-entity-edit.component';

import {SidebarDirective} from './directives/sidebar.directive';
import {SidebarLinkDirective} from './directives/sidebarlink.directive';
import {SidebarListDirective} from './directives/sidebarlist.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebaranchortoggle.directive';
import {SidebarToggleDirective} from './directives/sidebartoggle.directive';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {EllipsisPipe} from './directives/ellipsis.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BarMeterComponent} from './ui/charts/bar-meter/bar-meter.component';
import {ApiExService} from './services/api-ex.service';
import {ContentCardCenteredComponent} from './ui/content-cards/content-card-centered/content-card-centered.component';
import {ButtonHexComponent} from './ui/buttons/button-hex/button-hex.component';
import {LocalApiService} from './services/local-api.service';
import {ControlPlusminusComponent} from './ui/control-plusminus/control-plusminus.component';
import {CustomerCardComponent} from './ui/customer-card/customer-card.component';
import {MidsoleSuggestionCardComponent} from './ui/midsole-suggestion-card/midsole-suggestion-card.component';
import {ArchitectService} from './services/architect.service';
import {Feet3dEccoComponent} from './ui/feet-3d-ecco/feet-3d-ecco.component';
import {CustomerCardNoscanComponent} from './ui/customer-card-noscan/customer-card-noscan.component';
import {Scene3dComponent} from './ui/scene3d/scene3d.component';
import {CircularProgressComponent} from './ui/circular-progress/circular-progress.component';
import { InlineParamEditorComponent } from './ui/editors/inline-param-editor/inline-param-editor.component';
import { NoteCardComponent } from './ui/cards/note-card/note-card.component';
import { TextEditorComponent } from './ui/editors/text-editor/text-editor.component';
import { EditorBaseComponent } from './ui/editors/editor-base/editor-base.component';
import { CardBaseComponent } from './ui/cards/card-base/card-base.component';
import { CustomEntityListComponent } from './ui/lists/custom-entity-list/custom-entity-list.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ChartsModule,
        HttpClientModule,
        UploadModule,
        ReactiveFormsModule,
        InputsModule,
        TranslateModule,
        FontAwesomeModule,
        GridModule,
        DialogModule,
        GaugesModule,
        PerfectScrollbarModule
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        ProfilePageComponent,
        TranslateModule,
        InputsModule,
        Feet3dVolumentalComponent,
        Feet3dEccoComponent,
        GaitChartComponent,
        SessionChartComponent,
        SessionDataComponent,
        FileUploaderComponent,
        ContentCardComponent,
        ContentCardCenteredComponent,
        ContentCardLrComponent,
        ContentCardLRGaugesComponent,
        SessionDataStepsComponent,
        GridRestDataDirective,
        BaseEntityListComponent,
        BarMeterComponent,
        ButtonHexComponent,
        ControlPlusminusComponent,
        SidebarDirective,
        CircularProgressComponent,
        CustomerCardComponent,
        CustomerCardNoscanComponent,
        MidsoleSuggestionCardComponent,
        Scene3dComponent,
        CustomEntityListComponent
    ],
    // providers: [
    //     GendersResolver,
    //     MeasurementService,
    //     //UiService,
    //     ChartService
    // ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        ToggleFullscreenDirective,
        ProfilePageComponent,
        SessionChartComponent,
        SessionDataComponent,
        GaitChartComponent,
        FileUploaderComponent,
        ContentCardComponent,
        ContentCardCenteredComponent,
        ContentCardLrComponent,
        ContentCardLRGaugesComponent,
        SessionDataStepsComponent,
        SessionChartStepsComponent,
        Feet3dVolumentalComponent,
        Feet3dEccoComponent,
        GridRestDataDirective,
        BaseEntityListComponent,
        BaseEntityEditComponent,
        EllipsisPipe,
        BarMeterComponent,
        CircularProgressComponent,
        ButtonHexComponent,
        ControlPlusminusComponent,
        CustomerCardComponent,
        CustomerCardNoscanComponent,
        MidsoleSuggestionCardComponent,
        Scene3dComponent,
        CircularProgressComponent,
        InlineParamEditorComponent,
        NoteCardComponent,
        TextEditorComponent,
        EditorBaseComponent,
        CardBaseComponent,
        CustomEntityListComponent
    ],
    entryComponents: [BaseEntityEditComponent]
})
export class SharedModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                GendersResolver,
                MeasurementService,
                UiService,
                ChartService,
                LocalApiService,
                ArchitectService,
                ApiExService
            ]
        };
    }
}
