/* tslint:disable */
import {
  AppUser,
  Location,
  Product,
  Booking,
  Gender,
  EventStatus
} from '../index';

declare var Object: any;
export interface EventInterface {
  "title"?: string;
  "info"?: string;
  "image"?: string;
  "stat"?: any;
  "isAdvertised"?: boolean;
  "isInternal"?: boolean;
  "canBeBooked"?: boolean;
  "id"?: number;
  "locationId"?: number;
  "appUserId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "statusId"?: number;
  managers?: AppUser[];
  location?: Location;
  products?: Product[];
  bookings?: Booking[];
  genders?: Gender[];
  status?: EventStatus;
  state?: any;
}

export class Event implements EventInterface {
  "title": string;
  "info": string;
  "image": string;
  "stat": any;
  "isAdvertised": boolean;
  "isInternal": boolean;
  "canBeBooked": boolean;
  "id": number;
  "locationId": number;
  "appUserId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "statusId": number;
  managers: AppUser[];
  location: Location;
  products: Product[];
  bookings: Booking[];
  genders: Gender[];
  status: EventStatus;
  constructor(data?: EventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Event`.
   */
  public static getModelName() {
    return "Event";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Event for dynamic purposes.
  **/
  public static factory(data: EventInterface): Event{
    return new Event(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Event',
      plural: 'Events',
      path: 'Events',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "info": {
          name: 'info',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "image": {
          name: 'image',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "stat": {
          name: 'stat',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "isAdvertised": {
          name: 'isAdvertised',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "isInternal": {
          name: 'isInternal',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "canBeBooked": {
          name: 'canBeBooked',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "locationId": {
          name: 'locationId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "statusId": {
          name: 'statusId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        managers: {
          name: 'managers',
          type: 'AppUser[]',
          model: 'AppUser',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'eventId'
        },
        location: {
          name: 'location',
          type: 'Location',
          model: 'Location',
          relationType: 'belongsTo',
                      keyFrom: 'locationId',
          keyTo: 'id'
        },
        products: {
          name: 'products',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
              modelThrough: 'EventProduct',
              keyThrough: 'productId',
                      keyFrom: 'id',
          keyTo: 'eventId'
        },
        bookings: {
          name: 'bookings',
          type: 'Booking[]',
          model: 'Booking',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'eventId'
        },
        genders: {
          name: 'genders',
          type: 'Gender[]',
          model: 'Gender',
          relationType: 'hasMany',
              modelThrough: 'EventGender',
              keyThrough: 'genderId',
                      keyFrom: 'id',
          keyTo: 'eventId'
        },
        status: {
          name: 'status',
          type: 'EventStatus',
          model: 'EventStatus',
          relationType: 'belongsTo',
                      keyFrom: 'statusId',
          keyTo: 'id'
        },
      }
    }
  }
}
