/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementDataCompact, MeasurementDataCompactInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementDataCompactActionTypes } from '../actions';

export interface MeasurementDataCompactsState extends EntityState<MeasurementDataCompact | MeasurementDataCompactInterface> {};

export const MeasurementDataCompactAdapter: EntityAdapter<MeasurementDataCompact | MeasurementDataCompactInterface> = createEntityAdapter<MeasurementDataCompact | MeasurementDataCompactInterface>();

const initialState: MeasurementDataCompactsState = MeasurementDataCompactAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementDataCompactsState, MeasurementDataCompact | MeasurementDataCompactInterface>(MeasurementDataCompactActionTypes, MeasurementDataCompactAdapter);

/**
 * @module MeasurementDataCompactsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementDataCompact reducer.
 */
export function MeasurementDataCompactsReducer(state = initialState, action: LoopbackAction): MeasurementDataCompactsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementDataCompactsState = (state: any) => state.MeasurementDataCompacts;
export const getMeasurementDataCompactsEntities = (state: any) => state.MeasurementDataCompacts.entities;
export const getMeasurementDataCompactsIds = (state: any) => state.MeasurementDataCompacts.ids;

export const getMeasurementDataCompacts =
  createSelector(getMeasurementDataCompactsEntities, getMeasurementDataCompactsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementDataCompactById(id: string) {
  return (state: any) => state.MeasurementDataCompacts.entities[id];
}

export function getMeasurementDataCompactsById(ids: string[]) {
  return createSelector(getMeasurementDataCompactsEntities, (entities) => ids.map((id) => entities[id]));
}
