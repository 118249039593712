/* tslint:disable */
import { AuthGuard } from './auth.guard';
import { ProductExistsGuard } from './Product';
import { AppUserExistsGuard } from './AppUser';
import { MeasurementSessionExistsGuard } from './MeasurementSession';
import { MeasurementDataExistsGuard } from './MeasurementData';
import { GenderExistsGuard } from './Gender';
import { MeasurementDataCompactExistsGuard } from './MeasurementDataCompact';
import { FeetScanExistsGuard } from './FeetScan';
import { AttachmentExistsGuard } from './Attachment';
import { MeasurementParameterExistsGuard } from './MeasurementParameter';
import { FeatureExistsGuard } from './Feature';
import { FeatureDataExistsGuard } from './FeatureData';
import { SensorExistsGuard } from './Sensor';
import { PrinterExistsGuard } from './Printer';
import { LocationExistsGuard } from './Location';
import { BookingExistsGuard } from './Booking';
import { EventExistsGuard } from './Event';
import { PrinterTaskExistsGuard } from './PrinterTask';
import { OrderExistsGuard } from './Order';
import { OrderStatusExistsGuard } from './OrderStatus';
import { PrintTaskStatusExistsGuard } from './PrintTaskStatus';
import { BookingStatusExistsGuard } from './BookingStatus';
import { ScannerExistsGuard } from './Scanner';
import { MeasurementDataNormExistsGuard } from './MeasurementDataNorm';
import { FeatureDataSeriesExistsGuard } from './FeatureDataSeries';
import { WorkerExistsGuard } from './Worker';
import { BookingSlotExistsGuard } from './BookingSlot';
import { MeasurementSessionIntegrityExistsGuard } from './MeasurementSessionIntegrity';
import { MeasurementSessionQualityExistsGuard } from './MeasurementSessionQuality';
import { EventStatusExistsGuard } from './EventStatus';
import { PrinterStatusExistsGuard } from './PrinterStatus';
import { LogExistsGuard } from './Log';
import { EnvironmentExistsGuard } from './Environment';
import { MidsoleExistsGuard } from './Midsole';
import { CustomMidsoleExistsGuard } from './CustomMidsole';

export const LOOPBACK_GUARDS_PROVIDERS = [
  AuthGuard,
	ProductExistsGuard,
	AppUserExistsGuard,
	MeasurementSessionExistsGuard,
	MeasurementDataExistsGuard,
	GenderExistsGuard,
	MeasurementDataCompactExistsGuard,
	FeetScanExistsGuard,
	AttachmentExistsGuard,
	MeasurementParameterExistsGuard,
	FeatureExistsGuard,
	FeatureDataExistsGuard,
	SensorExistsGuard,
	PrinterExistsGuard,
	LocationExistsGuard,
	BookingExistsGuard,
	EventExistsGuard,
	PrinterTaskExistsGuard,
	OrderExistsGuard,
	OrderStatusExistsGuard,
	PrintTaskStatusExistsGuard,
	BookingStatusExistsGuard,
	ScannerExistsGuard,
	MeasurementDataNormExistsGuard,
	FeatureDataSeriesExistsGuard,
	WorkerExistsGuard,
	BookingSlotExistsGuard,
	MeasurementSessionIntegrityExistsGuard,
	MeasurementSessionQualityExistsGuard,
	EventStatusExistsGuard,
	PrinterStatusExistsGuard,
	LogExistsGuard,
	EnvironmentExistsGuard,
	MidsoleExistsGuard,
	CustomMidsoleExistsGuard,
];

export * from './auth.guard';
export * from './Product';
export * from './AppUser';
export * from './MeasurementSession';
export * from './MeasurementData';
export * from './Gender';
export * from './MeasurementDataCompact';
export * from './FeetScan';
export * from './Attachment';
export * from './MeasurementParameter';
export * from './Feature';
export * from './FeatureData';
export * from './Sensor';
export * from './Printer';
export * from './Location';
export * from './Booking';
export * from './Event';
export * from './PrinterTask';
export * from './Order';
export * from './OrderStatus';
export * from './PrintTaskStatus';
export * from './BookingStatus';
export * from './Scanner';
export * from './MeasurementDataNorm';
export * from './FeatureDataSeries';
export * from './Worker';
export * from './BookingSlot';
export * from './MeasurementSessionIntegrity';
export * from './MeasurementSessionQuality';
export * from './EventStatus';
export * from './PrinterStatus';
export * from './Log';
export * from './Environment';
export * from './Midsole';
export * from './CustomMidsole';
