/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Order, OrderInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { OrderActionTypes } from '../actions';

export interface OrdersState extends EntityState<Order | OrderInterface> {};

export const OrderAdapter: EntityAdapter<Order | OrderInterface> = createEntityAdapter<Order | OrderInterface>();

const initialState: OrdersState = OrderAdapter.getInitialState({});

const cases = BaseReducerFactory<OrdersState, Order | OrderInterface>(OrderActionTypes, OrderAdapter);

/**
 * @module OrdersReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Order reducer.
 */
export function OrdersReducer(state = initialState, action: LoopbackAction): OrdersState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getOrdersState = (state: any) => state.Orders;
export const getOrdersEntities = (state: any) => state.Orders.entities;
export const getOrdersIds = (state: any) => state.Orders.ids;

export const getOrders =
  createSelector(getOrdersEntities, getOrdersIds, (entities, ids) => ids.map((id) => entities[id]));

export function getOrderById(id: string) {
  return (state: any) => state.Orders.entities[id];
}

export function getOrdersById(ids: string[]) {
  return createSelector(getOrdersEntities, (entities) => ids.map((id) => entities[id]));
}
