/* tslint:disable */

declare var Object: any;
export interface AppUserInterface {
  "name"?: string;
  "firstName"?: string;
  "lastName"?: string;
  "phone"?: string;
  "shoeSize"?: number;
  "sizeLeft"?: number;
  "sizeRight"?: number;
  "archLeft"?: number;
  "archRight"?: number;
  "vector"?: number;
  "hexagon"?: number;
  "weight"?: number;
  "height"?: number;
  "badge"?: string;
  "consent"?: boolean;
  "ship"?: boolean;
  "anonymous"?: boolean;
  "participate"?: boolean;
  "consentAt"?: Date;
  "origin"?: string;
  "integrityId"?: number;
  "age"?: number;
  "fitnessLevel"?: number;
  "personal"?: any;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "managerId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "workingSessionId"?: number;
  "genderId"?: number;
  "workingScanId"?: number;
  "eventId"?: number;
  "originEventId"?: number;
  "password"?: string;
  accessTokens?: any[];
  manager?: any;
  roles?: any[];
  measurementSessions?: any[];
  workingSession?: any;
  gender?: any;
  feetScans?: any[];
  workingScan?: any;
  locations?: any[];
  originEvent?: any;
  events?: any[];
}

export class AppUser implements AppUserInterface {
  "name": string;
  "firstName": string;
  "lastName": string;
  "phone": string;
  "shoeSize": number;
  "sizeLeft": number;
  "sizeRight": number;
  "archLeft": number;
  "archRight": number;
  "vector": number;
  "hexagon": number;
  "weight": number;
  "height": number;
  "badge": string;
  "consent": boolean;
  "ship": boolean;
  "anonymous": boolean;
  "participate": boolean;
  "consentAt": Date;
  "origin": string;
  "integrityId": number;
  "age": number;
  "fitnessLevel": number;
  "personal": any;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "managerId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "workingSessionId": number;
  "genderId": number;
  "workingScanId": number;
  "eventId": number;
  "originEventId": number;
  "password": string;
  accessTokens: any[];
  manager: any;
  roles: any[];
  measurementSessions: any[];
  workingSession: any;
  gender: any;
  feetScans: any[];
  workingScan: any;
  locations: any[];
  originEvent: any;
  events: any[];
  constructor(data?: AppUserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppUser`.
   */
  public static getModelName() {
    return "AppUser";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppUser for dynamic purposes.
  **/
  public static factory(data: AppUserInterface): AppUser{
    return new AppUser(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppUser',
      plural: 'AppUsers',
      path: 'AppUsers',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "firstName": {
          name: 'firstName',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "phone": {
          name: 'phone',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "shoeSize": {
          name: 'shoeSize',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sizeLeft": {
          name: 'sizeLeft',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sizeRight": {
          name: 'sizeRight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "archLeft": {
          name: 'archLeft',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "archRight": {
          name: 'archRight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "vector": {
          name: 'vector',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "hexagon": {
          name: 'hexagon',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "weight": {
          name: 'weight',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "height": {
          name: 'height',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "badge": {
          name: 'badge',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "consent": {
          name: 'consent',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "ship": {
          name: 'ship',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "anonymous": {
          name: 'anonymous',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "participate": {
          name: 'participate',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "consentAt": {
          name: 'consentAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "origin": {
          name: 'origin',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "integrityId": {
          name: 'integrityId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "age": {
          name: 'age',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "fitnessLevel": {
          name: 'fitnessLevel',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "personal": {
          name: 'personal',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "realm": {
          name: 'realm',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "username": {
          name: 'username',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "email": {
          name: 'email',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          ui: {"group":"general","control":"textbox"},
          type: 'boolean'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "managerId": {
          name: 'managerId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "workingSessionId": {
          name: 'workingSessionId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "genderId": {
          name: 'genderId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "workingScanId": {
          name: 'workingScanId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "originEventId": {
          name: 'originEventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "password": {
          name: 'password',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'userId'
        },
        manager: {
          name: 'manager',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'managerId',
          keyTo: 'id'
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
              modelThrough: 'RoleMapping',
              keyThrough: 'roleId',
                      keyFrom: 'id',
          keyTo: 'principalId'
        },
        measurementSessions: {
          name: 'measurementSessions',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'appUserId'
        },
        workingSession: {
          name: 'workingSession',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'workingSessionId',
          keyTo: 'id'
        },
        gender: {
          name: 'gender',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'genderId',
          keyTo: 'id'
        },
        feetScans: {
          name: 'feetScans',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'appUserId'
        },
        workingScan: {
          name: 'workingScan',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'workingScanId',
          keyTo: 'id'
        },
        locations: {
          name: 'locations',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'appUserId'
        },
        originEvent: {
          name: 'originEvent',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'originEventId',
          keyTo: 'id'
        },
        events: {
          name: 'events',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'appUserId'
        },
      }
    }
  }
}
