/* tslint:disable */

declare var Object: any;
export interface MeasurementSessionInterface {
  "startedAt"?: Date;
  "stoppedAt"?: Date;
  "description"?: string;
  "note"?: string;
  "duration"?: number;
  "stat"?: any;
  "id"?: number;
  "appUserId"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "relatedScanId"?: number;
  "eventId"?: number;
  "integrityId"?: number;
  "qualityId"?: number;
  appUser?: any;
  measurementData?: any[];
  measurementDataCompact?: any[];
  relatedScan?: any;
  features?: any[];
  event?: any;
  measurementDataNorm?: any[];
  integrity?: any;
  quality?: any;
}

export class MeasurementSession implements MeasurementSessionInterface {
  "startedAt": Date;
  "stoppedAt": Date;
  "description": string;
  "note": string;
  "duration": number;
  "stat": any;
  "id": number;
  "appUserId": number;
  "createdAt": Date;
  "updatedAt": Date;
  "relatedScanId": number;
  "eventId": number;
  "integrityId": number;
  "qualityId": number;
  appUser: any;
  measurementData: any[];
  measurementDataCompact: any[];
  relatedScan: any;
  features: any[];
  event: any;
  measurementDataNorm: any[];
  integrity: any;
  quality: any;
  constructor(data?: MeasurementSessionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MeasurementSession`.
   */
  public static getModelName() {
    return "MeasurementSession";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MeasurementSession for dynamic purposes.
  **/
  public static factory(data: MeasurementSessionInterface): MeasurementSession{
    return new MeasurementSession(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MeasurementSession',
      plural: 'MeasurementSessions',
      path: 'MeasurementSessions',
      idName: 'id',
      properties: {
        "startedAt": {
          name: 'startedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "stoppedAt": {
          name: 'stoppedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "description": {
          name: 'description',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "note": {
          name: 'note',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "duration": {
          name: 'duration',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "stat": {
          name: 'stat',
          ui: {"group":"general","control":"textbox"},
          type: 'any'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "relatedScanId": {
          name: 'relatedScanId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "integrityId": {
          name: 'integrityId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "qualityId": {
          name: 'qualityId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'appUserId',
          keyTo: 'id'
        },
        measurementData: {
          name: 'measurementData',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'measurementSessionId'
        },
        measurementDataCompact: {
          name: 'measurementDataCompact',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'measurementSessionId'
        },
        relatedScan: {
          name: 'relatedScan',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'relatedScanId',
          keyTo: 'id'
        },
        features: {
          name: 'features',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'measurementSessionId'
        },
        event: {
          name: 'event',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'eventId',
          keyTo: 'id'
        },
        measurementDataNorm: {
          name: 'measurementDataNorm',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                      keyFrom: 'id',
          keyTo: 'measurementSessionId'
        },
        integrity: {
          name: 'integrity',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'integrityId',
          keyTo: 'id'
        },
        quality: {
          name: 'quality',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                      keyFrom: 'qualityId',
          keyTo: 'id'
        },
      }
    }
  }
}
