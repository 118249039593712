/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { FeatureData, FeatureDataInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { FeatureDataActionTypes } from '../actions';

export interface FeatureDatasState extends EntityState<FeatureData | FeatureDataInterface> {};

export const FeatureDataAdapter: EntityAdapter<FeatureData | FeatureDataInterface> = createEntityAdapter<FeatureData | FeatureDataInterface>();

const initialState: FeatureDatasState = FeatureDataAdapter.getInitialState({});

const cases = BaseReducerFactory<FeatureDatasState, FeatureData | FeatureDataInterface>(FeatureDataActionTypes, FeatureDataAdapter);

/**
 * @module FeatureDatasReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible FeatureData reducer.
 */
export function FeatureDatasReducer(state = initialState, action: LoopbackAction): FeatureDatasState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getFeatureDatasState = (state: any) => state.FeatureDatas;
export const getFeatureDatasEntities = (state: any) => state.FeatureDatas.entities;
export const getFeatureDatasIds = (state: any) => state.FeatureDatas.ids;

export const getFeatureDatas =
  createSelector(getFeatureDatasEntities, getFeatureDatasIds, (entities, ids) => ids.map((id) => entities[id]));

export function getFeatureDataById(id: string) {
  return (state: any) => state.FeatureDatas.entities[id];
}

export function getFeatureDatasById(ids: string[]) {
  return createSelector(getFeatureDatasEntities, (entities) => ids.map((id) => entities[id]));
}
