/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Product } from '../models';

export const ProductActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Product'), {
  GET_GENDER: type('[Product] getGender'),
  GET_GENDER_SUCCESS: type('[Product] getGender success'),
  GET_GENDER_FAIL: type('[Product] getGender fail'),

});
export const ProductActions =
Object.assign(BaseLoopbackActionsFactory<Product>(ProductActionTypes), {

  /**
   * getGender Action.
   * Fetches belongsTo relation gender.
   *
   * @param {any} id Product id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getGender: class implements Action {
    public readonly type = ProductActionTypes.GET_GENDER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getGenderSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getGenderSuccess: class implements Action {
    public readonly type = ProductActionTypes.GET_GENDER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getGenderFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getGenderFail: class implements Action {
    public readonly type = ProductActionTypes.GET_GENDER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});