import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
export class GaugeAreaComponent extends SettingsComponent {
}
GaugeAreaComponent.propDecorators = {
    'background': [{ type: Input },],
    'border': [{ type: Input },],
    'height': [{ type: Input },],
    'margin': [{ type: Input },],
    'width': [{ type: Input },],
};
