import { Injectable } from '@angular/core';
/**
 * @hidden
 */
export class CollectionChangesService {
}
CollectionChangesService.decorators = [
    { type: Injectable },
];
/** @nocollapse */
CollectionChangesService.ctorParameters = () => [];
