/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { MeasurementSessionIntegrity, MeasurementSessionIntegrityInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MeasurementSessionIntegrityActionTypes } from '../actions';

export interface MeasurementSessionIntegritysState extends EntityState<MeasurementSessionIntegrity | MeasurementSessionIntegrityInterface> {};

export const MeasurementSessionIntegrityAdapter: EntityAdapter<MeasurementSessionIntegrity | MeasurementSessionIntegrityInterface> = createEntityAdapter<MeasurementSessionIntegrity | MeasurementSessionIntegrityInterface>();

const initialState: MeasurementSessionIntegritysState = MeasurementSessionIntegrityAdapter.getInitialState({});

const cases = BaseReducerFactory<MeasurementSessionIntegritysState, MeasurementSessionIntegrity | MeasurementSessionIntegrityInterface>(MeasurementSessionIntegrityActionTypes, MeasurementSessionIntegrityAdapter);

/**
 * @module MeasurementSessionIntegritysReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible MeasurementSessionIntegrity reducer.
 */
export function MeasurementSessionIntegritysReducer(state = initialState, action: LoopbackAction): MeasurementSessionIntegritysState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMeasurementSessionIntegritysState = (state: any) => state.MeasurementSessionIntegritys;
export const getMeasurementSessionIntegritysEntities = (state: any) => state.MeasurementSessionIntegritys.entities;
export const getMeasurementSessionIntegritysIds = (state: any) => state.MeasurementSessionIntegritys.ids;

export const getMeasurementSessionIntegritys =
  createSelector(getMeasurementSessionIntegritysEntities, getMeasurementSessionIntegritysIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMeasurementSessionIntegrityById(id: string) {
  return (state: any) => state.MeasurementSessionIntegritys.entities[id];
}

export function getMeasurementSessionIntegritysById(ids: string[]) {
  return createSelector(getMeasurementSessionIntegritysEntities, (entities) => ids.map((id) => entities[id]));
}
