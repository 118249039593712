/* tslint:disable */
import { Injectable } from '@angular/core';
import { Product } from '../../models/Product';
import { AppUser } from '../../models/AppUser';
import { MeasurementSession } from '../../models/MeasurementSession';
import { MeasurementData } from '../../models/MeasurementData';
import { Gender } from '../../models/Gender';
import { MeasurementDataCompact } from '../../models/MeasurementDataCompact';
import { FeetScan } from '../../models/FeetScan';
import { Attachment } from '../../models/Attachment';
import { MeasurementParameter } from '../../models/MeasurementParameter';
import { Feature } from '../../models/Feature';
import { FeatureData } from '../../models/FeatureData';
import { Sensor } from '../../models/Sensor';
import { Printer } from '../../models/Printer';
import { Location } from '../../models/Location';
import { Booking } from '../../models/Booking';
import { Event } from '../../models/Event';
import { PrinterTask } from '../../models/PrinterTask';
import { Order } from '../../models/Order';
import { OrderStatus } from '../../models/OrderStatus';
import { PrintTaskStatus } from '../../models/PrintTaskStatus';
import { BookingStatus } from '../../models/BookingStatus';
import { Scanner } from '../../models/Scanner';
import { MeasurementDataNorm } from '../../models/MeasurementDataNorm';
import { FeatureDataSeries } from '../../models/FeatureDataSeries';
import { Worker } from '../../models/Worker';
import { BookingSlot } from '../../models/BookingSlot';
import { MeasurementSessionIntegrity } from '../../models/MeasurementSessionIntegrity';
import { MeasurementSessionQuality } from '../../models/MeasurementSessionQuality';
import { EventStatus } from '../../models/EventStatus';
import { PrinterStatus } from '../../models/PrinterStatus';
import { Log } from '../../models/Log';
import { Environment } from '../../models/Environment';
import { Midsole } from '../../models/Midsole';
import { CustomMidsole } from '../../models/CustomMidsole';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Product: Product,
    AppUser: AppUser,
    MeasurementSession: MeasurementSession,
    MeasurementData: MeasurementData,
    Gender: Gender,
    MeasurementDataCompact: MeasurementDataCompact,
    FeetScan: FeetScan,
    Attachment: Attachment,
    MeasurementParameter: MeasurementParameter,
    Feature: Feature,
    FeatureData: FeatureData,
    Sensor: Sensor,
    Printer: Printer,
    Location: Location,
    Booking: Booking,
    Event: Event,
    PrinterTask: PrinterTask,
    Order: Order,
    OrderStatus: OrderStatus,
    PrintTaskStatus: PrintTaskStatus,
    BookingStatus: BookingStatus,
    Scanner: Scanner,
    MeasurementDataNorm: MeasurementDataNorm,
    FeatureDataSeries: FeatureDataSeries,
    Worker: Worker,
    BookingSlot: BookingSlot,
    MeasurementSessionIntegrity: MeasurementSessionIntegrity,
    MeasurementSessionQuality: MeasurementSessionQuality,
    EventStatus: EventStatus,
    PrinterStatus: PrinterStatus,
    Log: Log,
    Environment: Environment,
    Midsole: Midsole,
    CustomMidsole: CustomMidsole,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
