import {Inject, Injectable, Optional} from '@angular/core';
import {BaseLoopBackApi} from '../lb-sdk/services/core/base.service';
import {SDKModels} from '../lb-sdk/services/custom/SDKModels';
import {LoopBackAuth} from '../lb-sdk/services/core/auth.service';
import {ErrorHandler} from '../lb-sdk/services/core/error.service';
import {LoopBackConfig} from '../lb-sdk/lb.config';
import {LoopBackFilter} from '../lb-sdk/models/BaseModels';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable()
export class ApiExService extends BaseLoopBackApi {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(SDKModels) protected models: SDKModels,
        @Inject(LoopBackAuth) protected auth: LoopBackAuth,
        @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
    ) {
        super(http, models, auth, errorHandler);
    }

    getModelName(): string {
        //return 'not_implemented';
        return null;
    }

    public getModelByName(modelName: string) {
        return this.models.get(modelName);
    }

    public countEx(modelName: string, where: any = {}, customHeaders?: Function): Observable<{ count: number }> {
        let _model = this.getModelByName(modelName);

        let _urlParams: any = {};
        if (where) {
            _urlParams.where = where;
        }
        return this.request('GET', [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            _model.getModelDefinition().path,
            'count'
        ].join('/'), undefined, _urlParams, undefined, null, customHeaders);
    }

    public findEx<T>(modelName: string, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
        let _model = this.getModelByName(modelName);

        return this.request('GET', [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            _model.getModelDefinition().path
        ].join('/'), undefined, {filter}, undefined, null, customHeaders)
            .pipe(map((datum: T[]) => datum.map((data: T) => _model.factory(data))));
    }

    public findByIdEx<T>(modelName: string, id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
        let _model = this.getModelByName(modelName);

        let _urlParams: any = {};
        if (filter) {
            _urlParams.filter = filter;
        }
        return this.request('GET', [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            _model.getModelDefinition().path,
            ':id'
        ].join('/'), {id}, _urlParams, undefined, null, customHeaders)
            .pipe(map((data: T) => _model.factory(data)));
    }

    public updateAttributesEx<T>(modelName: string, id: any, data: T, customHeaders?: Function): Observable<T> {
        let _model = this.getModelByName(modelName);

        //return this.request('PUT', [
        return this.request('PATCH', [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            _model.getModelDefinition().path,
            ':id'
        ].join('/'), {id}, undefined, {data}, null, customHeaders)
            .pipe(map((data: T) => _model.factory(data)));
    }

    public createEx<T>(modelName: string, data: T, customHeaders?: Function): Observable<T> {
        let _model = this.getModelByName(modelName);

        return this.request('POST', [
            LoopBackConfig.getPath(),
            LoopBackConfig.getApiVersion(),
            _model.getModelDefinition().path
        ].join('/'), undefined, undefined, {data}, null, customHeaders)
            .pipe(map((data: T) => _model.factory(data)));
    }

}
