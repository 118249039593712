/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Booking, BookingInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { BookingActionTypes } from '../actions';

export interface BookingsState extends EntityState<Booking | BookingInterface> {};

export const BookingAdapter: EntityAdapter<Booking | BookingInterface> = createEntityAdapter<Booking | BookingInterface>();

const initialState: BookingsState = BookingAdapter.getInitialState({});

const cases = BaseReducerFactory<BookingsState, Booking | BookingInterface>(BookingActionTypes, BookingAdapter);

/**
 * @module BookingsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Booking reducer.
 */
export function BookingsReducer(state = initialState, action: LoopbackAction): BookingsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getBookingsState = (state: any) => state.Bookings;
export const getBookingsEntities = (state: any) => state.Bookings.entities;
export const getBookingsIds = (state: any) => state.Bookings.ids;

export const getBookings =
  createSelector(getBookingsEntities, getBookingsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getBookingById(id: string) {
  return (state: any) => state.Bookings.entities[id];
}

export function getBookingsById(ids: string[]) {
  return createSelector(getBookingsEntities, (entities) => ids.map((id) => entities[id]));
}
