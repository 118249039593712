/* tslint:disable */
import {
  Gender
} from '../index';

declare var Object: any;
export interface ProductInterface {
  "sku"?: string;
  "name": string;
  "image"?: string;
  "pos"?: number;
  "sizeMin"?: number;
  "sizeMax"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "genderId"?: number;
  gender?: Gender;
}

export class Product implements ProductInterface {
  "sku": string;
  "name": string;
  "image": string;
  "pos": number;
  "sizeMin": number;
  "sizeMax": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  "genderId": number;
  gender: Gender;
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return "Product";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Product for dynamic purposes.
  **/
  public static factory(data: ProductInterface): Product{
    return new Product(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'Products',
      path: 'Products',
      idName: 'id',
      properties: {
        "sku": {
          name: 'sku',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "name": {
          name: 'name',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "image": {
          name: 'image',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "pos": {
          name: 'pos',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sizeMin": {
          name: 'sizeMin',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "sizeMax": {
          name: 'sizeMax',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "genderId": {
          name: 'genderId',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
      },
      relations: {
        gender: {
          name: 'gender',
          type: 'Gender',
          model: 'Gender',
          relationType: 'belongsTo',
                      keyFrom: 'genderId',
          keyTo: 'id'
        },
      }
    }
  }
}
