/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Midsole, MidsoleInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { MidsoleActionTypes } from '../actions';

export interface MidsolesState extends EntityState<Midsole | MidsoleInterface> {};

export const MidsoleAdapter: EntityAdapter<Midsole | MidsoleInterface> = createEntityAdapter<Midsole | MidsoleInterface>();

const initialState: MidsolesState = MidsoleAdapter.getInitialState({});

const cases = BaseReducerFactory<MidsolesState, Midsole | MidsoleInterface>(MidsoleActionTypes, MidsoleAdapter);

/**
 * @module MidsolesReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Midsole reducer.
 */
export function MidsolesReducer(state = initialState, action: LoopbackAction): MidsolesState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getMidsolesState = (state: any) => state.Midsoles;
export const getMidsolesEntities = (state: any) => state.Midsoles.entities;
export const getMidsolesIds = (state: any) => state.Midsoles.ids;

export const getMidsoles =
  createSelector(getMidsolesEntities, getMidsolesIds, (entities, ids) => ids.map((id) => entities[id]));

export function getMidsoleById(id: string) {
  return (state: any) => state.Midsoles.entities[id];
}

export function getMidsolesById(ids: string[]) {
  return createSelector(getMidsolesEntities, (entities) => ids.map((id) => entities[id]));
}
