import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
export class ScaleComponent extends SettingsComponent {
}
ScaleComponent.propDecorators = {
    'labels': [{ type: Input },],
    'majorTicks': [{ type: Input },],
    'minorTicks': [{ type: Input },],
    'min': [{ type: Input },],
    'max': [{ type: Input },],
    'minorUnit': [{ type: Input },],
    'majorUnit': [{ type: Input },],
    'reverse': [{ type: Input },],
    'rangeSize': [{ type: Input },],
    'rangePlaceholderColor': [{ type: Input },],
};
