/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { OrderActionTypes, OrderActions } from '../actions/Order';
import { LoopbackErrorActions } from '../actions/error';
import { OrderApi } from '../services/index';

@Injectable()
export class OrderEffects extends BaseLoopbackEffects {
  @Effect()
  public getStatus$ = this.actions$
    .pipe(
      ofType(OrderActionTypes.GET_STATUS),
      mergeMap((action: LoopbackAction) =>
        this.order.getStatus(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'OrderStatus', 'findSuccess'),
            of(new OrderActions.getStatusSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new OrderActions.getStatusFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(OrderApi) public order: OrderApi
  ) {
    super(actions$, order, 'Order', OrderActionTypes, OrderActions);
  }
}
