/* tslint:disable */
import { Action, createSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BaseReducerFactory } from './base';
import { Sensor, SensorInterface } from '../models';
import { LoopbackAction } from '../models/BaseModels';
import { SensorActionTypes } from '../actions';

export interface SensorsState extends EntityState<Sensor | SensorInterface> {};

export const SensorAdapter: EntityAdapter<Sensor | SensorInterface> = createEntityAdapter<Sensor | SensorInterface>();

const initialState: SensorsState = SensorAdapter.getInitialState({});

const cases = BaseReducerFactory<SensorsState, Sensor | SensorInterface>(SensorActionTypes, SensorAdapter);

/**
 * @module SensorsReducer
 * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
 * @license MIT
 * @description
 * Provides with a LoopBack compatible Sensor reducer.
 */
export function SensorsReducer(state = initialState, action: LoopbackAction): SensorsState {
  if (cases[action.type]) {
    return cases[action.type](state, action);
  } else {
    return state;
  }
}

export const getSensorsState = (state: any) => state.Sensors;
export const getSensorsEntities = (state: any) => state.Sensors.entities;
export const getSensorsIds = (state: any) => state.Sensors.ids;

export const getSensors =
  createSelector(getSensorsEntities, getSensorsIds, (entities, ids) => ids.map((id) => entities[id]));

export function getSensorById(id: string) {
  return (state: any) => state.Sensors.entities[id];
}

export function getSensorsById(ids: string[]) {
  return createSelector(getSensorsEntities, (entities) => ids.map((id) => entities[id]));
}
