/* tslint:disable */
import { map, catchError, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { LoopbackAction } from '../models/BaseModels';
import { BaseLoopbackEffects } from './base';
import { resolver, resolveThrough } from './resolver';

import * as actions from '../actions';
import { FeatureDataSeriesActionTypes, FeatureDataSeriesActions } from '../actions/FeatureDataSeries';
import { LoopbackErrorActions } from '../actions/error';
import { FeatureDataSeriesApi } from '../services/index';

@Injectable()
export class FeatureDataSeriesEffects extends BaseLoopbackEffects {
  @Effect()
  public getFeature$ = this.actions$
    .pipe(
      ofType(FeatureDataSeriesActionTypes.GET_FEATURE),
      mergeMap((action: LoopbackAction) =>
        this.featuredataseries.getFeature(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Feature', 'findSuccess'),
            of(new FeatureDataSeriesActions.getFeatureSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataSeriesActions.getFeatureFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getSensor$ = this.actions$
    .pipe(
      ofType(FeatureDataSeriesActionTypes.GET_SENSOR),
      mergeMap((action: LoopbackAction) =>
        this.featuredataseries.getSensor(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'Sensor', 'findSuccess'),
            of(new FeatureDataSeriesActions.getSensorSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataSeriesActions.getSensorFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementParameter$ = this.actions$
    .pipe(
      ofType(FeatureDataSeriesActionTypes.GET_MEASUREMENTPARAMETER),
      mergeMap((action: LoopbackAction) =>
        this.featuredataseries.getMeasurementParameter(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementParameter', 'findSuccess'),
            of(new FeatureDataSeriesActions.getMeasurementParameterSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataSeriesActions.getMeasurementParameterFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

  @Effect()
  public getMeasurementSession$ = this.actions$
    .pipe(
      ofType(FeatureDataSeriesActionTypes.GET_MEASUREMENTSESSION),
      mergeMap((action: LoopbackAction) =>
        this.featuredataseries.getMeasurementSession(action.payload.id, action.payload.refresh).pipe(
          mergeMap((response: any) => concat(
            resolver({data: response, meta: action.meta}, 'MeasurementSession', 'findSuccess'),
            of(new FeatureDataSeriesActions.getMeasurementSessionSuccess(action.payload.id, response, action.meta))
          )),
          catchError((error: any) => concat(
            of(new FeatureDataSeriesActions.getMeasurementSessionFail(error, action.meta)),
            of(new LoopbackErrorActions.error(error, action.meta))
          ))
        )
      )
    );

    /**
   * @author João Ribeiro <@JonnyBGod> <github:JonnyBGod>
   * @description
   * Decorate base effects metadata
   */
  @Effect() public create$;
  @Effect() public createMany$;
  @Effect() public findById$;
  @Effect() public find$;
  @Effect() public findOne$;
  @Effect() public updateAll$;
  @Effect() public deleteById$;
  @Effect() public updateAttributes$;
  @Effect() public upsert$;
  @Effect() public upsertWithWhere$;
  @Effect() public replaceOrCreate$;
  @Effect() public replaceById$;
  @Effect() public patchOrCreate$;
  @Effect() public patchAttributes$;

  constructor(
    @Inject(Actions) public actions$: Actions,
    @Inject(FeatureDataSeriesApi) public featuredataseries: FeatureDataSeriesApi
  ) {
    super(actions$, featuredataseries, 'FeatureDataSeries', FeatureDataSeriesActionTypes, FeatureDataSeriesActions);
  }
}
