/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, FeatureData } from '../models';

export const FeatureDataActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('FeatureData'), {
  GET_FEATURE: type('[FeatureData] getFeature'),
  GET_FEATURE_SUCCESS: type('[FeatureData] getFeature success'),
  GET_FEATURE_FAIL: type('[FeatureData] getFeature fail'),

  GET_MEASUREMENTPARAMETER: type('[FeatureData] getMeasurementParameter'),
  GET_MEASUREMENTPARAMETER_SUCCESS: type('[FeatureData] getMeasurementParameter success'),
  GET_MEASUREMENTPARAMETER_FAIL: type('[FeatureData] getMeasurementParameter fail'),

  GET_MEASUREMENTSESSION: type('[FeatureData] getMeasurementSession'),
  GET_MEASUREMENTSESSION_SUCCESS: type('[FeatureData] getMeasurementSession success'),
  GET_MEASUREMENTSESSION_FAIL: type('[FeatureData] getMeasurementSession fail'),

  FIND_BY_ID_MEASUREMENTDATACOMPACT: type('[FeatureData] findByIdMeasurementDataCompact'),
  FIND_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] findByIdMeasurementDataCompact success'),
  FIND_BY_ID_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] findByIdMeasurementDataCompact fail'),

  DESTROY_BY_ID_MEASUREMENTDATACOMPACT: type('[FeatureData] destroyByIdMeasurementDataCompact'),
  DESTROY_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] destroyByIdMeasurementDataCompact success'),
  DESTROY_BY_ID_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] destroyByIdMeasurementDataCompact fail'),

  UPDATE_BY_ID_MEASUREMENTDATACOMPACT: type('[FeatureData] updateByIdMeasurementDataCompact'),
  UPDATE_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] updateByIdMeasurementDataCompact success'),
  UPDATE_BY_ID_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] updateByIdMeasurementDataCompact fail'),

  LINK_MEASUREMENTDATACOMPACT: type('[FeatureData] linkMeasurementDataCompact'),
  LINK_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] linkMeasurementDataCompact success'),
  LINK_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] linkMeasurementDataCompact fail'),

  UNLINK_MEASUREMENTDATACOMPACT: type('[FeatureData] unlinkMeasurementDataCompact'),
  UNLINK_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] unlinkMeasurementDataCompact success'),
  UNLINK_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] unlinkMeasurementDataCompact fail'),

  FIND_BY_ID_MEASUREMENTDATA: type('[FeatureData] findByIdMeasurementData'),
  FIND_BY_ID_MEASUREMENTDATA_SUCCESS: type('[FeatureData] findByIdMeasurementData success'),
  FIND_BY_ID_MEASUREMENTDATA_FAIL: type('[FeatureData] findByIdMeasurementData fail'),

  DESTROY_BY_ID_MEASUREMENTDATA: type('[FeatureData] destroyByIdMeasurementData'),
  DESTROY_BY_ID_MEASUREMENTDATA_SUCCESS: type('[FeatureData] destroyByIdMeasurementData success'),
  DESTROY_BY_ID_MEASUREMENTDATA_FAIL: type('[FeatureData] destroyByIdMeasurementData fail'),

  UPDATE_BY_ID_MEASUREMENTDATA: type('[FeatureData] updateByIdMeasurementData'),
  UPDATE_BY_ID_MEASUREMENTDATA_SUCCESS: type('[FeatureData] updateByIdMeasurementData success'),
  UPDATE_BY_ID_MEASUREMENTDATA_FAIL: type('[FeatureData] updateByIdMeasurementData fail'),

  LINK_MEASUREMENTDATA: type('[FeatureData] linkMeasurementData'),
  LINK_MEASUREMENTDATA_SUCCESS: type('[FeatureData] linkMeasurementData success'),
  LINK_MEASUREMENTDATA_FAIL: type('[FeatureData] linkMeasurementData fail'),

  UNLINK_MEASUREMENTDATA: type('[FeatureData] unlinkMeasurementData'),
  UNLINK_MEASUREMENTDATA_SUCCESS: type('[FeatureData] unlinkMeasurementData success'),
  UNLINK_MEASUREMENTDATA_FAIL: type('[FeatureData] unlinkMeasurementData fail'),

  GET_SENSOR: type('[FeatureData] getSensor'),
  GET_SENSOR_SUCCESS: type('[FeatureData] getSensor success'),
  GET_SENSOR_FAIL: type('[FeatureData] getSensor fail'),

  FIND_BY_ID_MEASUREMENTDATANORM: type('[FeatureData] findByIdMeasurementDataNorm'),
  FIND_BY_ID_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] findByIdMeasurementDataNorm success'),
  FIND_BY_ID_MEASUREMENTDATANORM_FAIL: type('[FeatureData] findByIdMeasurementDataNorm fail'),

  DESTROY_BY_ID_MEASUREMENTDATANORM: type('[FeatureData] destroyByIdMeasurementDataNorm'),
  DESTROY_BY_ID_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] destroyByIdMeasurementDataNorm success'),
  DESTROY_BY_ID_MEASUREMENTDATANORM_FAIL: type('[FeatureData] destroyByIdMeasurementDataNorm fail'),

  UPDATE_BY_ID_MEASUREMENTDATANORM: type('[FeatureData] updateByIdMeasurementDataNorm'),
  UPDATE_BY_ID_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] updateByIdMeasurementDataNorm success'),
  UPDATE_BY_ID_MEASUREMENTDATANORM_FAIL: type('[FeatureData] updateByIdMeasurementDataNorm fail'),

  LINK_MEASUREMENTDATANORM: type('[FeatureData] linkMeasurementDataNorm'),
  LINK_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] linkMeasurementDataNorm success'),
  LINK_MEASUREMENTDATANORM_FAIL: type('[FeatureData] linkMeasurementDataNorm fail'),

  UNLINK_MEASUREMENTDATANORM: type('[FeatureData] unlinkMeasurementDataNorm'),
  UNLINK_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] unlinkMeasurementDataNorm success'),
  UNLINK_MEASUREMENTDATANORM_FAIL: type('[FeatureData] unlinkMeasurementDataNorm fail'),

  GET_MEASUREMENTDATACOMPACT: type('[FeatureData] getMeasurementDataCompact'),
  GET_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] getMeasurementDataCompact success'),
  GET_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] getMeasurementDataCompact fail'),

  CREATE_MEASUREMENTDATACOMPACT: type('[FeatureData] createMeasurementDataCompact'),
  CREATE_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] createMeasurementDataCompact success'),
  CREATE_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] createMeasurementDataCompact fail'),

  DELETE_MEASUREMENTDATACOMPACT: type('[FeatureData] deleteMeasurementDataCompact'),
  DELETE_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] deleteMeasurementDataCompact success'),
  DELETE_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] deleteMeasurementDataCompact fail'),

  GET_MEASUREMENTDATA: type('[FeatureData] getMeasurementData'),
  GET_MEASUREMENTDATA_SUCCESS: type('[FeatureData] getMeasurementData success'),
  GET_MEASUREMENTDATA_FAIL: type('[FeatureData] getMeasurementData fail'),

  CREATE_MEASUREMENTDATA: type('[FeatureData] createMeasurementData'),
  CREATE_MEASUREMENTDATA_SUCCESS: type('[FeatureData] createMeasurementData success'),
  CREATE_MEASUREMENTDATA_FAIL: type('[FeatureData] createMeasurementData fail'),

  DELETE_MEASUREMENTDATA: type('[FeatureData] deleteMeasurementData'),
  DELETE_MEASUREMENTDATA_SUCCESS: type('[FeatureData] deleteMeasurementData success'),
  DELETE_MEASUREMENTDATA_FAIL: type('[FeatureData] deleteMeasurementData fail'),

  GET_MEASUREMENTDATANORM: type('[FeatureData] getMeasurementDataNorm'),
  GET_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] getMeasurementDataNorm success'),
  GET_MEASUREMENTDATANORM_FAIL: type('[FeatureData] getMeasurementDataNorm fail'),

  CREATE_MEASUREMENTDATANORM: type('[FeatureData] createMeasurementDataNorm'),
  CREATE_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] createMeasurementDataNorm success'),
  CREATE_MEASUREMENTDATANORM_FAIL: type('[FeatureData] createMeasurementDataNorm fail'),

  DELETE_MEASUREMENTDATANORM: type('[FeatureData] deleteMeasurementDataNorm'),
  DELETE_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] deleteMeasurementDataNorm success'),
  DELETE_MEASUREMENTDATANORM_FAIL: type('[FeatureData] deleteMeasurementDataNorm fail'),

  CREATE_MANY_MEASUREMENTDATACOMPACT: type('[FeatureData] createManyMeasurementDataCompact'),
  CREATE_MANY_MEASUREMENTDATACOMPACT_SUCCESS: type('[FeatureData] createManyMeasurementDataCompact success'),
  CREATE_MANY_MEASUREMENTDATACOMPACT_FAIL: type('[FeatureData] createManyMeasurementDataCompact fail'),

  CREATE_MANY_MEASUREMENTDATA: type('[FeatureData] createManyMeasurementData'),
  CREATE_MANY_MEASUREMENTDATA_SUCCESS: type('[FeatureData] createManyMeasurementData success'),
  CREATE_MANY_MEASUREMENTDATA_FAIL: type('[FeatureData] createManyMeasurementData fail'),

  CREATE_MANY_MEASUREMENTDATANORM: type('[FeatureData] createManyMeasurementDataNorm'),
  CREATE_MANY_MEASUREMENTDATANORM_SUCCESS: type('[FeatureData] createManyMeasurementDataNorm success'),
  CREATE_MANY_MEASUREMENTDATANORM_FAIL: type('[FeatureData] createManyMeasurementDataNorm fail'),

});
export const FeatureDataActions =
Object.assign(BaseLoopbackActionsFactory<FeatureData>(FeatureDataActionTypes), {

  /**
   * getFeature Action.
   * Fetches belongsTo relation feature.
   *
   * @param {any} id FeatureData id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getFeature: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_FEATURE;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getFeatureSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getFeatureSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_FEATURE_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getFeatureFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getFeatureFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_FEATURE_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementParameter Action.
   * Fetches belongsTo relation measurementParameter.
   *
   * @param {any} id FeatureData id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameter: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTPARAMETER;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementParameterSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTPARAMETER_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementParameterFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementParameterFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTPARAMETER_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementSession Action.
   * Fetches belongsTo relation measurementSession.
   *
   * @param {any} id FeatureData id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSession: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTSESSION;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getMeasurementSessionSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTSESSION_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementSessionFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementSessionFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTSESSION_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdMeasurementDataCompact Action.
   * Find a related item by id for measurementDataCompact.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataCompact
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATACOMPACT;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdMeasurementDataCompact Action.
   * Delete a related item by id for measurementDataCompact.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataCompact
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATACOMPACT;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdMeasurementDataCompact Action.
   * Update a related item by id for measurementDataCompact.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataCompact
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATACOMPACT;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkMeasurementDataCompact Action.
   * Add a related item by id for measurementDataCompact.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataCompact
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATACOMPACT;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkMeasurementDataCompact Action.
   * Remove the measurementDataCompact relation to an item by id.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataCompact
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATACOMPACT;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdMeasurementData Action.
   * Find a related item by id for measurementData.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementData
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATA;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdMeasurementData Action.
   * Delete a related item by id for measurementData.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementData
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATA;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdMeasurementData Action.
   * Update a related item by id for measurementData.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementData
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATA;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkMeasurementData Action.
   * Add a related item by id for measurementData.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementData
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATA;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkMeasurementData Action.
   * Remove the measurementData relation to an item by id.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementData
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATA;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getSensor Action.
   * Fetches belongsTo relation sensor.
   *
   * @param {any} id FeatureData id
   * @param {boolean} refresh 
   * @param {any} meta (optional).
   * 
   */
  getSensor: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_SENSOR;
      public payload: {id: any, refresh: any, customHeaders};

    constructor(id: any, refresh: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, refresh, customHeaders};
    }
  },
  /**
   * getSensorSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  getSensorSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_SENSOR_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getSensorFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getSensorFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_SENSOR_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * findByIdMeasurementDataNorm Action.
   * Find a related item by id for measurementDataNorm.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataNorm
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATANORM;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.FIND_BY_ID_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdMeasurementDataNorm Action.
   * Delete a related item by id for measurementDataNorm.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataNorm
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATANORM;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DESTROY_BY_ID_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdMeasurementDataNorm Action.
   * Update a related item by id for measurementDataNorm.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataNorm
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATANORM;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UPDATE_BY_ID_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * linkMeasurementDataNorm Action.
   * Add a related item by id for measurementDataNorm.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataNorm
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATANORM;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * linkMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * linkMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  linkMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.LINK_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * unlinkMeasurementDataNorm Action.
   * Remove the measurementDataNorm relation to an item by id.
   *
   * @param {any} id FeatureData id
   * @param {any} fk Foreign key for measurementDataNorm
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATANORM;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * unlinkMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * unlinkMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  unlinkMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.UNLINK_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementDataCompact Action.
   * Queries measurementDataCompact of FeatureData.
   *
   * @param {any} id FeatureData id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATACOMPACT;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createMeasurementDataCompact Action.
   * Creates a new instance in measurementDataCompact of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATACOMPACT;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteMeasurementDataCompact Action.
   * Deletes all measurementDataCompact of this model.
   *
   * @param {any} id FeatureData id
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATACOMPACT;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATACOMPACT_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementData Action.
   * Queries measurementData of FeatureData.
   *
   * @param {any} id FeatureData id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATA;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createMeasurementData Action.
   * Creates a new instance in measurementData of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATA;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteMeasurementData Action.
   * Deletes all measurementData of this model.
   *
   * @param {any} id FeatureData id
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATA;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATA_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getMeasurementDataNorm Action.
   * Queries measurementDataNorm of FeatureData.
   *
   * @param {any} id FeatureData id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATANORM;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.GET_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createMeasurementDataNorm Action.
   * Creates a new instance in measurementDataNorm of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATANORM;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteMeasurementDataNorm Action.
   * Deletes all measurementDataNorm of this model.
   *
   * @param {any} id FeatureData id
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATANORM;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATANORM_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.DELETE_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyMeasurementDataCompact Action.
   * Creates a new instance in measurementDataCompact of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataCompact: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATACOMPACT;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyMeasurementDataCompactSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataCompactSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATACOMPACT_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyMeasurementDataCompactFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataCompactFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATACOMPACT_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyMeasurementData Action.
   * Creates a new instance in measurementData of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementData: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATA;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyMeasurementDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyMeasurementDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyMeasurementDataNorm Action.
   * Creates a new instance in measurementDataNorm of this model.
   *
   * @param {any} id FeatureData id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataNorm: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATANORM;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyMeasurementDataNormSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataNormSuccess: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATANORM_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyMeasurementDataNormFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyMeasurementDataNormFail: class implements Action {
    public readonly type = FeatureDataActionTypes.CREATE_MANY_MEASUREMENTDATANORM_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});