/* tslint:disable */
import { Action } from '@ngrx/store';
import { type } from '../util';
import { BaseLoopbackActionTypesFactory, BaseLoopbackActionsFactory } from './base';
import { LoopBackFilter, SDKToken, Feature } from '../models';

export const FeatureActionTypes =
Object.assign(BaseLoopbackActionTypesFactory('Feature'), {
  FIND_BY_ID_FEATUREDATA: type('[Feature] findByIdFeatureData'),
  FIND_BY_ID_FEATUREDATA_SUCCESS: type('[Feature] findByIdFeatureData success'),
  FIND_BY_ID_FEATUREDATA_FAIL: type('[Feature] findByIdFeatureData fail'),

  DESTROY_BY_ID_FEATUREDATA: type('[Feature] destroyByIdFeatureData'),
  DESTROY_BY_ID_FEATUREDATA_SUCCESS: type('[Feature] destroyByIdFeatureData success'),
  DESTROY_BY_ID_FEATUREDATA_FAIL: type('[Feature] destroyByIdFeatureData fail'),

  UPDATE_BY_ID_FEATUREDATA: type('[Feature] updateByIdFeatureData'),
  UPDATE_BY_ID_FEATUREDATA_SUCCESS: type('[Feature] updateByIdFeatureData success'),
  UPDATE_BY_ID_FEATUREDATA_FAIL: type('[Feature] updateByIdFeatureData fail'),

  GET_FEATUREDATA: type('[Feature] getFeatureData'),
  GET_FEATUREDATA_SUCCESS: type('[Feature] getFeatureData success'),
  GET_FEATUREDATA_FAIL: type('[Feature] getFeatureData fail'),

  CREATE_FEATUREDATA: type('[Feature] createFeatureData'),
  CREATE_FEATUREDATA_SUCCESS: type('[Feature] createFeatureData success'),
  CREATE_FEATUREDATA_FAIL: type('[Feature] createFeatureData fail'),

  DELETE_FEATUREDATA: type('[Feature] deleteFeatureData'),
  DELETE_FEATUREDATA_SUCCESS: type('[Feature] deleteFeatureData success'),
  DELETE_FEATUREDATA_FAIL: type('[Feature] deleteFeatureData fail'),

  BIN: type('[Feature] bin'),
  BIN_SUCCESS: type('[Feature] bin success'),
  BIN_FAIL: type('[Feature] bin fail'),

  CREATE_MANY_FEATUREDATA: type('[Feature] createManyFeatureData'),
  CREATE_MANY_FEATUREDATA_SUCCESS: type('[Feature] createManyFeatureData success'),
  CREATE_MANY_FEATUREDATA_FAIL: type('[Feature] createManyFeatureData fail'),

});
export const FeatureActions =
Object.assign(BaseLoopbackActionsFactory<Feature>(FeatureActionTypes), {

  /**
   * findByIdFeatureData Action.
   * Find a related item by id for featureData.
   *
   * @param {any} id Feature id
   * @param {any} fk Foreign key for featureData
   * @param {any} meta (optional).
   * 
   */
  findByIdFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.FIND_BY_ID_FEATUREDATA;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * findByIdFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  findByIdFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.FIND_BY_ID_FEATUREDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * findByIdFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  findByIdFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.FIND_BY_ID_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * destroyByIdFeatureData Action.
   * Delete a related item by id for featureData.
   *
   * @param {any} id Feature id
   * @param {any} fk Foreign key for featureData
   * @param {any} meta (optional).
   * 
   */
  destroyByIdFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.DESTROY_BY_ID_FEATUREDATA;
      public payload: {id: any, fk: any, customHeaders};

    constructor(id: any, fk: any, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, customHeaders};
    }
  },
  /**
   * destroyByIdFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  destroyByIdFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.DESTROY_BY_ID_FEATUREDATA_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * destroyByIdFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  destroyByIdFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.DESTROY_BY_ID_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * updateByIdFeatureData Action.
   * Update a related item by id for featureData.
   *
   * @param {any} id Feature id
   * @param {any} fk Foreign key for featureData
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  updateByIdFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.UPDATE_BY_ID_FEATUREDATA;
      public payload: {id: any, fk: any, data: any, customHeaders};

    constructor(id: any, fk: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, fk, data, customHeaders};
    }
  },
  /**
   * updateByIdFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  updateByIdFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.UPDATE_BY_ID_FEATUREDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * updateByIdFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  updateByIdFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.UPDATE_BY_ID_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * getFeatureData Action.
   * Queries featureData of Feature.
   *
   * @param {any} id Feature id
   * @param {object} filter 
   * @param {any} meta (optional).
   * 
   */
  getFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.GET_FEATUREDATA;
      public payload: {id: any, filter: LoopBackFilter, customHeaders};

    constructor(id: any, filter: LoopBackFilter = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, filter, customHeaders};
    }
  },
  /**
   * getFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  getFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.GET_FEATUREDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * getFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  getFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.GET_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createFeatureData Action.
   * Creates a new instance in featureData of this model.
   *
   * @param {any} id Feature id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_FEATUREDATA;
      public payload: {id: any, data: any, customHeaders};

    constructor(id: any, data: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object} data 
   * @param {any} meta (optional).
   * 
   */
  createFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_FEATUREDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * deleteFeatureData Action.
   * Deletes all featureData of this model.
   *
   * @param {any} id Feature id
   * @param {any} meta (optional).
   * 
   */
  deleteFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.DELETE_FEATUREDATA;
      
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  deleteFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.DELETE_FEATUREDATA_SUCCESS;
  
    constructor(public payload: any, public meta?: any) {}
  },
  /**
   * deleteFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  deleteFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.DELETE_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * bin Action.
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} req 
   * @param {object} res 
   * @param {string} feature 
   * @param {string} key 
   * @param {any} meta (optional).
   * 
   */
  bin: class implements Action {
    public readonly type = FeatureActionTypes.BIN;
      public payload: {req: any, res: any, feature: any, key: any, customHeaders};

    constructor(req: any = {}, res: any = {}, feature: any = {}, key: any = {}, customHeaders?: Function, public meta?: any) {
      this.payload = {req, res, feature, key, customHeaders};
    }
  },
  /**
   * binSuccess Action.
   * 
   * @param {any} id 
   * This method returns no data.
   * @param {any} meta (optional).
   * 
   */
  binSuccess: class implements Action {
    public readonly type = FeatureActionTypes.BIN_SUCCESS;
      public payload: {id: any, fk: any};

    constructor(id: any, fk: any, public meta?: any) {
      this.payload = {id, fk};
    }
  },
  /**
   * binFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  binFail: class implements Action {
    public readonly type = FeatureActionTypes.BIN_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },

  /**
   * createManyFeatureData Action.
   * Creates a new instance in featureData of this model.
   *
   * @param {any} id Feature id
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   * @param {any} meta (optional).
   * 
   */
  createManyFeatureData: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_MANY_FEATUREDATA;
      public payload: {id: any, data: any[], customHeaders};

    constructor(id: any, data: any[] = [], customHeaders?: Function, public meta?: any) {
      this.payload = {id, data, customHeaders};
    }
  },
  /**
   * createManyFeatureDataSuccess Action.
   * 
   * @param {any} id 
   * @param {object[]} data 
   * @param {any} meta (optional).
   * 
   */
  createManyFeatureDataSuccess: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_MANY_FEATUREDATA_SUCCESS;
      public payload: {id: any, data: any};

    constructor(id: any, data: any, public meta?: any) {
      this.payload = {id, data};
    }
  },
  /**
   * createManyFeatureDataFail Action.
   *
   * @param {any} payload
   * @param {any} meta (optional).
   * 
   */
  createManyFeatureDataFail: class implements Action {
    public readonly type = FeatureActionTypes.CREATE_MANY_FEATUREDATA_FAIL;

    constructor(public payload: any, public meta?: any) { }
  },
});