/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { ProductApi } from './services/custom/Product';
import { AppUserApi } from './services/custom/AppUser';
import { MeasurementSessionApi } from './services/custom/MeasurementSession';
import { MeasurementDataApi } from './services/custom/MeasurementData';
import { GenderApi } from './services/custom/Gender';
import { MeasurementDataCompactApi } from './services/custom/MeasurementDataCompact';
import { FeetScanApi } from './services/custom/FeetScan';
import { AttachmentApi } from './services/custom/Attachment';
import { MeasurementParameterApi } from './services/custom/MeasurementParameter';
import { FeatureApi } from './services/custom/Feature';
import { FeatureDataApi } from './services/custom/FeatureData';
import { SensorApi } from './services/custom/Sensor';
import { PrinterApi } from './services/custom/Printer';
import { LocationApi } from './services/custom/Location';
import { BookingApi } from './services/custom/Booking';
import { EventApi } from './services/custom/Event';
import { PrinterTaskApi } from './services/custom/PrinterTask';
import { OrderApi } from './services/custom/Order';
import { OrderStatusApi } from './services/custom/OrderStatus';
import { PrintTaskStatusApi } from './services/custom/PrintTaskStatus';
import { BookingStatusApi } from './services/custom/BookingStatus';
import { ScannerApi } from './services/custom/Scanner';
import { MeasurementDataNormApi } from './services/custom/MeasurementDataNorm';
import { FeatureDataSeriesApi } from './services/custom/FeatureDataSeries';
import { WorkerApi } from './services/custom/Worker';
import { BookingSlotApi } from './services/custom/BookingSlot';
import { MeasurementSessionIntegrityApi } from './services/custom/MeasurementSessionIntegrity';
import { MeasurementSessionQualityApi } from './services/custom/MeasurementSessionQuality';
import { EventStatusApi } from './services/custom/EventStatus';
import { PrinterStatusApi } from './services/custom/PrinterStatus';
import { LogApi } from './services/custom/Log';
import { EnvironmentApi } from './services/custom/Environment';
import { MidsoleApi } from './services/custom/Midsole';
import { CustomMidsoleApi } from './services/custom/CustomMidsole';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        ProductApi,
        AppUserApi,
        MeasurementSessionApi,
        MeasurementDataApi,
        GenderApi,
        MeasurementDataCompactApi,
        FeetScanApi,
        AttachmentApi,
        MeasurementParameterApi,
        FeatureApi,
        FeatureDataApi,
        SensorApi,
        PrinterApi,
        LocationApi,
        BookingApi,
        EventApi,
        PrinterTaskApi,
        OrderApi,
        OrderStatusApi,
        PrintTaskStatusApi,
        BookingStatusApi,
        ScannerApi,
        MeasurementDataNormApi,
        FeatureDataSeriesApi,
        WorkerApi,
        BookingSlotApi,
        MeasurementSessionIntegrityApi,
        MeasurementSessionQualityApi,
        EventStatusApi,
        PrinterStatusApi,
        LogApi,
        EnvironmentApi,
        MidsoleApi,
        CustomMidsoleApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

export * from './actions/index';
export * from './reducers/index';
export * from './state';
export * from './guards/index';
export * from './resolvers/index';
