/* tslint:disable */

declare var Object: any;
export interface MidsoleInterface {
  "file"?: string;
  "gcode"?: string;
  "size"?: number;
  "id"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Midsole implements MidsoleInterface {
  "file": string;
  "gcode": string;
  "size": number;
  "id": number;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: MidsoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Midsole`.
   */
  public static getModelName() {
    return "Midsole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Midsole for dynamic purposes.
  **/
  public static factory(data: MidsoleInterface): Midsole{
    return new Midsole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Midsole',
      plural: 'Midsoles',
      path: 'Midsoles',
      idName: 'id',
      properties: {
        "file": {
          name: 'file',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "gcode": {
          name: 'gcode',
          ui: {"group":"general","control":"textbox"},
          type: 'string'
        },
        "size": {
          name: 'size',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "id": {
          name: 'id',
          ui: {"group":"general","control":"textbox"},
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          ui: {"group":"general","control":"textbox"},
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
